import React from 'react'
import AdminReportsManagementContent from '../sections/AdminReportsManagement/AdminReportsManagementContent'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
const AdminReportsManagement = () => {
  return (
    <div>
      <AdminTopNavigation/> 
      <AdminReportsManagementContent />
    </div>
  )
}

export default AdminReportsManagement