import React from 'react'
import SupervisorArnDSRStatusReportConent from '../sections/SupervisorArnDSRStatusReport/SupervisorArnDSRStatusReportConent'
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation.js';
const SupervisorArnDSRStatusReport = () => {
  return (
    <div>
      <SupervisorTopNavigation />
      <SupervisorArnDSRStatusReportConent />
    </div>
  )
}

export default SupervisorArnDSRStatusReport