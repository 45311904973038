import React, { useState, useEffect } from 'react';
import { Grid, FormControl } from '@mui/material';
import Select from 'react-select';
// import marketscope_icon from '../../../assets/images/marketscope-w.png';
// import dailyreport_icon from '../../../assets/images/dailyreport-w.png';
// import summary_icon from '../../../assets/images/summary-w.png';
// import montly_review_icon from '../../../assets/images/montly-review-w.png';
import { Link } from 'react-router-dom';
import Auth from '../../../modules/Auth';
import { getsupervisorwiseuserarn, getsummeryforday, getsummeryforyear } from '../../Api/SupervisorAPI';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getsip_lumpsumtarget } from '../../Api/DashboardApi';


const Dashboard = () => {
	const supervisorid = Auth.getUserId();
	const [arnf, setArnf] = useState([]);
	const [arnOption, setArnOption] = useState([]);
	const [date, setDate] = useState(new Date());
	const [summeryforday, setSummeryforday] = useState({});
	const [summeryformonth, setSummeryformonth] = useState({})
	const [summeryforyear, setSummeryforyear] = useState({});
	const [YearlyDsrtargetdetails, setYearlyDsrtargetdetails] = useState({});
	const [siplumpsumtarget, setSiplumpsumtarget] = useState({});
	const [fromdate, setFromdate] = useState(null);
	const [todate, setTodate] = useState(null);
	const [nimfsystemmonth, setNimfsystemmonth] = useState({});
	const [nimfsystemyear, setNimfsystemyear] = useState({});

	useEffect(() => {
		GetUserArns();
		// eslint-disable-next-line
	}, []);
	const GetUserArns = () => {
		getsupervisorwiseuserarn(supervisorid).then((res) => {
			if (res.data.response.status === 1) {
				let arnlist = res.data.response.data.arnCodeList
				setArnOption(arnlist)
			}
		}).catch((err) => {
			console.log(err)
		})
	}
	useEffect(() => {
		if (arnf.value !== undefined) {
			GetSummeryFortheDay();
			GetSummeryFortheYear();
			// GetMonthlyForMonth();
			GetSip_LumpsumTarget();
		}
		// eslint-disable-next-line
	}, [date, arnf])
	const GetSip_LumpsumTarget = () => {
		let selecteddate = moment(date).format('yyyy-MM-DD');
		let data = {
			"UserId": arnf.value,
			"Date": selecteddate
		}
		getsip_lumpsumtarget(data).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data;
				setSiplumpsumtarget(result.monthly[0]);
				setNimfsystemmonth(result.nimfMTargets === null ? {} : result.nimfMTargets);
				setNimfsystemyear(result.nimfYTargets  === null ? {} : result.nimfYTargets);
			} else {
				setSiplumpsumtarget({});
				setNimfsystemmonth({});
				setNimfsystemyear({});
			}
		}).catch((err) => {
			setSiplumpsumtarget({});
			setNimfsystemmonth({});
			setNimfsystemyear({});
			console.log(err);
		})
	}
	const GetSummeryFortheDay = () => {
		let selecteddate = moment(date).format('yyyy-MM-DD');
		let data = {
			"UserId": arnf.value,
			"Date": selecteddate
		}
		getsummeryforday(data).then((res) => {
			if (res.data.response.status === 1) {

				let result = res.data.response.data.dsrDailyDetails;
				setSummeryforday(result.length === 0 ? {} : result[0]);

				let resultM = res.data.response.data.dsrMonthlyDetails;
				setSummeryformonth(resultM.length === 0 ? {} : resultM[0])
			} else {
				setSummeryforday({});
				setSummeryformonth({})
			}
		}).catch((err) => {
			setSummeryforday({});
			setSummeryformonth({})
			console.log(err)
		})
	}




	const GetSummeryFortheYear = () => {
		let data = {
			"UserId": arnf.value,
		}
		getsummeryforyear(data).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data.dsrYearly[0];
				let resulttargetyearly =
					res.data.response.data.querynTargetYearly[0] === undefined
						? {}
						: res.data.response.data.querynTargetYearly[0];
				setYearlyDsrtargetdetails(resulttargetyearly);
				setSummeryforyear(result);
				setFromdate(res.data.response.data.convertDate);
				setTodate(res.data.response.data.oneYearDate);
			} else {
				setSummeryforyear({});
				setYearlyDsrtargetdetails({});
				setFromdate(null);
				setTodate(null);
			}
			// console.log(res);
		}).catch((err) => {
			setSummeryforyear({});
			setFromdate(null);
			setTodate(null);
			console.log(err)
		})
	}
	return (
		<div className='container'>
			<Grid container spacing={10} padding={6} rowSpacing={5}>
				<Grid item xs={12} md={12}>
					<div className="cards">
						<div className="card card-1">
							<Link to="/supervisorusermanagement">
								<div className="card__title">Manage Agent</div>
								{/* <div className="card__icon">
									<img src={marketscope_icon} alt="Market Scope" className="img-fluid" />
								</div> */}
							</Link>
						</div>

						<div className="card card-2">
							<Link to="/supervisorreportmanagement">
								<div className="card__title">View Daily Reports of all users</div>
								{/* <div className="card__icon">
									<img src={dailyreport_icon} alt="Daily Report" className="img-fluid" />
								</div> */}
							</Link>
						</div>
						<div className="card card-3">
							<Link to="/supervisormsreportsmanagement">
								<div className="card__title">Market Scope Reports</div>
								{/* <div className="card__icon">
									<img src={dailyreport_icon} alt="Daily Report" className="img-fluid" />
								</div> */}
							</Link>
						</div>

						<div className="card card-1">
							<Link to="/supervisorarndsrstatusreport">
								<div className="card__title">ARN DSR Status Reports</div>
								{/* <div className="card__icon">
									<img src={summary_icon} alt="Market Scope" className="img-fluid" />
								</div> */}
							</Link>
						</div>

						<div className="card card-2">
							<Link to="/monthlyreview">
								<div className="card__title">Monthly & Six Monthly Review</div>
								{/* <div className="card__icon">
									<img src={montly_review_icon} alt="Daily Report" className="img-fluid" />
								</div> */}
							</Link>
						</div>
						<div className="card card-3">
							<Link to="/supervisormandsixmreviewreport">
								<div className="card__title">Monthly & Six Monthly Review Reports</div>
								{/* <div className="card__icon">
									<img src={montly_review_icon} alt="Daily Report" className="img-fluid" />
								</div> */}
							</Link>
						</div>
					</div>
				</Grid>
			</Grid>

			<div className='container mb-4'>

				<Grid item xs={12} md={12}>
					<div className="dashboard__counter-header mb-4">
						Summary for the Day
						<div className="dashboard__counter-header-bg" />
					</div>
				</Grid>
				<Grid container spacing={1} className='mb-4' >

					<Grid item xs={6} md={4}>
						<FormControl fullWidth className="textfield">
							<label>Date<span className="text-danger">*</span></label>
							<DatePicker
								className="textfield"
								placeholderText="dd-mm-yyyy"
								showMonthDropdown
								showYearDropdown
								scrollableYearDropdown
								yearDropdownItemNumber={50}
								maxDate={new Date()}
								// minDate={new Date(startDate)}
								onChange={(date) => {
									setDate(date)
								}}
								dateFormat="dd-MM-yyyy"
								name="startdate"
								autoComplete="off"
								selected={date}
								withPortal
							/>
						</FormControl>
					</Grid>
					<Grid item xs={6} md={4}>
						<FormControl fullWidth className="textfield">
							<label>ARN Code<span className="text-danger">*</span></label>
							<Select
								value={arnf}

								options={arnOption}
								onChange={(selected) => {
									setArnf(selected);
								}}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid item xs={12} md={12}>
					<div className="dashboard__counter-border">

						<div className="dashboard__counters">
							<div className="dashboard__counter">

								<div className="dashboard__counter-content">
									<h6>Total Meetings</h6>
									<h4>
										{summeryforday.totalMeetingsDone === undefined ? (
											0
										) : (
											summeryforday.totalMeetingsDone
										)}
									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>
							</div>



							<div className="dashboard__counter-divider" />

							<div className="dashboard__counter">
								<div className="dashboard__counter-content">
									<h6>Lumpsum Application</h6>
									<h4>
										{summeryforday.lumpsumApplication === undefined ? (
											0
										) : (
											summeryforday.lumpsumApplication
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 5% </span> from yesterday
									</p> */}
								</div>

								<div className="dashboard__counter-content">
									<h6>SIP Application</h6>
									<h4>
										{summeryforday.sipApplication === undefined ? (
											0
										) : (
											summeryforday.sipApplication
										)}
									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>

							</div>
							<div className="dashboard__counter-divider" />
							<div className="dashboard__counter">

								<div className="dashboard__counter-content">
									<h6>Lumpsum Business Amount</h6>
									<h4>
										&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											summeryforday.lumpsumAmount === undefined
												? 0
												: summeryforday.lumpsumAmount
										)}

									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>
								<div className="dashboard__counter-content">
									<h6>SIP Business Amount</h6>
									<h4>
										&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											summeryforday.sipTotalAmount === undefined
												? 0
												: summeryforday.sipTotalAmount
										)}

									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>
							</div>

						</div>
					</div>
				</Grid>

				{/* monthly summary */}

				<Grid item xs={12} md={12}>
					<div className="dashboard__counter-header mb-4">
						Summary for the month
						<div className="dashboard__counter-header-bg" />
					</div>
				</Grid>
				{/* <Grid item xs={12} md={6} textAlign={'right'} >
					<div className='doj'>DOJ: {Datedetails.createDate === undefined ? "" : moment(new Date(Datedetails.createDate)).format("DD-MM-yyyy")}<span className='doj-days'> { Datedetails.days === undefined ? "" : `(${parseInt(Datedetails.days)}) days` }</span></div>
					</Grid> */}
				<Grid item xs={12} md={12}>
					<div className="dashboard__counter-border">

						<div className="dashboard__counters">
							<div className="dashboard__counter">
								<div className="dashboard__counter-content">
									<h6>As per NIMF system (Lumpsum)</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											nimfsystemmonth.nimfMLumpsum === undefined ? 0 : nimfsystemmonth.nimfMLumpsum
										)}</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>
								<div className="dashboard__counter-content">
									<h6>As per NIMF system (SIP)</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											nimfsystemmonth.nimfMSip === undefined ? 0 : nimfsystemmonth.nimfMSip
										)}</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>


							</div>
							<div className="dashboard__counter-divider" />
							<div className="dashboard__counter">

								<div className="dashboard__counter-content">

									<h6>Lumpsum Target</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											siplumpsumtarget.lumpsumTarget === undefined ? 0 : siplumpsumtarget.lumpsumTarget
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
								</div>
								<div className="dashboard__counter-content">

									<h6>SIP Target</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											siplumpsumtarget.sipTarget === undefined ? 0 : siplumpsumtarget.sipTarget
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
								</div>

							</div>


							<div className="dashboard__counter-divider" />

							<div className="dashboard__counter">

								<div className="dashboard__counter-content">

									<h6>Lumpsum Achivement</h6>
									<h4>
										&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											summeryformonth.lumpsumAmount === undefined ? 0 : summeryformonth.lumpsumAmount
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
								</div>
								<div className="dashboard__counter-content">
									<h6>SIP Achivement</h6>
									<h4>
										&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											summeryformonth.sipTotalAmount === undefined ? 0 : summeryformonth.sipTotalAmount
										)}
									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>

							</div>

							<div className="dashboard__counter-divider" />

							<div className="dashboard__counter">


								<div className="dashboard__counter-content">
									<h6>Lumpsum Application</h6>
									<h4>{summeryformonth.lumpsumApplication === undefined ? 0 : summeryformonth.lumpsumApplication}</h4>
									{/* <p>
										<span className="decrease">- 5% </span> from July
									</p> */}
								</div>
								<div className="dashboard__counter-content">
									<h6>SIP Application</h6>
									<h4>{summeryformonth.sipApplication === undefined ? 0 : summeryformonth.sipApplication}</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>

							</div>
							<div className="dashboard__counter-divider" />
							<div className="dashboard__counter">


								<div className="dashboard__counter-content-wrap" >
									<div className="dashboard__counter-content"><h6>Open Leads</h6>
										<h4>{summeryformonth.openLeads === undefined ? 0 : summeryformonth.openLeads}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}</div>
									<div className="dashboard__counter-content">
										<h6>Closed Leads</h6>
										<h4>{summeryformonth.closedLeads === undefined ? 0 : summeryformonth.closedLeads}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}</div>
								</div>

								<div className="dashboard__counter-content">
									<h6>Total Meetings</h6>
									<h4>{summeryformonth.totalMeetingsDone === undefined ? 0 : summeryformonth.totalMeetingsDone}</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>
							</div>

						</div>
					</div>
				</Grid>

				<Grid item xs={12} md={12}>
					<div className="dashboard__counter-header my-4">
						Summary for the year <span>{fromdate === null || todate === null ? "" : `(From: ${fromdate == null ? "" : moment(new Date(fromdate)).format('DD/MM/yyyy')} - To: ${todate == null ? "" : moment(new Date(todate)).format('DD/MM/yyyy')})`}</span>
						<div className="dashboard__counter-header-bg" />
					</div>
				</Grid>
				<Grid item xs={12} md={12} className="pb-4">
					<div className="dashboard__counter-border">

						<div className="dashboard__counters">
							<div className="dashboard__counter">
								<div className="dashboard__counter-content">
									<h6>As per NIMF system (Lumpsum)</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											nimfsystemyear.nimfYLumpsum === undefined ? 0 : nimfsystemyear.nimfYLumpsum
										)}</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>
								<div className="dashboard__counter-content">
									<h6>As per NIMF system (SIP)</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											nimfsystemyear.nimfYSip === undefined ? 0 : nimfsystemyear.nimfYSip
										)}</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
								</div>


							</div>
							<div className="dashboard__counter-divider" />
							<div className="dashboard__counter">

								<div className="dashboard__counter-content">

									<h6>Lumpsum Target</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											YearlyDsrtargetdetails.lumpsumTarget === undefined ? 0 : YearlyDsrtargetdetails.lumpsumTarget
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
								</div>
								<div className="dashboard__counter-content">

									<h6>SIP Target</h6>
									<h4>&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											YearlyDsrtargetdetails.sipTarget === undefined ? 0 : YearlyDsrtargetdetails.sipTarget
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
								</div>

							</div>
							<div className="dashboard__counter-divider" />

							<div className="dashboard__counter">

								<div className="dashboard__counter-content">
									<h6>Total Meetings</h6>
									<h4>
										{summeryforyear.totalMeetingsDone === undefined ? (
											0
										) : (
											summeryforyear.totalMeetingsDone
										)}
									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>
							</div>


							<div className="dashboard__counter-divider" />

							<div className="dashboard__counter">
								<div className="dashboard__counter-content">
									<h6>Lumpsum Application</h6>
									<h4>
										{summeryforyear.lumpsumApplication === undefined ? (
											0
										) : (
											summeryforyear.lumpsumApplication
										)}
									</h4>
									{/* <p>
										<span className="decrease">- 5% </span> from yesterday
									</p> */}
								</div>

								<div className="dashboard__counter-content">
									<h6>SIP Application</h6>
									<h4>
										{summeryforyear.sipApplication === undefined ? (
											0
										) : (
											summeryforyear.sipApplication
										)}
									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>

							</div>
							<div className="dashboard__counter-divider" />
							<div className="dashboard__counter">

								<div className="dashboard__counter-content">
									<h6>Lumpsum Business Amount</h6>
									<h4>
										&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											summeryforyear.lumpsumAmount === undefined
												? 0
												: summeryforyear.lumpsumAmount
										)}
									</h4>
								</div>
								<div className="dashboard__counter-content">
									<h6>SIP Business Amount</h6>
									<h4>
										&#x20B9;{' '}
										{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
											summeryforyear.sipTotalAmount === undefined
												? 0
												: summeryforyear.sipTotalAmount
										)}
									</h4>
									{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
								</div>
							</div>

						</div>
					</div>
				</Grid>
			</div>
		</div>
	)
}

export default Dashboard