import React, { useState, useEffect } from 'react'
import { Grid, FormControl, Button, IconButton } from '@mui/material'
import moment from 'moment'
import Select from 'react-select'
import { ReviewType } from '../../../modules/helpers/constant'
import DatePicker from 'react-datepicker'
import LoadingScreen from '../../layouts/LoadingScreen'
import Auth from '../../../modules/Auth';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Preview } from '@mui/icons-material'
import { getmonthlysixmonthlyreviewreport, getsupervisorwiseuserarn } from '../../Api/SupervisorAPI'
import { Box } from '@mui/system';
import No_data from '../../../assets/images/no_data1.jpg';
import { exportExcel } from '../../../modules/helpers/exportExcel';
import { exportPDF } from '../../../modules/helpers/exportPdf';
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';
const SupervisorMandSixMReviewReportContent = () => {
    const [arnf, setArnf] = useState([]);
    const [arnOption, setArnOption] = useState([]);
    const [reviewtypef, setReviewtypef] = useState({ label: "Monthly", value: "Monthly" })
    const [fromdatef, setFromdatef] = useState(null);
    const [todatef, setTodatef] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = useState({});
    const [errors, setErrors] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [count, setCount] = useState(0);
    const supervisorid = Auth.getUserId();
    useEffect(() => {
        GetUserArns();
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        GetMonthlyAndSixMonthlyReportDetails();
        // eslint-disable-next-line
    }, [pageNo])
    const handleDownloadExcel = async () => {
        setLoading(true);
        let data = {
            userArnCode: arnf.label === undefined ? "" : arnf.label,
            reviewType: reviewtypef.value === undefined ? "" : reviewtypef.value,
            supervisorId: Number(supervisorid),
            start_Date: fromdatef === null ? "" : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
            end_Date: todatef === null ? "" : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
            pageNo:pageNo  ,
            PageSize:5,
            type: "Download",
        }
        getmonthlysixmonthlyreviewreport(data).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                let result = res.data.response.data.history;
                let table1 = result.map((S, ind) => {
                    return {
                        challengesFaced: S.challengesFaced,
                        clientMeetingsAchieved: S.clientMeetingsAchieved,
                        clientMeetingsPlanned: S.clientMeetingsPlanned,
                        date: moment(S.date).format('DD-MM-yyyy'),
                        lumpsumAmountAchieved: `₹${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.lumpsumAmountAchieved))}`,
                        lumpsumAmountPlanned: `₹${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.lumpsumAmountPlanned))}`,
                        lumpsumApplicationAchieved: S.lumpsumApplicationAchieved,
                        lumpsumApplicationPlanned: S.lumpsumApplicationPlanned,
                        nimfSupportRequired: S.nimfSupportRequired,
                        reviewType: S.reviewType === "Six_Monthly" ? "Six Monthly" : S.reviewType,
                        sipAmountAchieved: `₹${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.sipAmountAchieved))}`,
                        sipAmountPlanned: `₹${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.sipAmountPlanned))}`,
                        sipApplicationAchieved: S.sipApplicationAchieved,
                        sipApplicationPlanned: S.sipApplicationPlanned,
                        submitted: S.submitted === 1 ? 'Not Submitted' : 'Submitted',
                        supervisorName: S.supervisorName,
                        trainingRequired: S.trainingRequired,
                        userArnCode: S.userArnCode,
                        userMobileNo: S.userMobileNo,
                        userName: S.userName,
                        whatDidntGoWell: S.whatDidntGoWell,
                        whatWentWell: S.whatWentWell,
                    }
                })
                let columns = [
                    { header: 'Agent name', key: 'userName', width: 30 },
                    { header: 'ARN code', key: 'userArnCode', width: 12 },
                    { header: 'Review type', key: 'reviewType', width: 12 },
                    { header: 'Date', key: 'date', width: 12 },
                    { header: 'No. of Client Meetings planned', key: 'clientMeetingsPlanned', width: 12 },
                    { header: 'No. of Client Meetings achieved', key: 'clientMeetingsAchieved', width: 12 },
                    { header: 'Total Lumpsum (in lacs) planned', key: 'lumpsumAmountPlanned', width: 12 },
                    { header: 'Total Lumpsum (in lacs) achieved', key: 'lumpsumAmountAchieved', width: 12 },
                    { header: 'Total Lumpsum (No. of Applications) planned', key: 'lumpsumApplicationPlanned', width: 12 },
                    { header: 'Total Lumpsum (No. of Applications) achieved', key: 'lumpsumApplicationAchieved', width: 12 },
                    { header: 'Total New SIPs (in lacs) planned', key: 'sipAmountPlanned', width: 12 },
                    { header: 'Total New SIPs (in lacs) achieved', key: 'sipAmountAchieved', width: 12 },
                    { header: 'Total SIP (No. of Applications) planned', key: 'sipApplicationPlanned', width: 12 },
                    { header: 'Total SIP (No. of Applications) achieved', key: 'sipApplicationAchieved', width: 12 },
                    { header: 'What Went Well?', key: 'whatWentWell', width: 12 },
                    { header: 'What Did not Go Well?', key: 'whatDidntGoWell', width: 12 },
                    { header: 'Challenges Faced', key: 'challengesFaced', width: 12 },
                    { header: 'Training Required', key: 'trainingRequired', width: 12 },
                    { header: 'NIMF Support Required', key: 'nimfSupportRequired', width: 12 },
                    { header: 'Supervisor name', key: 'supervisorName', width: 30 },
                    { header: 'Status', key: 'submitted', width: 30 },
                ]
                exportExcel(`${reviewtypef.label} Review Report`, columns, table1, 'masreport');
            } else {
          Swal.fire("Please try again","Something went wrong","error");
            }
        }).catch((err) => {
            Swal.fire("Please try again","Something went wrong","error");
            setLoading(false);
            console.log(err)
        });
      
    }
    const downloadpdf = () => {
        setLoading(true);
        let data = {
            userArnCode: arnf.label === undefined ? "" : arnf.label,
            reviewType: reviewtypef.value === undefined ? "" : reviewtypef.value,
            supervisorId: Number(supervisorid),
            start_Date: fromdatef === null ? "" : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
            end_Date: todatef === null ? "" : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
            pageNo:pageNo  ,
            PageSize:5,
            type: "Download",
        }
        getmonthlysixmonthlyreviewreport(data).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                let result = res.data.response.data.history;
                let table1 = result.map((S, ind) => {
                    return {
                        sr_no: ind + 1,
                        challengesFaced: S.challengesFaced,
                        clientMeetingsAchieved: S.clientMeetingsAchieved,
                        clientMeetingsPlanned: S.clientMeetingsPlanned,
                        date: moment(S.date).format('DD-MM-yyyy'),
                        lumpsumAmountAchieved: `${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.lumpsumAmountAchieved))}`,
                        lumpsumAmountPlanned: `${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.lumpsumAmountPlanned))}`,
                        lumpsumApplicationAchieved: S.lumpsumApplicationAchieved,
                        lumpsumApplicationPlanned: S.lumpsumApplicationPlanned,
                        nimfSupportRequired: S.nimfSupportRequired,
                        reviewType: S.reviewType === "Six_Monthly" ? "Six Monthly" : S.reviewType,
                        sipAmountAchieved: `${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.sipAmountAchieved))}`,
                        sipAmountPlanned: `${new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2
                        }).format(Number(S.sipAmountPlanned))}`,
                        sipApplicationAchieved: S.sipApplicationAchieved,
                        sipApplicationPlanned: S.sipApplicationPlanned,
                        submitted: S.submitted === 1 ? 'Not Submitted' : 'Submitted',
                        supervisorName: S.supervisorName,
                        trainingRequired: S.trainingRequired,
                        userArnCode: S.userArnCode,
                        userMobileNo: S.userMobileNo,
                        userName: S.userName,
                        whatDidntGoWell: S.whatDidntGoWell,
                        whatWentWell: S.whatWentWell,
                    }
                })
                let head = [
                    {
                        sr_no: 'Sr. No.',
                        userName: 'Agent name',
                        userArnCode: 'ARN code',
                        reviewType: 'Review type',
                        date: 'Date',
                        clientMeetingsPlanned: 'No. of Client Meetings planned',
                        clientMeetingsAchieved: 'No. of Client Meetings achieved',
                        lumpsumAmountPlanned: 'Total Lumpsum (in lacs) planned',
                        lumpsumAmountAchieved: 'Total Lumpsum (in lacs) achieved',
                        lumpsumApplicationPlanned: 'Total Lumpsum (No. of Applications) planned',
                        lumpsumApplicationAchieved: 'Total Lumpsum (No. of Applications) achieved',
                        sipAmountPlanned: 'Total New SIPs (in lacs) planned',
                        sipAmountAchieved: 'Total New SIPs (in lacs) achieved',
                        sipApplicationPlanned: 'Total SIP (No. of Applications) planned',
                        sipApplicationAchieved: 'Total SIP (No. of Applications) achieved',
                        whatWentWell: 'What Went Well?',
                        whatDidntGoWell: 'What Did not Go Well?',
                        challengesFaced: 'Challenges Faced',
                        trainingRequired: 'Training Required',
                        nimfSupportRequired: 'NIMF Support Required',
                        supervisorName: 'Supervisor name',
                        submitted: 'Status'
                    }
                ]
                exportPDF(head, table1, `${reviewtypef.label} Review Report`, `${reviewtypef.label} Review Report`);
            } else {
            Swal.fire("Please try again","Something went wrong","error");
            }
        }).catch((err) => {
            Swal.fire("Please try again","Something went wrong","error");
            setLoading(false);
            console.log(err)
        });

    }
    const GetUserArns = () => {
        getsupervisorwiseuserarn(supervisorid).then((res) => {
            if (res.data.response.status === 1) {
                let arnlist = res.data.response.data.arnCodeList
                setArnOption(arnlist)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const GetMonthlyAndSixMonthlyReportDetails = (search) => {
        let valid = validate();
        if (valid) {
            if(search !== undefined){
                setPageNo(search)
            }
            setLoading(true)
            let data = {
                userArnCode: arnf.label === undefined ? "" : arnf.label,
                reviewType: reviewtypef.value === undefined ? "" : reviewtypef.value,
                supervisorId: Number(supervisorid),
                start_Date: fromdatef === null ? "" : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
                end_Date: todatef === null ? "" : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
                pageNo:search  === undefined ? pageNo  : search,
                PageSize:5,
                type: "Pagination",
            }
            getmonthlysixmonthlyreviewreport(data).then((res) => {
                if (res.data.response.status === 1) {
                    let result = res.data.response.data;
                    setCount(result.count);
                    setData(result.history);
                } else {
                    setCount(0);
                    setData([]);
                }
                setLoading(false);
            }).catch((err) => {
                setCount(0);
                setData([]);
                setLoading(false);
                console.log(err)
            });
        }
    }
    const ClearFilter = () => {
        setArnf([]);
        setReviewtypef({ label: "Monthly", value: "Monthly" });
        setFromdatef(null);
        setTodatef(null);
        setErrors({});
        setPageNo(1);
        setCount(0);
        setLoading(true)
        let data = {
            userArnCode: "",
            supervisorId: Number(supervisorid),
            reviewType: "Monthly",
            start_Date: "",
            end_Date: "",
            pageNo:1,
            PageSize:5,
            type: "Pagination",
        }
        getmonthlysixmonthlyreviewreport(data).then((res) => {
            if (res.data.response.status === 1) {
                let result = res.data.response.data;
                setCount(result.count);
                setData(result.history);
            } else {
                setData([]);
                setCount(0);
            }
      setLoading(false);

        }).catch((err) => {
            setData([]);
            setCount(0);
            console.log(err)
        })
    }
    const handlePreview = (data) => {
        setPreviewData(data);
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        setPreviewData({});
    }
    const validate = () => {
        let valid = true;
        let error = {};
        if (fromdatef == null && todatef != null) {
            error['fromdatef'] = 'Please select from date';
            valid = false;
        }
        if (todatef == null && fromdatef != null) {
            error['todatef'] = 'Please select to date';
            valid = false;
        }
        setErrors(error);
        return valid;

    }
    const handlePageChange = (pageNumber) =>  {
        // console.log(`active page is ${pageNumber}`);
        setPageNo(pageNumber);
      }
    return (
        <div className='p-4'>
            {loading === true ? <LoadingScreen /> : null}
            <Grid container spacing={0} padding={0} rowSpacing={2}>
                <Grid item md={6} xs={6} alignContent="flex-start">
                    <div className='sub-heading'>View Monthly And Six Monthly Report</div>
                </Grid>
                <Grid item md={6} xs={6} className={'right'}>
                    <div>
                        <Button onClick={handleDownloadExcel} variant="contained" className='p-2'  disabled={data.length === 0}>download excel</Button>
                        <Button
                            className='p-2 m-2'
                            variant="contained"
                            onClick={() => downloadpdf()}
                            disabled={data.length === 0}
                        >
                            Download PDF
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className={'p-4'}>

                    <Grid container spacing={2} padding={0} rowSpacing={1.5} className={'admin__search-bar'}>

                        <Grid item xs={4} md={2.4}>
                            <FormControl fullWidth className="textfield">
                                <label>ARN Code</label>
                                <Select
                                    value={arnf}

                                    options={arnOption}
                                    onChange={(selected) => {
                                        setArnf(selected);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <FormControl fullWidth className="textfield">
                                <label>Monthly/Six Monthly</label>
                                <Select
                                    value={reviewtypef}
                                    options={ReviewType}
                                    onChange={(selected) => {
                                        setReviewtypef(selected);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <FormControl fullWidth className="textfield">
                                <label>From Date</label>
                                <DatePicker
                                    className="textfield"
                                    placeholderText="dd-mm-yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                    maxDate={new Date()}
                                    // minDate={new Date(startDate)}
                                    onChange={(date) => {
                                        setFromdatef(date);
                                        setTodatef(new Date());
                                        setErrors({ ...errors, fromdatef: '' });
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    name="startdate"
                                    autoComplete="off"
                                    selected={fromdatef}
                                    withPortal
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <FormControl fullWidth className="textfield">
                                <label>To Date</label>
                                <DatePicker

                                    className="textfield"
                                    placeholderText="dd-mm-yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                    minDate={new Date(fromdatef)}
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        setTodatef(date);
                                        setErrors({ ...errors, todatef: '' });
                                    }}
                                    withPortal
                                    dateFormat="dd-MM-yyyy"
                                    name="startdate"
                                    autoComplete="off"
                                    selected={todatef}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12} className={'center p-t-5'}>
                            <Button variant="contained" className='btn-grad ' onClick={() => GetMonthlyAndSixMonthlyReportDetails(1)} >Search</Button>
                            <Button variant="contained" className='btn-grad' onClick={() => ClearFilter()} >Clear</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {data.length !== 0 ?
                <Grid item xs={12} md={12}>
                    <div className="overflow-admin">
                        <table id="dsr" border={1} >
                            <thead>
                                <tr className="thead">
                                    <td rowSpan={2}>Sr. No.</td>
                                    <td rowSpan={2}>Agent Name</td>
                                    <td rowSpan={2} className="p-r-100">ARN Code</td>
                                    <td rowSpan={2} className="p-r-100">Date</td>
                                    <td rowSpan={2} className="p-r-100">Review Type</td>
                                    <td rowSpan={2} className="p-r-100">Status</td>
                                    <td rowSpan={2}>Action</td>
                                </tr>

                            </thead>
                            <tbody>
                                {data &&
                                    data.map((dailysreport, ind) => (
                                        <tr key={ind}>
                                            <td>{(ind + 1) +( (pageNo * 5) - (5) )}</td>
                                            <td>{dailysreport.userName}</td>
                                            <td>{dailysreport.userArnCode}</td>
                                            <td>{moment(dailysreport.date).format('DD-MM-yyyy')}</td>
                                            <td>{dailysreport.reviewType === "Six_Monthly" ? "Six Monthly" : dailysreport.reviewType}</td>
                                            <td>{dailysreport.submitted === 1 ? "Not Submmited yet" : "Submitted"}</td>
                                            <td className="icons-wrap">
                                                <IconButton
                                                    color="primary"
                                                    title='Preview'
                                                    onClick={() => handlePreview(dailysreport)}
                                                >
                                                    <Preview />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <Grid item md={12} xs={12} className={'center p-t-5'}>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={pageNo}
                            itemsCountPerPage={5}
                            totalItemsCount={count}
                            pageRangeDisplayed={5}
                            prevPageText="Previous"
                            nextPageText="Next"
                            onChange={handlePageChange}
                        />
                    </Grid>
                </Grid>:<img src={No_data} className="no_data" alt="no data"/>}

            </Grid>
            {show === true ?
                <div>
                    <Modal size='lg' isOpen={show} toggle={handleClose}>
                        <ModalHeader toggle={handleClose} className="modal-hd">
                            {previewData.reviewType === "Six_Monthly" ? "Six Monthly" : previewData.reviewType} Preview
                        </ModalHeader>
                        <ModalBody>
                            <Box>
                                <form>
                                    <Grid container padding={2} spacing={0} rowSpacing={1.5} columnSpacing={0} className="table-bordered" marginTop={2}>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            {' '}
                                            <b>ARN Name : </b><br />
                                            {previewData.userName}

                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            {' '}
                                            <b>ARN Code :</b> <br /> {previewData.userArnCode}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>  Date : </b><br />
                                            {moment(previewData.date).format('DD/MM/yyyy')}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b> ARN Contact Number :</b><br />
                                            {previewData.userMobileNo}
                                        </Grid>

                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b> Lumpsum application planned : </b><br />
                                            {previewData.lumpsumApplicationPlanned}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>Lumpsum application achieved : </b><br />
                                            {previewData.lumpsumApplicationAchieved}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b> Lumpsum amount planned (in lacs):</b> <br />
                                            {`₹${new Intl.NumberFormat('en-IN', {
                                                minimumFractionDigits: 2
                                            }).format(Number(previewData.lumpsumAmountPlanned))}`}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            {' '}
                                            <b> Lumpsum amount achieved (in lacs)
                                                :</b> <br />
                                            {`₹${new Intl.NumberFormat('en-IN', {
                                                minimumFractionDigits: 2
                                            }).format(Number(previewData.lumpsumAmountAchieved))}`}
                                        </Grid>

                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b> SIP application planned : </b><br />
                                            {previewData.sipApplicationPlanned}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>SIP application achieved : </b><br />
                                            {previewData.sipApplicationAchieved}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b> SIP amount planned (in lacs):</b> <br />
                                            {`₹${new Intl.NumberFormat('en-IN', {
                                                minimumFractionDigits: 2
                                            }).format(Number(previewData.sipAmountPlanned))}`}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            {' '}
                                            <b> SIP amount achieved (in lacs)
                                                :</b> <br />
                                            {`₹${new Intl.NumberFormat('en-IN', {
                                                minimumFractionDigits: 2
                                            }).format(Number(previewData.sipAmountAchieved))}`}
                                        </Grid>

                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>Supervisor Name : </b><br />
                                            {previewData.supervisorName}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>What Went Well? : </b><br />
                                            {previewData.whatWentWell}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>What Didn't Go Well? : </b><br />
                                            {previewData.whatDidntGoWell}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>Challenges Faced : </b><br />
                                            {previewData.challengesFaced}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>Training Required : </b><br />
                                            {previewData.trainingRequired}
                                        </Grid>
                                        <Grid item xs={12} md={6} className={'divider'}>
                                            <b>NIMF Support Required : </b><br />
                                            {previewData.nimfSupportRequired}
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <div>
                                <Button variant="contained" className="btn-grad" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </div> : null}
        </div>
    )
}

export default SupervisorMandSixMReviewReportContent