// import update from 'react-addons-update';

const initialState = {
	keyindDetails: [],
	onkeyindchange: false
};
const keyindustryreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_KEYIND':
			return {
				...state,
				keyindDetails: action.payload
			};
		case 'UPDATE_ARRAY':
			let e = action.payload;
			const { name, value } = e.target;
			return {
				...state,
				keyindDetails: {
					...state.keyindDetails,
					[action.index]: {
						...state.keyindDetails[action.index],
						[name]: value
					}
				}
			};
		case 'UPDATE_KEYIND':
			return {
				...state,
				keyindDetails: action.payload
			};
		case 'CLEAR_KEYIND':
			return {
				...state,
				keyindDetails: action.payload
			};
		default:
			return state;
	}
};

export default keyindustryreducer;
