import React from 'react'
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation'
import SupervisorReportsManagementContent from '../sections/SupervisorReportsManagement/SupervisorReportsManagementContent'

const SupervisorReportsManagement = () => {
    return (
        <div>
            <SupervisorTopNavigation />
            <SupervisorReportsManagementContent/>
        </div>
  )
}

export default SupervisorReportsManagement