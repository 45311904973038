import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Grid, FormControl } from '@mui/material';
import Select from 'react-select';
import { getsummeryforday, getsummeryforyear } from '../../Api/SupervisorAPI';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getuserarn } from '../../Api/AdminApi';
import { getsip_lumpsumtarget } from '../../Api/DashboardApi';
const Dashboard = () => {
	const [arnf, setArnf] = useState([]);
	const [arnOption, setArnOption] = useState([]);
	const [date, setDate] = useState(new Date());
	const [summeryforday, setSummeryforday] = useState({});
	const [summeryforyear, setSummeryforyear] = useState({});
	const [YearlyDsrtargetdetails, setYearlyDsrtargetdetails] = useState({});
	const [fromdate, setFromdate] = useState(null);
	const [todate, setTodate] = useState(null);
	const [nimfsystemyear, setNimfsystemyear] = useState({});

	useEffect(() => {
		GetUserArns()
	}, []);
	const GetUserArns = () => {
		getuserarn().then((res) => {
			if (res.data.response.status === 1) {
				let arnlist = res.data.response.data.arnCodeList;
				setArnOption(arnlist)
			}
		}).catch((err) => {
			console.log(err)
		})
	}
	useEffect(() => {
		if (arnf.value != undefined) {
			GetSummeryFortheDay();
			GetSummeryFortheYear();
			GetSip_LumpsumTarget();
		}
	}, [date, arnf])
	const GetSip_LumpsumTarget = () => {
		let selecteddate = moment(date).format('yyyy-MM-DD');
		let data = {
			"UserId": arnf.value,
			"Date": selecteddate
		}
		getsip_lumpsumtarget(data).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data;
				setNimfsystemyear(result.nimfYTargets  === null ? {} : result.nimfYTargets);
			} else {
				setNimfsystemyear({});
			}
		}).catch((err) => {
			setNimfsystemyear({});
			console.log(err);
		})
	}
	const GetSummeryFortheDay = () => {
		let selecteddate = moment(date).format('yyyy-MM-DD');
		let data = {
			"UserId": arnf.value,
			"Date": selecteddate
		}
		getsummeryforday(data).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data.dsrDailyDetails;
				setSummeryforday(result.length === 0 ? {} : result[0]);
			} else {
				setSummeryforday({});
			}
		}).catch((err) => {
			setSummeryforday({});
			console.log(err)
		})
	}
	const GetSummeryFortheYear = () => {
		let data = {
			"UserId": arnf.value,
		}
		getsummeryforyear(data).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data.dsrYearly[0];
				let resulttargetyearly =
					res.data.response.data.querynTargetYearly[0] === undefined
						? {}
						: res.data.response.data.querynTargetYearly[0];
				setYearlyDsrtargetdetails(resulttargetyearly);
				setSummeryforyear(result);
				setFromdate(res.data.response.data.convertDate);
				setTodate(res.data.response.data.oneYearDate);
			} else {
				setSummeryforyear({});
				setYearlyDsrtargetdetails({});
				setFromdate(null);
				setTodate(null);
			}
		}).catch((err) => {
			setSummeryforyear({});
			setFromdate(null);
			setTodate(null);
			console.log(err)
		})
	}
	return (
		<div className='container p-3'>
			<div className="cards">
				<div className="card card-1">
					<Link to="/adminusermanagement">
						<div className="card__title">Manage Agent</div>
						{/*<div className="card__icon">
								
									<div className="card__title">{dashboarddetails.clients} </div>
								</div> */}
					</Link>
				</div>

				<div className="card card-2">
					<Link to="/adminsupervisormanagement">
						<div className="card__title">Manage Supervisor</div>
					</Link>
				</div>

				<div className="card card-3">
					<Link to="/adminreportsmanagement">
						<div className="card__title">View Daily Reports of all users</div>
					</Link>
				</div>

				<div className="card card-1">
					<Link to="/adminmsreportsmanagement">
						<div className="card__title">Market Scope Reports</div>
					</Link>
				</div>
				<div className="card card-2">
					<Link to="/adminarndsrstatusreport">
						<div className="card__title">ARN DSR Status Reports</div>
					</Link>
				</div>
				<div className="card card-3">
					<Link to="/adminmandsixmreviewreport">
						<div className="card__title">Monthly and Six Monthly Review Reports</div>
					</Link>
				</div>
				<div className="card card-2">
					<Link to="/adminmastermanagement">
						<div className="card__title">Master Management</div>
					</Link>
				</div>
			</div>
			<div className='container text-center'>
				<div className='text-center '>
					<Grid container spacing={1} margin='auto' >
						<Grid item xs={12} md={12}>
							<div className="dashboard__counter-header mb-4">
								Summary for the Day
								<div className="dashboard__counter-header-bg" />
							</div>
						</Grid>

						<Grid container spacing={3} className='mb-4' marginLeft={1}>
							<Grid item xs={6} md={4}>
								<FormControl fullWidth className="textfield">
									<label>Date<span className="text-danger">*</span></label>
									<DatePicker
										className="textfield"
										placeholderText="dd-mm-yyyy"
										showMonthDropdown
										showYearDropdown
										scrollableYearDropdown
										yearDropdownItemNumber={50}
										maxDate={new Date()}
										// minDate={new Date(startDate)}
										onChange={(date) => {
											setDate(date)
										}}
										dateFormat="dd-MM-yyyy"
										name="startdate"
										autoComplete="off"
										selected={date}
										withPortal
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl fullWidth className="textfield">
									<label>ARN Code<span className="text-danger">*</span></label>
									<Select
										value={arnf}
										options={arnOption}
										onChange={(selected) => {
											setArnf(selected);
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12}>
							<div className="dashboard__counter-border">

								<div className="dashboard__counters">
									<div className="dashboard__counter">

										<div className="dashboard__counter-content">
											<h6>Total Meetings</h6>
											<h4>
												{summeryforday.totalMeetingsDone === undefined ? (
													0
												) : (
													summeryforday.totalMeetingsDone
												)}
											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>
									</div>



									<div className="dashboard__counter-divider" />

									<div className="dashboard__counter">
										<div className="dashboard__counter-content">
											<h6>Lumpsum Application</h6>
											<h4>
												{summeryforday.lumpsumApplication === undefined ? (
													0
												) : (
													summeryforday.lumpsumApplication
												)}
											</h4>
											{/* <p>
										<span className="decrease">- 5% </span> from yesterday
									</p> */}
										</div>

										<div className="dashboard__counter-content">
											<h6>SIP Application</h6>
											<h4>
												{summeryforday.sipApplication === undefined ? (
													0
												) : (
													summeryforday.sipApplication
												)}
											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>

									</div>
									<div className="dashboard__counter-divider" />
									<div className="dashboard__counter">

										<div className="dashboard__counter-content">
											<h6>Lumpsum Business Amount</h6>
											<h4>
												&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													summeryforday.lumpsumAmount === undefined
														? 0
														: summeryforday.lumpsumAmount
												)}

											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>
										<div className="dashboard__counter-content">
											<h6>SIP Business Amount</h6>
											<h4>
												&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													summeryforday.sipTotalAmount === undefined
														? 0
														: summeryforday.sipTotalAmount
												)}

											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>
									</div>

								</div>
							</div>
						</Grid>


						<Grid item xs={12} md={12}>
							<div className="dashboard__counter-header mb-4">
								Summary for the year <span> {fromdate === null || todate === null ? "" : `(From: ${fromdate == null ? "" : moment(new Date(fromdate)).format('DD/MM/yyyy')} - To: ${todate == null ? "" : moment(new Date(todate)).format('DD/MM/yyyy')})`}</span>
								<div className="dashboard__counter-header-bg" />
							</div>
						</Grid>
						<Grid item xs={12} md={12}>
							<div className="dashboard__counter-border">

								<div className="dashboard__counters">
									<div className="dashboard__counter">
										<div className="dashboard__counter-content">
											<h6>As per NIMF system (Lumpsum)</h6>
											<h4>&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													nimfsystemyear.nimfYLumpsum === undefined ? 0 : nimfsystemyear.nimfYLumpsum
												)}</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
										</div>
										<div className="dashboard__counter-content">
											<h6>As per NIMF system (SIP)</h6>
											<h4>&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													nimfsystemyear.nimfYSip === undefined ? 0 : nimfsystemyear.nimfYSip
												)}</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
										</div>


									</div>
									<div className="dashboard__counter-divider" />
									<div className="dashboard__counter">

										<div className="dashboard__counter-content">

											<h6>Lumpsum Target</h6>
											<h4>&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													YearlyDsrtargetdetails.lumpsumTarget === undefined ? 0 : YearlyDsrtargetdetails.lumpsumTarget
												)}
											</h4>
											{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
										</div>
										<div className="dashboard__counter-content">

											<h6>SIP Target</h6>
											<h4>&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													YearlyDsrtargetdetails.sipTarget === undefined ? 0 : YearlyDsrtargetdetails.sipTarget
												)}
											</h4>
											{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
										</div>

									</div>
									<div className="dashboard__counter-divider" />

									<div className="dashboard__counter">

										<div className="dashboard__counter-content">
											<h6>Total Meetings</h6>
											<h4>
												{summeryforyear.totalMeetingsDone === undefined ? (
													0
												) : (
													summeryforyear.totalMeetingsDone
												)}
											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>
									</div>



									<div className="dashboard__counter-divider" />

									<div className="dashboard__counter">
										<div className="dashboard__counter-content">
											<h6>Lumpsum Application</h6>
											<h4>
												{summeryforyear.lumpsumApplication === undefined ? (
													0
												) : (
													summeryforyear.lumpsumApplication
												)}
											</h4>
											{/* <p>
										<span className="decrease">- 5% </span> from yesterday
									</p> */}
										</div>

										<div className="dashboard__counter-content">
											<h6>SIP Application</h6>
											<h4>
												{summeryforyear.sipApplication === undefined ? (
													0
												) : (
													summeryforyear.sipApplication
												)}
											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>

									</div>
									<div className="dashboard__counter-divider" />
									<div className="dashboard__counter">

										<div className="dashboard__counter-content">
											<h6>Lumpsum Business Amount</h6>
											<h4>
												&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													summeryforyear.lumpsumAmount === undefined
														? 0
														: summeryforyear.lumpsumAmount
												)}
											</h4>
										</div>
										<div className="dashboard__counter-content">
											<h6>SIP Business Amount</h6>
											<h4>
												&#x20B9;{' '}
												{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
													summeryforyear.sipTotalAmount === undefined
														? 0
														: summeryforyear.sipTotalAmount
												)}
											</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
										</div>
									</div>

								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	)
}

export default Dashboard