import React from 'react'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
import AdminUserManagementContent from '../sections/AdminUserManagement/AdminUserManagementContent'

const AdminUserManagement = () => {
  return (
    <div><AdminTopNavigation />
      <AdminUserManagementContent/>
    </div>
  )
}

export default AdminUserManagement