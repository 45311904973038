// import update from 'react-addons-update';

const initialState = {
	fmcgDetails: [],
	onfmcgchange: false
};
const topfmcgreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_FMCG':
			return {
				...state,
				fmcgDetails: action.payload
			};
		case 'UPDATE_FMCG':
			return {
				...state,
				fmcgDetails: action.payload
			};
		case 'CLEAR_FMCG':
			return {
				...state,
				fmcgDetails: action.payload
			};
		default:
			return state;
	}
};

export default topfmcgreducer;
