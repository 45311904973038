import React from 'react'
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation'
import SupervisorMandSixMReviewReportContent from '../sections/SupervisorMandSixMReviewReport/SupervisorMandSixMReviewReportContent'
const SupervisorMandSixMreviewReport = () => {
    return (
        <div>
            <SupervisorTopNavigation />
            <SupervisorMandSixMReviewReportContent />
        </div>
    )
}

export default SupervisorMandSixMreviewReport