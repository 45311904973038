import React from 'react'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
import AdminSupervisorManagementContent from '../sections/AdminSupervisorManagement/AdminSupervisorManagementContent'
const AdminSupervisorManagement = () => {
  return (
    <div>
      <AdminTopNavigation />
      <AdminSupervisorManagementContent />
    </div>    
  )
}

export default AdminSupervisorManagement