import TopNavigation from "../../layouts/TopNavigation";
import SideNavigation from '../../layouts/SideNavigation';
import ComingSoonImg from "../../../assets/images/comingsoon.jpg";
const ComingsoonContent = () => {
  return (
    <div>
        <TopNavigation />
        <SideNavigation />
        <div style={{padding:'140px'}} >
          <span className="coming-soon">Coming Soon</span>
         <p> We are currently working on something awesome. Stay tuned!</p>
        </div>
        
    </div>
  )
}

export default ComingsoonContent;