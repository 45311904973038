import React, { useState, useEffect } from 'react';
import { FormControl, Button, Grid } from '@mui/material';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import { getuserprofiledetails, updateuserprofiledetails } from '../../Api/DashboardApi';
import { getallsupervisorlist } from "../../Api/AdminApi"
import Auth from '../../../modules/Auth/index';
import Swal from 'sweetalert2';
import LoadingScreen from '../../layouts/LoadingScreen';
import { Container, Row, Col } from 'react-bootstrap';

const UserProfileContent = () => {
	const UserId = Auth.getUserId();
	const [statelist, setStatelist] = useState([]);
	const [citieslist, setCitieslist] = useState([]);
	const [userdetails, setUserdetails] = useState({});
	const [supvisorList, setSupvisorList] = useState([]);
	const [selectSupervisor, setSelectSupervisor] = useState([]);
	const [supervisorPhone, setSupervisorPhone] = useState([])

	const [countrySelected, setCountrySelected] = useState([]);
	const [stateSelected, setStateSelected] = useState([]);
	const [citiesSelected, setCitiesSelected] = useState([]);
	const [newUserErr, setnewUserErr] = useState({});
	const [loading, setLoading] = useState(false);
	const validation = () => {
		let newUserErr = {};
		let isValid = true;

		if (stateSelected.length < 1) {
			newUserErr['stateSelected'] = 'Please Select State!';
			isValid = false;
		}
		if (citiesSelected.length < 1 && citieslist.length !== 0) {
			newUserErr['citiesSelected'] = 'Please Select City!';
			isValid = false;
		}

		setnewUserErr(newUserErr);
		// setIsCheckedErr(isCheckedErr);
		return isValid;
	};

	useEffect(() => {
		getStates();
		getSupervisorList();

		let Countries = Country.getCountryByCode('IN');
		setCountrySelected({ label: Countries.name, value: Countries.name, isoCode: Countries.isoCode });
		GetUserProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getStates = () => {
		let States = State.getStatesOfCountry('IN');
		let result = States.map((f) => {
			return { label: f.name, value: f.name, isoCode: f.isoCode, countryCode: f.countryCode };
		});
		setStatelist(result);
	};
	const getCities = (countryCode, stateCode) => {
		let Cities = City.getCitiesOfState(countryCode, stateCode);
		let result = Cities.map((f) => {
			return { label: f.name, value: f.name, stateCode: f.stateCode, countryCode: f.countryCode };
		});
		setCitieslist(result);
	};
	const handlechangeStates = (selected) => {
		setStateSelected(selected);
		getCities(selected.countryCode, selected.isoCode);
		setCitiesSelected([]);
		setnewUserErr({ ...newUserErr, stateSelected: '', citiesSelected: '' });
	};
	const handlechangeCities = (selected) => {
		setCitiesSelected(selected);
		setnewUserErr({ ...newUserErr, citiesSelected: '' });
	};
	const GetUserProfile = () => {
		setLoading(true);
		getuserprofiledetails(UserId)
			.then((res) => {
				if (res.data.authenticationResponse.status === 1) {
					let result = res.data.authenticationResponse.data.details;
					setUserdetails(result);
					setSupervisorPhone(result.supervisorMobileNo)
					let States = State.getStatesOfCountry('IN');
					let findstate = States.find((f) => f.name === result.userState);
					setStateSelected({
						label: result.userState,
						value: result.userState,
						isoCode: findstate.isoCode,
						countryCode: findstate.countryCode
					});
					setSelectSupervisor({ label: result.supervisorName, value: result.supervisorId })
					getCities(findstate.countryCode, findstate.isoCode);
					setCitiesSelected({
						label: result.userCity,
						value: result.userCity,
						stateCode: findstate.isoCode,
						countryCode: 'IN'
					});
				} else {
					setUserdetails({});
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	const getSupervisorList = (type) => {
		setLoading(true);
		let data = {
				supervisorName: ""
			}
		
	
		getallsupervisorlist(data).then((res) => {
			setLoading(false);
			if (res.data.response.status === 1) {
				let result = res.data.response.data.supervisor;
		
				let resultSelect = result.map((f) => {
					return { label: f.supervisorName, value: f.supervisorId, phoneNo: f.supervisorMobileNo };
				});
				setSupvisorList(resultSelect);
			}
		}).catch((err) => {
			setLoading(false);
			console.log(err, "err in getsupervisorlist")
		})
	}

	const handlechangeSupervisor = (selected) =>{

		setSelectSupervisor(selected)
		setSupervisorPhone(selected.phoneNo)
		setnewUserErr({ ...newUserErr, selectSupervisor: '' });
	}
	
	const UpdateProfileDetails = () => {
		let isvalid = validation();
		if (isvalid) {
			let data = {
				userId: UserId,
				SupervisorId: selectSupervisor.value
				// userState: stateSelected.value,
				// userCity: citieslist.length === 0 ? "" : citiesSelected.value,
				
			};
			setLoading(true);
			updateuserprofiledetails(data)
				.then((res) => {
					setLoading(false);
					if (res.data.authenticationResponse.status === 1) {
						Swal.fire('User details updated successfully', '', 'success');
					} else {
						Swal.fire('Something Went Wrong', '', 'error');
					}
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
	};
	return (
		<div>
			{loading === true ? <LoadingScreen /> : null}
			<Container >
				<Row>
					<Col className="left">
						<h4>Edit Profile</h4>
						<div className="sub-heading-bg" />
					</Col>
				</Row>
				&nbsp;
				<Row>
					<Col className="profile_box">
						{' '}
						<Grid container spacing={2} rowSpacing={3} paddingRight={10} padding={3}>
							<Grid item xs={6} md={2} className="left">
								ARN Code:
							</Grid>
							<Grid item xs={6} md={10} className="left">
								<b>{userdetails.userArnCode}</b>
							</Grid>
							<Grid item xs={6} md={2} className="left">
								User Name:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{userdetails.userName}
							</Grid>
							<Grid item xs={6} md={2} className="left">
								Email Id:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{userdetails.userEmailId}
							</Grid>
							<Grid item xs={6} md={2} className="left">
								PAN:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{userdetails.userPanNo}
							</Grid>
							<Grid item xs={6} md={2} className="left">
								Country:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{userdetails.userCountry}
							</Grid>
							<Grid item xs={6} md={2} className="left" marginTop={1}>
								State:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{stateSelected.label}
								{/* <FormControl sx={{ maxWidth: '20rem' }} fullWidth>
									<Select options={statelist} value={stateSelected} onChange={handlechangeStates} />
									{newUserErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{newUserErr.stateSelected}
										</div>
									) : null}
								</FormControl> */}
							</Grid>
							<Grid item xs={6} md={2} className="left" marginTop={1}>
								City:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{citiesSelected.label}
								{/* <FormControl sx={{ maxWidth: '20rem' }} fullWidth>
									<Select options={citieslist} value={citiesSelected} onChange={handlechangeCities} isDisabled={citieslist.length === 0} />
									{newUserErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{newUserErr.citiesSelected}
										</div>
									) : null}
								</FormControl> */}
							</Grid>
							<Grid item xs={6} md={2} className="left">
								PIN code:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{userdetails.userPinCode}
							</Grid>
						<Grid item xs={6} md={2} className="left" marginTop={1}>
								Supervisor Name:
							</Grid>
							
							<Grid item xs={6} md={4} className="left">
								{/* {userdetails.supervisorName} */}
								<FormControl sx={{ maxWidth: '20rem' }} fullWidth>
									<Select options={supvisorList} value={selectSupervisor} onChange={handlechangeSupervisor} />
									{newUserErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{newUserErr.selectSupervisor}
										</div>
									) : null}
								</FormControl>
							</Grid>
							<Grid item xs={6} md={2} className="left">
								Supervisor Mobile No:
							</Grid>
							<Grid item xs={6} md={4} className="left">
								{supervisorPhone}
							</Grid>
							<Grid item xs={12} md={12} marginTop={3}>
								<Button
									variant="contained"
									color="primary"
									className="btn"
									onClick={() => UpdateProfileDetails()}
								>
									Update
								</Button>
							</Grid>
						</Grid>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default UserProfileContent;
