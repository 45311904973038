import BaseUrl from '../../modules/config/BaseUrl';
import axios from '../../modules/helpers/axiosInstance';

const getalluserlist = (Date) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Admin/GetUserDetails?Date=${Date}`
	});
};
const activeinactiveuser = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Admin/ActiveDeactiveUserType`,
		data: data
	});
};
const updateuserlist = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Admin/UpdateUserDetails`,
		data: data
	});
};
const getallsupervisorlist = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Admin/GetSupervisorDetails`,
		data: data
	});
};
const activeinactivesupervisor = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Admin/ActiveDeactiveSupervisorType`,
		data: data
	});
};
const addsupervisor = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Admin/AddSupervisorDetails`,
		data: data
	});
};
const updatesupervisor = (data) => {
	return axios({
		method: 'PUT',
		url: `${BaseUrl.MainGateWayUrl}/Admin/UpdateSupervisorDetails`,
		data: data
	});
};
const getadmindsrdetails = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterDSRDetails`,
		data: data
	});
};
const getuserarn = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetUserArnCode`,
	});
};
const getadminmarketscopedetails = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterMSDetails`,
		data: data
	});
};
const getadminmonthlysixmonthlyreviewreport = (data) => {
	return axios({
		method: "POST",
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterMonthlyDetails`,
		data: data
	})
}

const getallsector = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/GetSector`
	})
}
const getallsupervisor = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Authentication/GetAllSupervisorDetails`
	})
}
const uploadmodule = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Admin/ReadFile`,
		data: data
	})
}
const mastergetall = (url) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}${url}`
	})
}
const masteradd = (url, data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}${url}`,
		data: data
	})
}
const masterupdate = (url, data) => {
	return axios({
		method: 'PUT',
		url: `${BaseUrl.MainGateWayUrl}${url}`,
		data: data
	})
}
const masterdelete = (url) => {
	return axios({
		method: 'DELETE',
		url: `${BaseUrl.MainGateWayUrl}${url}`,
	})
}
export {
	getalluserlist,
	activeinactiveuser,
	updateuserlist,
	getallsupervisorlist,
	activeinactivesupervisor,
	addsupervisor,
	updatesupervisor,
	getadmindsrdetails,
	getuserarn,
	getadminmarketscopedetails,
	getadminmonthlysixmonthlyreviewreport,
	getallsector,
	getallsupervisor,
	uploadmodule,
	mastergetall,
	masteradd,
	masterupdate,
	masterdelete
}