// import axios from 'axios';

import BaseUrl from '../../modules/config/BaseUrl';
import axios from '../../modules/helpers/axiosInstance';

const getdailysalesreportbyuseridanddate = (UserId, Date) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetDailySalesReportDetailsByUserID?UserId=${UserId}&Date=${Date}`
	});
};

const getdailysalesreportheaderdetails = (UserId, Date) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetDailySalesReportHeader?UserId=${UserId}&Date=${Date}`
	});
};

const getProductpitchedDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetProductsDetails`
	});
};
const getSchemeDetails = (category) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetSchemeCategory?category=${category}`
	});
};
const getRemarkDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetRemarksDetails`
	});
};

const AddDailySalesReport = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/AddDailySalesReportDetails`,
		data: data
	});
};
const UpdateDailySalesReport = (data) => {
	return axios({
		method: 'PUT',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/UpdateDailySalesReportDetails`,
		data: data
	});
};
const DeleteDailySalesReport = (dailyreportid) => {
	return axios({
		method: 'DELETE',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/DeleteDailySalesReportDetailsByID?DailyReportId=${dailyreportid}`
	});
};
const dailyreportcallalert = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/CallTypeAlert?UserId=${UserId}`
	});
};
const dsrdatealert = (UserId, PreviousDay) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/DateAlert?UserId=${UserId}&Date=${PreviousDay}`
	});
};

const dsrDailyReportDateRange = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterDSRARNDetails`,
		// url: `https://localhost:44339/api/DailySalesReport/FilterDSRARNDetails`,
		data: data
	});
};

const getSourceDetails = () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetSourceDetails`
	});
};



export {
	/* get daily report table and header api*/
	getdailysalesreportbyuseridanddate,
	getdailysalesreportheaderdetails,
	/* get daily report form dropdowns deatils api */
	getProductpitchedDetails,
	getSchemeDetails,
	getRemarkDetails,
	getSourceDetails,
	/* Daily sales report form report */
	AddDailySalesReport,
	UpdateDailySalesReport,
	DeleteDailySalesReport,
	/* Call alert api */
	dailyreportcallalert,
	dsrdatealert,
	dsrDailyReportDateRange,
};
