import React, { useState, useEffect } from 'react'
// import "react-calendar/dist/Calendar.css";
import { Grid, FormControl, Button, IconButton } from '@mui/material';
import LoadingScreen from '../../layouts/LoadingScreen';
import Select from 'react-select';
import Calender from 'react-calendar'
import moment from 'moment/moment';
import Auth from '../../../modules/Auth';
import { getarndsrstatusreport } from '../../Api/SupervisorAPI';
import { getuserarn } from '../../Api/AdminApi';
import InfoIcon from '@mui/icons-material/Info';
const AdminArnDSRStatusReportcontent = () => {
    const [arnf, setArnf] = useState([]);
    const [arnOption, setArnOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [datesdata, setDatesdata] = useState([]);
    const supervisorid = Auth.getUserId()
    useEffect(() => {
        GetUserArns()
    }, [])
    useEffect(() => {
        if (arnf.value !== undefined) {
            GetArnDSRStatusReport();
        }
    }, [arnf])
    
    const GetUserArns = () => {
        getuserarn().then((res) => {
            if (res.data.response.status === 1) {
                let arnlist = res.data.response.data.arnCodeList;
                setArnOption(arnlist)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const GetArnDSRStatusReport = () => {
        setLoading(true);
        getarndsrstatusreport(arnf.value).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                let dates = res.data.response.data;
                setDatesdata(dates);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }
    // const [date, setDate] = useState(preselectedDays)
    return (
        <div className='p-4'>
            {loading === true ? <LoadingScreen /> : null}
            <Grid container spacing={0} padding={0} rowSpacing={2}>
                <Grid item md={12} xs={12} alignContent="flex-start">
                    <div className='sub-heading'>Daily Reporting Stats</div>
                </Grid>
                <Grid item xs={12} md={12} className={'p-4'}>

                    <Grid container spacing={2} padding={0} rowSpacing={1.5} className={'admin__search-bar'}>

                        <Grid item xs={12} md={2.4}>
                            <FormControl fullWidth className="textfield mb-4">
                                <label>ARN Code</label>
                                <Select
                                    value={arnf}

                                    options={arnOption}
                                    onChange={(selected) => {
                                        setArnf(selected);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className='row justify-content-center'>
                        <Grid item xs={12} md={12} display="flex" justifyContent="center" className='mt-4'>
                            <Calender
                                tileClassName={({ date, view }) => {
                                    if (datesdata.find(x => moment(x.date).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))) {
                                        let x = datesdata.find(x => moment(x.date).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))
                                        return x.status == 0 ? 'greencolor' : x.status == 1 ? 'yellowcolor' : x.status == 2 ? 'highlight' : ""
                                    }
                                }}
                                tileContent={({ date, view }) => {
                                    if (datesdata.find(x => moment(x.date).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))) {
                                        let x = datesdata.find(x => moment(x.date).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))
                                        return x.status === 1  ? <div title={`Submitted on: ${moment(x.subdate).format("DD-MM-YYYY")}`}><InfoIcon fontSize='10px' /></div> : null
                                        // return x.status == 0 ? <div title='submit '
                                        // >greencolor</div> : x.status == 1 ? 'yellowcolor' : x.status == 2 ? 'red' : ""
                                    }
                                }}
                            />
                        </Grid>


                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className='legends mt-4 justify-content-center'>
                                <ul class="legend">
                                    <li><span class="superawesome"></span> Report - Not submitted</li>
                                    <li><span class="awesome"></span>  Report - Late Submitted</li>
                                    <li><span class="kindaawesome"></span> Report - Submitted on time</li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default AdminArnDSRStatusReportcontent