import React from 'react'
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation'
import SupervisorMarketScopeReportContent from '../sections/SupervisorMarketScopeReport/SupervisorMarketScopeReportContent'

const SupervisorMarketScopeReport = () => {
  return (
    <div>
      <SupervisorTopNavigation/>
      <SupervisorMarketScopeReportContent />
    </div>
  )
}

export default SupervisorMarketScopeReport