import React from 'react';
import ScopepointsContent from '../sections/ScopePoints/ScopepointsContent';

const ScopePoints = () => {
    return (
        <div>
            <ScopepointsContent />
        </div>
    );
};

export default ScopePoints;
