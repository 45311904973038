import React, { useState, useEffect } from 'react';
import {
	// Paper,
	Typography,
	TextField,
	FormControl,
	// Button,
	// Container,
	// Grid,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	Select,
	IconButton,
	MenuItem
} from '@mui/material';

import nippon_logo from '../../../assets/images/nipponindia-mutual-fund-logo.png';
import HowToRegIcon from '@mui/icons-material/HowToReg';
//import Select from 'react-select';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import ReCAPTCHA from 'react-google-recaptcha';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cleave from 'cleave.js/react';
import BaseUrl from '../../../modules/config/BaseUrl';
const RegisterContent = () => {
	const Navigate = useNavigate();
	const NavigateLogin = () => {
		Navigate('/login');
	};
	/* start registratiion state */
	const [newUser, setNewUser] = useState({
		ARNcode: '',
		Name: '',
		Email: '',
		Mobileno: '',
		Panno: '',
		PinCode: '',
		Supervisorname: '',
		Supervisormobileno: '',
		Password: '',
		Cnfpassword: ''
	});

	const [countrySelected, setCountrySelected] = useState([]);
	const [stateSelected, setStateSelected] = useState([]);
	const [citiesSelected, setCitiesSelected] = useState([]);
	const [isverified, setIsverified] = useState(false);
	/* end registratiion state */
	// const [countrylist, setCountrylist] = useState([]);
	const [statelist, setStatelist] = useState([]);
	const [citieslist, setCitieslist] = useState([]);

	const [showpassword, setShowPassword] = useState(false);
	const [showcnfpassword, setShowCnfPassword] = useState(false);

	const [newUserErr, setnewUserErr] = useState({});
	const [supervisor, setSupervisor] = useState("");
	const [supervisoroption, setSupervisoroption] = useState([]);
	const [loading, setLoading] = useState(false);
	/* start country state cities manage */

	useEffect(() => {
		let Countries = Country.getCountryByCode('IN');
		setCountrySelected({ label: Countries.name, value: Countries.name, isoCode: Countries.isoCode });
		const getStates = () => {
			let States = State.getStatesOfCountry('IN');
			let result = States.map((f) => {
				return { label: f.name, value: f.name, isoCode: f.isoCode, countryCode: f.countryCode };
			});
			setStatelist(result);
		};
		getStates();
		SupervisorList();
	}, []);
	useEffect(() => {
		if (stateSelected.value !== undefined || citiesSelected.value !== undefined) {
			SupervisorList();
		}
	}, [stateSelected, citiesSelected])

	const getCities = (countryCode, stateCode) => {
		let Cities = City.getCitiesOfState(countryCode, stateCode);
		let result = Cities.map((f) => {
			return { label: f.name, value: f.name, stateCode: f.stateCode, countryCode: f.countryCode };
		});
		setCitieslist(result);
	};
	// const handlechangeCountries = (event) => {
	// 	let { value } = event.target;
	// 	setCountrySelected(value);
	// 	getStates(value.isoCode);
	// 	setStateSelected([]);
	// 	setCitiesSelected([]);
	// 	setnewUserErr({ ...newUserErr, countrySelected: '', stateSelected: '', citiesSelected: '' });
	// };
	const handlechangeStates = (event) => {
		let { value } = event.target;
		setStateSelected(value);
		getCities(value.countryCode, value.isoCode);
		setCitiesSelected([]);
		setnewUserErr({ ...newUserErr, stateSelected: '', citiesSelected: '' });
	};
	const handlechangeCities = (event) => {
		let { value } = event.target;
		setCitiesSelected(value);
		setnewUserErr({ ...newUserErr, citiesSelected: '' });
	};
	const handlechangesupervisor = (event) => {
		let { value } = event.target;
		setSupervisor(parseInt(value));
		setnewUserErr({ ...newUserErr, supervisorlist: '' });
	};
	/* end country state cities manage */
	/* start captcha */
	function onChange(value) {
		// console.log('Captcha value:', value);
		if (value !== null) {
			setIsverified(true);
			setnewUserErr({ ...newUserErr, ischkd: '' });
		}
	}
	const grecaptchaObject = window.grecaptcha;
	/* end captcha */

	/* start show hide password and cnfpassword */
	const handleClickShowPassword = () => {
		setShowPassword(!showpassword);
	};
	const handleClickCnfShowPassword = () => {
		setShowCnfPassword(!showcnfpassword);
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	/* end show hide password and cnfpassword */

	/* start register onchange handlers */
	const handleChangeReg = (e) => {
		const name = e.target.name;
		const value = name === "Panno" ? e.target.value.toUpperCase() : e.target.value
		setNewUser({ ...newUser, [name]: value });
		// setIsChecked(true);
		if (name === 'ARNcode' && value !== null) {
			setnewUserErr({ ...newUserErr, ARNcode: '' });
		}
		if (name === 'Name' && value !== null) {
			setnewUserErr({ ...newUserErr, Name: '' });
		}
		if (name === 'Email' && value !== null) {
			setnewUserErr({ ...newUserErr, Email: '' });
		}
		if (name === 'Mobileno' && value !== null) {
			setnewUserErr({ ...newUserErr, Mobileno: '' });
		}
		if (name === 'Panno' && value !== null) {
			setnewUserErr({ ...newUserErr, Panno: '' });
		}
		if (name === 'PinCode' && value !== null) {
			setnewUserErr({ ...newUserErr, PinCode: '' });
		}
		if (name === 'Supervisorname' && value !== null) {
			setnewUserErr({ ...newUserErr, Supervisorname: '' });
		}
		if (name === 'Supervisormobileno' && value !== null) {
			setnewUserErr({ ...newUserErr, Supervisormobileno: '' });
		}
		if (name === 'Password' && value !== null) {
			setnewUserErr({ ...newUserErr, Password: '' });
		}
		if (name === 'Cnfpassword' && value !== null) {
			setnewUserErr({ ...newUserErr, Cnfpassword: '' });
		}
		// if (isChecked === true) {
		//     setIsCheckedErr(" ");
		// }
	};
	/* end register onchange handlers */
	/* start regisration validation*/
	const validation = () => {
		const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		const phoneregex = /^[6-9]\d{9}$/gi;
		const pincoderegex = /^(\d{6})$/;
		// const sphoneregex = /^[6-9]\d{9}$/gi;
		const panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
		let spaceAndLetter = /\S/;
		let newUserErr = {};
		let isValid = true;
		if (newUser.ARNcode === '' || newUser.ARNcode === null) {
			newUserErr['ARNcode'] = 'Please enter ARN code.';
			isValid = false;
		} else if (spaceAndLetter.test(newUser.ARNcode) === false) {
			newUserErr['ARNcode'] = 'Please enter with no spaces.';
			isValid = false;
		} else if (newUser.ARNcode.length !== 10) {
			newUserErr['ARNcode'] = 'Invalid ARN Code.';
			isValid = false;
		}
		if (newUser.Name.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === '' || newUser.Name === null) {
			newUserErr['Name'] = 'Please enter name.';
			isValid = false;
		}
		if (supervisor === "") {
			// supervisorlist
			newUserErr['supervisorlist'] = 'Please select supervisor.';
			isValid = false;
		}
		// if (newUser.Supervisorname.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === '' || newUser.Supervisorname === null) {
		// 	newUserErr['Supervisorname'] = 'Please enter supervisor name';
		// 	isValid = false;
		// }
		if (countrySelected.length < 1) {
			newUserErr['countrySelected'] = 'Please select country.';
			isValid = false;
		}
		if (stateSelected.length < 1) {
			newUserErr['stateSelected'] = 'Please select state.';
			isValid = false;
		}
		if (citiesSelected.length < 1 && citieslist.length !== 0) {
			newUserErr['citiesSelected'] = 'Please select city.';
			isValid = false;
		}

		if (newUser.Panno === '' || newUser.Panno === null) {
			newUserErr['Panno'] = 'Please enter pan number.';
			isValid = false;
		} else if (panregex.test(newUser.Panno.toUpperCase()) === false) {
			newUserErr['Panno'] = 'Invalid pan number.';
			isValid = false;
		}
		if (newUser.Email === '' || newUser.Email === null) {
			newUserErr['Email'] = 'Please enter email id.';
			isValid = false;
		} else if (emailregex.test(newUser.Email) === false) {
			newUserErr['Email'] = 'Please enter valid email id.';
			isValid = false;
		}
		if (newUser.Mobileno === '' || newUser.Mobileno === null) {
			newUserErr['Mobileno'] = 'Please enter 10 digit mobile number.';
			isValid = false;
		} else if (phoneregex.test(Number(newUser.Mobileno)) === false) {
			newUserErr['Mobileno'] = 'Please enter 10 digit valid mobile number.';
			isValid = false;
		}

		if (newUser.Password && newUser.Cnfpassword) {
			if (newUser.Password !== newUser.Cnfpassword) {
				newUserErr['Cnfpassword'] = 'Password does not match.';
				isValid = false;
			}
		}
		if (newUser.PinCode === "") {
			newUserErr['PinCode'] = 'Please enter PIN code.';
			isValid = false;
		} else if (pincoderegex.test(Number(newUser.PinCode)) === false) {
			newUserErr['PinCode'] = 'Invalid PIN code.';
			isValid = false
		}
		// if (newUser.Supervisormobileno === '' || newUser.Supervisormobileno === null) {
		// 	newUserErr['Supervisormobileno'] = 'Please enter 10 digit mobile number';
		// 	isValid = false;
		// } else if (sphoneregex.test(Number(newUser.Supervisormobileno)) === false) {
		// 	newUserErr['Supervisormobileno'] = 'Please enter 10 digit valid mobile number';
		// 	isValid = false;
		// }
		if (newUser.Password === '' || newUser.Password === null) {
			newUserErr['Password'] = 'Please enter password.';
			isValid = false;
		} else if (passregex.test(newUser.Password) === false) {
			newUserErr['Password'] =
				'Password must be a minimum 8 characters and must contain combination of Numbers, both Uppercase and lowercase letters and special character.';
			isValid = false;
		}
		if (newUser.Cnfpassword === '' || newUser.Cnfpassword === null) {
			newUserErr['Cnfpassword'] = 'Please enter confirm password.';
			isValid = false;
		}
		if (isverified !== true) {
			newUserErr['ischkd'] = 'Please click on the captcha field.';
			isValid = false;
		}
		setnewUserErr(newUserErr);
		// setIsCheckedErr(isCheckedErr);
		return isValid;
	};
	/* end regisration validation*/

	/* handle submit register */

	const handleRegSubmit = (e) => {
		const isValid = validation();
		if (isValid) {
			let regiUser = {
				userName: newUser.Name,
				userEmailId: newUser.Email,
				userPassword: newUser.Password,
				userConfirmPassword: newUser.Cnfpassword,
				userMobileNo: newUser.Mobileno,
				userArnCode: newUser.ARNcode,
				userPanNo: newUser.Panno.toUpperCase(),
				userPinCode: newUser.PinCode,
				userState: stateSelected.value,
				SupervisorId: Number(supervisor),
				userCountry: countrySelected.value,
				userCity: citiesSelected.value,
				userAddress: '',
				AcceptTerms: true
			};
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setLoading(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/register`, regiUser, headers)
				.then((res) => {
					if (res.status === 200) {
						setLoading(false);
						if (res.data.authenticationResponse.status === 1) {
							Swal.fire(
								'Registered Successfully',
								'Please check your email for verifying account before login.',
								'success'
							);
							Navigate('/login');
						} else {
							Swal.fire('something went wrong', '', 'info');
						}
					} else {
						setLoading(false);
						Swal.fire('something went wrong', '', 'info');
					}
				})
				.catch((err) => {
					setLoading(false);
					// if (err.response.data !== null && err.response.data !== undefined) {
					// 	if (err.response.data.authenticationResponse.message === 'Already Registered') {
					// 		Swal.fire('Email or Mobile No. and Pan already registered', '', 'warning');
					// 	} else {
					// 		Swal('Invalid registration details', '', 'info');
					// 	}
					// }
					if (err.response.data.authenticationResponse.message) {
						Swal.fire(`${err.response.data.authenticationResponse.message}`, '', 'warning');
					} else {
						Swal('Invalid registration details', '', 'info');
					}
				});
		}
	};
	const SupervisorList = () => {
		let data = {
			"SupervisorState": stateSelected.value === undefined ? "NA" : stateSelected.value,
			"SupervisorCity": citiesSelected.value === undefined ? "NA" : citiesSelected.value
		}
		let headers = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		axios.post(`${BaseUrl.MainGateWayUrl}/Supervisor/GetSupervisorDetails`, data, headers).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data.supervisor;
				setSupervisoroption(result);
				// console.log(res, "saurabh")
			} else {
				setSupervisoroption([]);
			}
		}).catch((err) => {
			setSupervisoroption([]);
			console.log(err, "err in supervisor get")
		})
	}
	return (

		<div className="container-fluid registration-bg">
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<div className="logo-img-reg">
							<img src={nippon_logo} alt="Nippon India" />
						</div>
					</div>
				</div>
				<div className='row p-b-2' >
					<div className='col-md-12 center'>
						<div className=' registration-box  p-4'>
							<form>
								<div className='row'>
									<div className='col-md-12'>
										<h3 className="label-heading">Create your account</h3>
									</div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										{/* <TextField
											variant="outlined"
											className="txt-field"
											type="text"
											placeholder="Enter Your ARN Code"
											label="ARN Code"
											size="small"
											name="ARNcode"
											value={newUser.ARNcode}
											onChange={handleChangeReg}
										/> */}
										<Cleave
											name="ARNcode"
											className="txt-field1"
											placeholder="Enter your ARN number"
											options={{
												prefix: "ARN-",
												blocks: [10],
												uppercase: true,
												numericOnly: true
											}}
											value={newUser.ARNcode}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.ARNcode}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										<TextField
											variant="outlined"
											size="small"
											className="txt-field1"
											type="text"
											placeholder="Enter Your Name"
											label="Name"
											name="Name"
											value={newUser.Name}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Name}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										<TextField
											variant="outlined"
											size="small"
											className="txt-field"
											type="email"
											placeholder="Enter Your Email Id"
											label="Email ID"
											name="Email"
											value={newUser.Email}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Email}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										<TextField
											variant="outlined"
											size="small"
											className="txt-field"
											type="number"
											placeholder="Enter Your Mobile Number"
											label="Mobile Number"
											name="Mobileno"
											value={newUser.Mobileno}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Mobileno}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										<TextField
											variant="outlined"
											size="small"
											className="txt-field"
											type="text"
											placeholder="Enter Your PAN No"
											label="PAN"
											name="Panno"
											value={newUser.Panno}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Panno}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										<TextField
											variant="outlined"
											size="small"
											className="txt-field"
											type="text"
											label="Country"
											name="Country"
											disabled
											value="India"
										/>
										{/* <Select
											placeholder="Select Country"
											labelId="sel-country"
											name="Country"
											value={countrySelected}
											input={<OutlinedInput label="Select Country" />}
											disabled
											onChange={handlechangeCountries}
										>
											{countrylist.map((data, index) => (
												<MenuItem
													key={index}
													value={data}
													// style={getStyles(name, personName, theme)}
												>
													{data.label}
												</MenuItem>
											))}
										</Select> */}
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.countrySelected}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl size="small" fullWidth>
										<InputLabel id="sel-country" className='m-label'>Select State</InputLabel>
										<Select
											placeholder="Select State"
											name="State"
											className="txt-field"
											value={stateSelected.length === 0 ? '' : stateSelected}
											onChange={handlechangeStates}
											input={<OutlinedInput label="Select State" />}
										>
											{statelist.map((data, index) => (
												<MenuItem
													key={index}
													value={data}
												// style={getStyles(name, personName, theme)}
												>
													{data.label}
												</MenuItem>
											))}
										</Select>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.stateSelected}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl size="small" fullWidth>
										<InputLabel id="sel-country" className='m-label'>Select City</InputLabel>
										<Select
											placeholder="Select City"
											name="City"
											size="small"
											className="txt-field"
											value={citiesSelected.length === 0 ? '' : citiesSelected}
											onChange={handlechangeCities}
											input={<OutlinedInput label="Select City" />}
											disabled={citieslist.length === 0}
										>
											{citieslist.map((data, index) => (
												<MenuItem
													key={index}
													value={data}
												// style={getStyles(name, personName, theme)}
												>
													{data.label}
												</MenuItem>
											))}
										</Select>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.citiesSelected}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl fullWidth>
										<TextField
											variant="outlined"
											size="small"
											className="txt-field"
											type="number"
											placeholder="Enter your PIN code"
											label="PIN code"
											name="PinCode"
											value={newUser.PinCode}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.PinCode}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-6 col-sm-12'><FormControl size="small" fullWidth>
										<InputLabel id="sel-country" className='m-label'>Select Supervisor</InputLabel>
										<Select
											placeholder="Select Supervisor"
											name="supervisor"
											className="txt-field"
											value={supervisor === "" ? '' : supervisor}
											onChange={handlechangesupervisor}
											input={<OutlinedInput label="Select Supervisor" />}
										>
											{supervisoroption.map((data, index) => (
												<MenuItem
													key={index}
													value={data.supervisorId}
												// style={getStyles(name, personName, theme)}
												>
													{data.supervisorName}
												</MenuItem>
											))}
										</Select>

										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.supervisorlist}
											</div>
										) : null}
									</FormControl>
										{/* <Grid item xs={12} md={6}>
									<FormControl sx={{ m: 1, maxWidth: '20rem' }} fullWidth>
										<TextField
											variant="outlined"
											className="txt-field"
											type="text"
											size="small"
											placeholder="Enter Supervisor Name"
											label="Supervisor Name"
											name="Supervisorname"
											value={newUser.Supervisorname}
											onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Supervisorname}
											</div>
										) : null}
									</FormControl>
								</Grid> */}</div>
									<div className='col-md-6 col-sm-12'><FormControl variant="outlined" fullWidth size="small">
										<InputLabel htmlFor="outlined-adornment-password" className='m-label'>Password</InputLabel>
										<OutlinedInput
											variant="outlined"
											className="txt-field"
											name="Password"
											size="small"
											value={newUser.Password}
											onChange={handleChangeReg}
											autoComplete="on"
											type={showpassword ? 'text' : 'password'}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showpassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											label="Password"
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Password}
											</div>
										) : null}
									</FormControl>

										{/* <Grid item xs={12} md={6}>
									<FormControl sx={{ m: 1, maxWidth: '20rem' }} fullWidth>
										<TextField
											variant="outlined"
											className="txt-field"
											size="small"
											type="number"
											placeholder="Enter Supervisor Mobile No."
											label="Supervisor Mobile No"
											name="Supervisormobileno"
											value={supervisor.supervisorMobileNo}
											disabled
										// onChange={handleChangeReg}
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Supervisormobileno}
											</div>
										) : null}
									</FormControl>
								</Grid> */}</div>
									<div className='col-md-6 col-sm-12'><FormControl variant="outlined" size="small"
										fullWidth
									>
										<InputLabel htmlFor="outlined-adornment-password" className='m-label'>Confirm Password</InputLabel>
										<OutlinedInput
											name="Cnfpassword"
											value={newUser.Cnfpassword}
											onChange={handleChangeReg}
											variant="outlined"
											className="txt-field"

											autoComplete="on"
											type={showcnfpassword ? 'text' : 'password'}
											endAdornment={
												<InputAdornment position="end" >
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickCnfShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showcnfpassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											label="Confirm Password"
										/>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.Cnfpassword}
											</div>
										) : null}
									</FormControl></div>
									<div className='col-md-12 col-sm-12 center'>
										<FormControl>
											<ReCAPTCHA
												grecaptcha={grecaptchaObject}
												data-type="image"
												className='captcha'

												data-theme="dark"
												sitekey="6LcW-r0gAAAAACqQhpduFZJ_4h5MXahnwNBmPF0O"
												onChange={onChange}
												onExpired={() => setIsverified(false)}
											/>
										</FormControl>
										{newUserErr ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{newUserErr.ischkd}
											</div>
										) : null}</div>
									<div className='col-md-12 col-sm-12'>
										<LoadingButton
											size="large"
											variant="contained"
											className="btn-login"
											startIcon={<HowToRegIcon />}
											sx={{ marginTop: 3 }}
											onClick={handleRegSubmit}
											loading={loading}
											loadingPosition="start"
										>
											Register
										</LoadingButton></div>
									<div className='col-md-12 col-sm-12'>

										<Typography className="normaltxt" sx={{ marginTop: '10px', marginBottom: '10px' }}>
											Already a member?{' '}
											<span
												style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
												onClick={() => NavigateLogin()}
											>
												Sign in
											</span>
										</Typography></div>

								</div>
							</form>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
};

export default RegisterContent;
