// import update from 'react-addons-update';

const initialState = {
	cosocietyDetails: [],
	oncosocietychange: false
};
const topcoopersocietyreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_COSociety':
			return {
				...state,
				cosocietyDetails: action.payload
			};
		case 'UPDATE_COSociety':
			return {
				...state,
				cosocietyDetails: action.payload
			};
		case 'CLEAR_COSociety':
			return {
				...state,
				cosocietyDetails: action.payload
			};
		default:
			return state;
	}
};

export default topcoopersocietyreducer;
