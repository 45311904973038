import React, { useState, useEffect } from 'react'
import { Grid, Typography, IconButton, Button, Card, CardActionArea, CardContent } from '@mui/material'
import { Edit, DeleteOutline } from '@mui/icons-material'
import { mastergetall, masteradd, masterupdate, masterdelete } from '../../Api/AdminApi';
import LoadingScreen from '../../layouts/LoadingScreen';
import Swal from 'sweetalert2';
import { faBedPulse } from '@fortawesome/free-solid-svg-icons';
const AdminMasterManageContent = () => {
    const getall = [
        '/KeyIndustry/GetKeySectorMasterDetails',
        '/Fmcg/GetFmcgSectorMasterDetails',
        '/Influencer/GetInfluencerSectorMasterDetails',
        '/KeyIndustry/GetNatureInvMasterDetails',
        '/DailySalesReport/GetProductsDetails',
        '/DailySalesReport/GetRemarksDetails',
        '/DailySalesReport/GetSourceDetails',
    ]
    const adddata = [
        '/KeyIndustry/AddKeySectorMasterDetails',
        '/Fmcg/AddFmcgSectorMasterDetails',
        '/Influencer/AddInfluencerSectorMasterDetails',
        '/KeyIndustry/AddNatureInvMasterDetails',
        '/DailySalesReport/AddProductsDetails',
        '/DailySalesReport/AddRemarksDetails',
        '/DailySalesReport/AddSourceDetails'
    ]
    const updatedata = [
        '/KeyIndustry/UpdateKeySectorMasterDetails',
        '/Fmcg/UpdateFmcgSectorMasterDetails',
        '/Influencer/UpdateInfluencerSectorMasterDetails',
        '/KeyIndustry/UpdateNatureInvMasterDetails',
        '/DailySalesReport/UpdateProductsDetails',
        '/DailySalesReport/UpdateRemarksDetails',
        '/DailySalesReport/UpdateSourceDetails',
    ]
    const deletedata = [
        '/KeyIndustry/DeleteKeySectorMasterDetailsByID',
        '/Fmcg/DeleteFmcgSectorMasterDetailsByID',
        '/Influencer/DeleteInfluencerSectorMasterDetailsByID',
        '/KeyIndustry/DeleteNatureInvMasterDetailsByID',
        '/DailySalesReport/DeleteProductsDetailsByID',
        '/DailySalesReport/DeleteRemarksDetailsByID',
        '/DailySalesReport/DeleteSourceDetailsByID',
    ]
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [tab, setTab] = useState(0);
    const [masterName, setMasterName] = useState("");
    const [masterId, setMasterId] = useState(0);
    const [error, setError] = useState({});
    useEffect(() => {
        ALLmasterget();
    }, [tab])
    const Addmaster = () => {
        if (masterName.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') !== "") {
            setLoading(true);
            let details = {};
            if (tab === 0) {
                details = {
                    sectorName: masterName
                }
            } else if (tab === 1) {
                details = {
                    fmcgSectorName: masterName
                }
            } else if (tab === 2) {
                details = {
                    locationName: masterName
                }
            } else if (tab === 3) {
                details = {
                    natureInvName: masterName
                }
            } else if (tab === 4) {
                details = {
                    productsPitchedName: masterName
                }
            } else if (tab === 5) {
                details = {
                    remarksName: masterName
                }
            } else if (tab === 6) {
                details = {
                    sourceName: masterName
                }
            }
            masteradd(adddata[tab], details).then((res) => {
                setLoading(false);
                if (res.data.response.status === 1) {
                    handleClear();
                    Swal.fire(res.data.response.message, '', 'success').then((result) => {
                        ALLmasterget();
                    });;
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err, 'err in master add')
            });
        } else {
            let err = {};
            err['masterName'] = 'Please enter name.'
            setError(err);
        }
    }
    const Updatemaster = () => {
        Swal.fire({
            icon: 'warning',
            title: 'If you update this, all the entries will be impacted.',
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                if (masterName.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') !== "") {
                    setLoading(true);
                    let details = {};
                    if (tab === 0) {
                        details = {
                            sectorName: masterName,
                            sectorId: masterId
                        }
                    } else if (tab === 1) {
                        details = {
                            fmcgSectorName: masterName,
                            fmcgSectorId: masterId
                        }
                    } else if (tab === 2) {
                        details = {
                            locationName: masterName,
                            locationId: masterId
                        }
                    } else if (tab === 3) {
                        details = {
                            natureInvName: masterName,
                            natureInvId: masterId
                        }
                    } else if (tab === 4) {
                        details = {
                            productsPitchedName: masterName,
                            productsPitchedId: masterId
                        }
                    } else if (tab === 5) {
                        details = {
                            remarksName: masterName,
                            remarksId: masterId
                        }
                    } else if (tab === 6) {
                        details = {
                            sourceName: masterName,
                            sourceId: masterId
                        }
                    }
                    masterupdate(updatedata[tab], details).then((res) => {
                        setLoading(false);
                        if (res.data.response.status === 1) {
                            handleClear();
                            Swal.fire(res.data.response.message, '', 'success').then((result) => {
                                ALLmasterget();
                            });;
                        }
                    }).catch((err) => {
                        setLoading(false);
                        console.log(err, 'err in master add')
                    });
                } else {
                    let err = {};
                    err['masterName'] = 'Please enter name.'
                    setError(err);
                }
            } else {
                handleClear();
            }
        });
    }
    const ALLmasterget = () => {
        setLoading(true);
        mastergetall(getall[tab]).then((res) => {
            if (res.data.response.status === 1) {
                let result = [];
                if (tab === 0 || tab === 1 || tab === 2) {
                    result = res.data.response.data.sectorDetails;
                } else if (tab === 3) {
                    result = res.data.response.data.investmentDetails;
                } else if (tab === 4) {
                    result = res.data.response.data.products;
                }
                else if (tab === 5) {
                    result = res.data.response.data.remarks;
                }
                else if (tab === 6) {
                    result = res.data.response.data.source;
                }
                let presult = result.map((S, ind) => {
                    if (tab === 0) {
                        S.name = S.sectorName;
                        S.mainId = S.sectorId;
                    } else if (tab === 1) {
                        S.name = S.fmcgSectorName;
                        S.mainId = S.fmcgSectorId;
                    } else if (tab === 2) {
                        S.name = S.locationName;
                        S.mainId = S.locationId;
                    }
                    else if (tab === 3) {
                        S.name = S.natureInvName;
                        S.mainId = S.natureInvId;
                    }
                    else if (tab === 4) {
                        S.name = S.productsPitchedName;
                        S.mainId = S.productsPitchedId;
                    }
                    else if (tab === 5) {
                        S.name = S.remarksName;
                        S.mainId = S.remarksId;
                    }
                    else if (tab === 6) {
                        S.name = S.sourceName;
                        S.mainId = S.sourceId;
                    }
                    return S;
                })
                setData(presult);
                setLoading(false);
            } else {
                setData([]);
                setLoading(false);
            }
        }).catch((err) => {
            setData([]);
            setLoading(false);

            console.log(err, 'err in all master get')
        })
    }
    const Deletemaster = (details) => {
        let url = "";
        if (tab === 0 || tab === 2) {
            url = `${deletedata[tab]}?SectorId=${details.mainId}`;
        }
        else if (tab === 1) {
            url = `${deletedata[tab]}?FmcgSectorId=${details.mainId}`;
        } else if (tab === 3) {
            url = `${deletedata[tab]}?NatureInvId=${details.mainId}`;
        } else if (tab === 4) {
            url = `${deletedata[tab]}?ProductsPitchedId=${details.mainId}`;
        }
        else if (tab === 5) {
            url = `${deletedata[tab]}?RemarksId=${details.mainId}`;
        }
        else if (tab === 6) {
            url = `${deletedata[tab]}?SourceId=${details.mainId}`;
        }
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to delete?',
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                masterdelete(url).then((res) => {
                    setLoading(false);
                    if (res.data.response.status === 1) {
                        Swal.fire(res.data.response.message, '', 'success').then((result) => {
                            ALLmasterget();
                        });;
                    } else if (res.data.response.status === 3 && res.data.response.message === "An error occurred while updating the entries. See the inner exception for details.") {
                        Swal.fire(`Unable to delete "${details.name}" due to dependency`, '', 'warning').then((result) => {
                            ALLmasterget();
                        });;
                    }
                }).catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
            }
        })
    }
    const handleEdit = (details) => {
        setMasterId(details.mainId);
        setMasterName(details.name);
        setError({});
    }
    const tabchange = (tabind) => {
        setTab(tabind);
        setMasterName("");
        setMasterId(0);
        setError({});
    }
    const handleClear = () => {
        setMasterName("");
        setMasterId(0);
        setError({});
    }
    return (
        <div>
            {loading === true ? <LoadingScreen /> : null}
            <Grid container spacing={0} padding={3} rowSpacing={3} className="">
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" className="sub-heading">
                        Admin Master Management
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} className='center'>
                    <Grid container spacing={3} className='mb-4 container' marginLeft={"auto"}>
                        <Grid item xs={6} md={2}>
                            <Card className={tab === 0 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                                <CardActionArea onClick={() => tabchange(0)}>
                                    <CardContent>
                                        <Typography>Key Industry Sector</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}> <Card className={tab === 1 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                            <CardActionArea onClick={() => tabchange(1)}>
                                <CardContent>
                                    <Typography>Top FMCG Sector</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        </Grid>
                        <Grid item xs={6} md={2}> <Card className={tab === 2 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                            <CardActionArea onClick={() => tabchange(2)}>
                                <CardContent>
                                    <Typography>Top Influencer in Location Sector</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                        <Grid item xs={6} md={2}> <Card className={tab === 3 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                            <CardActionArea onClick={() => tabchange(3)}>
                                <CardContent>
                                    <Typography>Nature of Investment</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                        <Grid item xs={6} md={2}> <Card className={tab === 4 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                            <CardActionArea onClick={() => tabchange(4)}>
                                <CardContent>
                                    <Typography>Product Pitched</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                        <Grid item xs={6} md={2}> <Card className={tab === 5 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                            <CardActionArea onClick={() => tabchange(5)}>
                                <CardContent>
                                    <Typography>Remarks</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                        <Grid item xs={6} md={2}> <Card className={tab === 6 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
                            <CardActionArea onClick={() => tabchange(6)}>
                                <CardContent>
                                    <Typography>Source</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} className='center '>
                    <Grid container spacing={3} width={'70%'} className="container" marginLeft={"auto"}>
                        <Grid item xs={12} md={12} lg={12} className={'center table-bordered '} >
                            <div className=''> <label>Enter
                                {tab === 0 ? " Key industry sector " : tab === 1 ? " FMCG sector " : tab === 2 ? " Influencer in Location sector " : tab === 3 ? " Nature of investment " : tab === 4 ? " Product pitched " : tab === 5 ? " Remark " : tab === 6 ? " Source" : ""}
                                :</label>
                                <input value={masterName} className={"admin_txtfield m-l-10"} onChange={(e) => {
                                    setMasterName(e.target.value);
                                    setError({ ...error, masterName: "" });
                                }} />
                                {error ? (
                                    <div style={{ fontSize: 12, color: 'red' }}>
                                        {error.masterName}
                                    </div>
                                ) : null}
                            </div>
                            <div className='m-l-10'>
                                <Button
                                    className='btn-blue m-l-10'
                                    variant="contained"
                                    onClick={masterId === 0 ? () => Addmaster() : () => Updatemaster()}
                                >
                                    {masterId === 0 ? 'Add' : 'Update'}
                                </Button>
                                <Button
                                    className='btn-blue m-l-10'
                                    variant="contained"
                                    onClick={() => handleClear()}
                                >
                                    Clear
                                </Button>
                            </div>
                        </Grid>
                        {/* table view */}
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid item xs={12} md={12}>
                                <div className="overflow">
                                    <table id="dsr" border={1} className="normaltxt table-responsive">
                                        <thead>
                                            <tr className="thead">
                                                <td width={'10px'}>Sr.No.</td>
                                                <td className="p-r-100">{tab === 0 ? " Key industry sector" : tab === 1 ? " FMCG sector" : tab === 2 ? " Influencer in Location sector" : tab === 3 ? " Nature of investment" : tab === 4 ? " Product pitched" : tab === 5 ? " Remark" : tab === 6 ? "Source" : ""}</td>
                                                <td width={"50px"}> Actions</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map((details, ind) => (
                                                    <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>{details.name}</td>
                                                        <td className="icons-wrap">
                                                            <IconButton
                                                                color="primary"
                                                                title='Edit'
                                                                disabled={tab === 5 ? details.name === "Other Remark" ? true : false : false}
                                                                onClick={() => handleEdit(details)}
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                            <IconButton
                                                                color="error"
                                                                title="Delete"
                                                                disabled={tab === 5 ? details.name === "Other Remark" ? true : false : false}
                                                                onClick={() => Deletemaster(details)}
                                                            >
                                                                <DeleteOutline />
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default AdminMasterManageContent