import React from 'react'
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation'
import SupervisorUserManagementContent from '../sections/SupervisorUserManagement/SupervisorUserManagementContent'

const SupervisorUserManagement = () => {
  return (
    <div>
      <SupervisorTopNavigation />
      <SupervisorUserManagementContent />
    </div>
  )
}

export default SupervisorUserManagement