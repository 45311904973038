
import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material';
import DatePicker from 'react-datepicker';
import { activeinactiveuser, updateuserlist } from '../../Api/AdminApi';
import { getsupervisorwiseuserlist, sixmonthreviewalert, monthreviewalert } from '../../Api/SupervisorAPI';
import LoadingScreen from '../../layouts/LoadingScreen';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import Auth from '../../../modules/Auth';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { exportExcel } from '../../../modules/helpers/exportExcel';
// import axios from 'axios'
const SupervisorUserManagementContent = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [sixmonthalert, setSixmonthalert] = useState([]);
    const [monthAlert, setMonthAlert] = useState([]);

    const supervisorId = Auth.getUserId();
    function handleDownloadExcel() {
        let table1 = data.map((S, ind) => {
            return {
                userName: S.userName,
                userArnCode: S.userArnCode,
                sipTarget: Number(S.sipTarget),
                lumpsumTarget: Number(S.lumpsumTarget),
                nimfSip: Number(S.nimfSip),
                nimfLumpsum: Number(S.nimfLumpsum),
                sipAmount: Number(S.sipTotalAmount),
                lumpsumAmount: Number(S.lumpsumAmount),
                supervisorName: S.supervisorName,
            };
        })
        let columns = [
            { header: 'Agent name', key: 'userName', width: 22 },
            { header: 'ARN Code', key: 'userArnCode', width: 12 },
            { header: 'Monthly SIP Target', key: 'sipTarget', width: 21 },
            { header: 'Monthly Lumpsum Target', key: 'lumpsumTarget', width: 28 },
            { header: 'NIMF SIP Achivement', key: 'nimfSip', width: 25 },
            { header: 'NIMF Lumpsum Achivement', key: 'nimfLumpsum', width: 30 },
            { header: 'SIP Amount', key: 'sipAmount', width: 15 },
            { header: 'Lumpsum Amount', key: 'lumpsumAmount', width: 18 },
            { header: 'SuperVisor name', key: 'supervisorName', width: 30 }]

        exportExcel('Agent List', columns, table1, 'Agent')
    }
    useEffect(() => {
        // SupervisorList();
        GetAllUserList();
    }, [startDate]);
    useEffect(() => {
        GetsixmonthReviewAlert();
        GetmonthReviewAlert();
    }, [])
    const GetAllUserList = async () => {
        setLoading(true);
        let date = moment(startDate).format('yyyy-MM-DD');
        getsupervisorwiseuserlist(date).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                let list = res.data.response.data.objUMList;
                setData(list);
            }
        }).catch((err) => {
            setLoading(false);
            console.log("err in userlist get");
        })
    }
    const GetsixmonthReviewAlert = () => {
        setLoading1(true);
        sixmonthreviewalert(supervisorId).then((res) => {
            setLoading1(false);
            if (res.data.response.status === 1) {
                let result = res.data.response.data;
                setSixmonthalert(result);
            }
        }).catch((err) => {
            setLoading1(false);
            console.log(err);
        })
    }
    const GetmonthReviewAlert = () => {
        setLoading1(true);
        monthreviewalert(supervisorId).then((res) => {
            setLoading1(false);
            if(res.data.response.status === 1) {
                let result = res.data.response.data;
                setMonthAlert(result);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }
    const handleChange = (e, idx) => {
        const { name, value } = e.target;
        let newArr = [...data];
        newArr[idx][name] = value;
        setData(newArr);
    };
    // const handleSectorchange = (e, idx) => {
    //     let find_user = supervisoroption.find((f) => f.supervisorId === Number(e.target.value))
    //     let selected = e.target.value;
    //     let newArr = [...data];
    //     let name = 'supervisorId';
    //     newArr[idx][name] = selected;
    //     newArr[idx]["supervisorName"] = find_user.supervisorName;
    //     newArr[idx]["supervisorMobileNo"] = find_user.supervisorMobileNo;
    //     setData(newArr);

    // };
    const UserActiveDeActive = (userid, act, idx) => {
        setLoading(true);
        let newArr = [...data];
        let userData = {
            userId: userid,
            active: 1 === act ? 0 : 1
        }
        activeinactiveuser(userData).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                newArr[idx]["active"] = 1 === act ? 0 : 1;
                setData(newArr);
                Swal.fire(res.data.response.message, "", "success");
            }
        }).catch((err) => {
            setLoading(false);
            console.log("err active and inactive")
        })
    }

    const UpdateUserDetails = () => {
        setLoading(true);
        let date = moment(startDate).format('yyyy-MM-DD');
        let userlistdata = data.map((S, ind) => {
            return {
                lumpsumTarget: Number(S.lumpsumTarget),
                sipTarget: Number(S.sipTarget),
                nimfLumpsum: Number(S.nimfLumpsum),
                nimfSip: Number(S.nimfSip),
                userId: S.userId,
                Date: date,
                supervisorId: Number(S.supervisorId)
            };
        })
        updateuserlist(userlistdata).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                Swal.fire("User list updated successfully", "", "success")
            }
        }).catch((err) => {
            setLoading(false);
            console.log("err in update userlist")
        })
    }

    // function compareTwoDates(first, second) {
    //     const firstDate = `${moment(first).format("M")}-${moment(first).format("YYYY")}`;
    //     const secondDate = `${moment(second).format("M")}-${moment(second).format("YYYY")}`;
    //     return firstDate !== secondDate
    // }
    // function NIMFcompareTwoDates(first, second) {
    //     const firstDate = `${moment(first).format("M")}-${moment(first).format("YYYY")}`;
    //     const secondDate = `${moment(second).format("M")}-${moment(second).format("YYYY")}`;

    //     return firstDate === secondDate
    // }

    // const SupervisorList = () => {
    //     axios.get(`${BaseUrl.MainGateWayUrl}/Authentication/GetAllSupervisorDetails`).then((res) => {
    //         if (res.data.authenticationResponse.status === 1) {
    //             let result = res.data.authenticationResponse.data.supervisor;
    //             setSupervisoroption(result);
    //         } else {
    //             setSupervisoroption([]);
    //         }
    //     }).catch((err) => {
    //         setSupervisoroption([]);
    //         console.log(err, "err in supervisor get")
    //     })
    // }
    return (
        <div className='p-4'>
            {loading === true || loading1 === true ? <LoadingScreen /> : null}
            <Grid container spacing={0} padding={0} rowSpacing={1.5}>
                <Grid item md={12} xs={12} alignContent="flex-start">
                    <div className='sub-heading'>User Management</div>
                </Grid>

                <Grid item md={6} xs={12} className={'left'}>
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                                console.log(date)
                            }}
                            sx={{ border: '0' }}
                            className='admin__date  '
                            popperPlacement="top-start"
                            dateFormat="MMMM-yyyy"
                            maxDate={new Date()}
                            showYearDropdown
                            showMonthYearPicker
                            showFullMonthYearPicker
                        />
                    </div>   </Grid>
                <Grid item md={6} xs={12} className={'right'}>
                    <div>   <Button onClick={handleDownloadExcel} variant="contained" className=''>download excel</Button></div>


                </Grid>

                <Grid item md={12} xs={12} >
                    <div className='overflow'>
                        <table
                            id="mytable"
                            className="normaltxt table-responsive"
                            style={{ width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th>Agent Name</th>
                                    <th>ARN Code</th>
                                    <th>Monthly SIP Target</th>
                                    <th>Monthly Lumpsum Target</th>
                                    <th>NIMF SIP Achivement</th>
                                    <th>NIMF Lumpsum Achivement</th>
                                    <th>SIP Amount</th>
                                    <th>Lumpsum Amount</th>
                                    <th>Monthly Review</th>
                                    <th>Six Monthly Review</th>
                                    <th>Active/Inactive</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map(
                                    (
                                        {
                                            userId,
                                            userName,
                                            userArnCode,
                                            sipTarget,
                                            lumpsumTarget,
                                            nimfSip,
                                            nimfLumpsum,
                                            supervisorId,
                                            sipTotalAmount,
                                            lumpsumAmount,
                                            active
                                        }, ind) => {
                                        let alertdata = sixmonthalert && sixmonthalert.find((f) => f.userId === userId);
                                        let alertdatam = monthAlert && monthAlert.find((f) => f.userId === userId);
                                        return (

                                            <tr key={ind} >
                                                <td >{userName}</td>
                                                <td>{userArnCode}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        name="sipTarget"
                                                        className="market_txtfield tData"
                                                        value={sipTarget}
                                                        onChange={(e) => handleChange(e, ind)}
                                                    // disabled={compareTwoDates(new Date(), new Date(startDate))}
                                                    /></td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        name="lumpsumTarget"
                                                        className="market_txtfield tData"
                                                        value={lumpsumTarget}
                                                        onChange={(e) => handleChange(e, ind)}
                                                    // disabled={compareTwoDates(new Date(), new Date(startDate))}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        name="nimfSip"
                                                        className="market_txtfield tData"
                                                        value={nimfSip}
                                                        onChange={(e) => handleChange(e, ind)}
                                                    // disabled={NIMFcompareTwoDates(new Date(), new Date(startDate))}
                                                    /></td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        name="nimfLumpsum"
                                                        className="market_txtfield tData"
                                                        value={nimfLumpsum}
                                                        onChange={(e) => handleChange(e, ind)}
                                                    // disabled={NIMFcompareTwoDates(new Date(), new Date(startDate))}
                                                    />
                                                </td>
                                                <td>{sipTotalAmount}</td>
                                                <td>{lumpsumAmount}</td>
                                                <td>
                                                    <ReviewsIcon titleAccess={`${alertdatam === undefined ? '' : alertdatam.message}`} className={alertdatam === undefined ? '' : alertdatam.status === 0 ? 'greenicon' : alertdatam.status === 1 ? 'yellowicon' : alertdatam.status === 2 ? 'redicon' : alertdatam.status === 3 ? '' : ''} />
                                                </td>
                                                
                                                <td>
                                                    <ReviewsIcon titleAccess={`${alertdata === undefined ? '' : alertdata.message}`} className={alertdata === undefined ? '' : alertdata.status === 0 ? 'greenicon' : alertdata.status === 1 ? 'yellowicon' : alertdata.status === 2 ? 'redicon' : alertdata.status === 3 ? '' : ''} />


                                                </td>
                                                <td>
                                                    <Switch checked={1 === active ? true : false} onChange={() => UserActiveDeActive(userId, active, ind)} />
                                                </td>
                                            </tr>

                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center'>
                        <div className='legends mt-4 justify-content-center'>
                            <ul class="legend">
                                <li><span class="superawesome"></span>Review - Pending</li>
                                <li><span class="awesome"></span>Review - Approching</li>
                                <li><span class="kindaawesome"></span> Review - Not Pending</li>
                            </ul>
                        </div>
                    </div>
                    <div className='p-t-5'>
                        <Button variant="contained" className="btn-blue" onClick={() => UpdateUserDetails()}>Save</Button>
                    </div>

                </Grid>

            </Grid>






        </div>
    )
}

export default SupervisorUserManagementContent