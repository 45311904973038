import React, { useEffect, useState } from 'react';
import { getsupervisorwiseuserarn, getmarketscopereportdetails } from '../../Api/SupervisorAPI';
import LoadingScreen from '../../layouts/LoadingScreen';
import { Grid, FormControl, Button, IconButton } from '@mui/material';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Auth from '../../../modules/Auth';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Box } from '@mui/system';
import { Preview } from '@mui/icons-material'
import { getallsector } from '../../Api/AdminApi';
import No_data from '../../../assets/images/no_data1.jpg';
import { exportExcel } from '../../../modules/helpers/exportExcel';
import { exportPDF } from '../../../modules/helpers/exportPdf';
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';
const SupervisorMarketScopeReportContent = () => {
  const [arnf, setArnf] = useState([]);
  const [arnOption, setArnOption] = useState([]);
  const [fromdatef, setFromdatef] = useState(null);
  const [todatef, setTodatef] = useState(null);
  const [categoryf, setCategoryf] = useState([]);
  const [categoryoption, setCategoryoption] = useState([
    { label: "Key Industry", value: 1 },
    { label: "Top Co-operative Banks Present", value: 2 },
    { label: "Top Co-operative Society Present", value: 3 },
    { label: "Top FMCG", value: 4 },
    { label: "Top Influencers in location", value: 5 },
    { label: "Top Industry Associations", value: 6 },
  ])
  const [sectorf, setSectorf] = useState([]);
  const [sectoroption, setSectoroption] = useState([]);
  const [companyname, setCompanyname] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [errors, setErrors] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [count, setCount] = useState(0)
  const supervisorid = Auth.getUserId();
  useEffect(() => {
    GetUserArns();
    GetSector();
  }, [])
  useEffect(() => {
    GetMSDetails();
  }, [pageNo])
  const handleDownloadExcel = async () => {
    setLoading(true);
      let data = {
        userArnCode: arnf.label === undefined ? null : arnf.label,
        market_Scope_ID: categoryf.value === undefined ? 0 : categoryf.value,
        sector_Name: sectorf.label === undefined ? null : sectorf.label,
        companyName: companyname === "" ? null : companyname,
        startDate: fromdatef === null ? null : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
        endDate: todatef === null ? null : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
        supervisorId: supervisorid,
        pageNo:pageNo,
        PageSize:5,
        type: "Download",
      }
      getmarketscopereportdetails(data).then((res) => {
        setLoading(false);
        if (res.data.response.status === 1) {
          let result = res.data.response.data.history;
          let table1 = result.map((S, ind) => {
            S.sr_no = ind + 1;
            S.deleteDate = S.deleteDate === null ? '-' : moment(S.deleteDate).format('DD-MM-yyyy');
            return S;
          })
          let columns = [
            { header: 'Sr. No.', key: 'sr_no', width: 5 },
            { header: 'Agent name', key: 'userName', width: 30 },
            { header: 'ARN code', key: 'userArnCode', width: 12 },
            { header: 'Contact person', key: 'contactPerson', width: 30 },
            { header: 'Contact number', key: 'contactNumber', width: 17 },
            { header: 'Company name', key: 'companyName', width: 30 },
            { header: 'Designation', key: 'designation', width: 15 },
            { header: 'Email', key: 'emailAddress', width: 30 },
            { header: 'Create date', key: 'date', width: 15 },
            { header: 'Address', key: 'address', width: 30 },
            { header: 'District', key: 'district', width: 15 },
            { header: 'Pin code', key: 'pinCode', width: 10 },
            { header: 'Sector name', key: 'sectorName', width: 15 },
            { header: 'Turn over (In lacs)', key: 'turnOver', width: 15 },
            { header: 'Nature of investment', key: 'natureInvName', width: 23 },
            { header: 'Total employees', key: 'totalEmployees', width: 15 },
            { header: 'Supervisor name', key: 'supervisorName', width: 30 },
            { header: 'Deleted', key: 'show', width: 12 },
            { header: 'Delete Date', key: 'deleteDate', width: 15 },
          ]
          exportExcel('Market Scope Report', columns, table1, 'msreport');
        } else {
          Swal.fire("Please try again","Something went wrong","error");
        }
      }).catch((err) => {
        
        console.log(err, "err in getadminmsreportdetails")
      })
   
  }
  const downloadpdf = () => {
    setLoading(true);
      let data = {
        userArnCode: arnf.label === undefined ? null : arnf.label,
        market_Scope_ID: categoryf.value === undefined ? 0 : categoryf.value,
        sector_Name: sectorf.label === undefined ? null : sectorf.label,
        companyName: companyname === "" ? null : companyname,
        startDate: fromdatef === null ? null : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
        endDate: todatef === null ? null : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
        supervisorId: supervisorid,
        pageNo:pageNo,
        PageSize:5,
        type: "Download",
      }
      getmarketscopereportdetails(data).then((res) => {
        setLoading(false);
        if (res.data.response.status === 1) {
          let result = res.data.response.data.history;
          let table1 = result.map((S, ind) => {
            return {
              sr_no: ind + 1,
              userName: S.userName,
              userArnCode: S.userArnCode,
              contactPerson: S.contactPerson,
              contactNumber: S.contactNumber,
              companyName: S.companyName,
              designation: S.designation,
              emailAddress: S.emailAddress,
              date: moment(S.date).format('DD-MM-yyyy'),
              address: S.address,
              district: S.district,
              pinCode: S.pinCode,
              sectorName: S.sectorName,
              turnOver: `${new Intl.NumberFormat('en-IN', {
                minimumFractionDigits: 2
              }).format(Number(S.turnOver))}`,
              natureInvName: S.natureInvName,
              totalEmployees: S.totalEmployees,
              supervisorName: S.supervisorName,
              show: S.show === 1 ? 'false' : 'true',
              deleteDate: S.delete_Date === null ? '-' : moment(S.delete_Date).format('DD-MM-yyyy')
            }
          });
          let head = [
            {
              sr_no: 'Sr. No.',
              userName: 'Agent name',
              userArnCode: 'ARN code',
              contactPerson: 'Contact person',
              contactNumber: 'Contact number',
              companyName: 'Company name',
              designation: 'Designation',
              emailAddress: 'Email',
              date: 'Create date',
              address: 'Address',
              district: 'District',
              pinCode: 'Pin code',
              sectorName: 'Sector name',
              turnOver: 'Turn over (In lacs)',
              natureInvName: 'Nature of investment',
              totalEmployees: 'Total employees',
              supervisorName: 'Supervisor name',
              show: 'Deleted',
              deleteDate: 'Delete Date'
            }
          ]
      
          exportPDF(head, table1, 'market scope report', 'Market Scope Report');
        } else {
          Swal.fire("Please try again","Something went wrong","error");
        }
      }).catch((err) => {
        Swal.fire("Please try again","Something went wrong","error");
        setLoading(false);
        console.log(err, "err in getadminmsreportdetails")
      })
    

  }
  const GetSector = () => {
    getallsector().then((res) => {
      if (res.data.response.status === 1) {
        let result = res.data.response.data.sectorDetails;
        let sectorlist = [];
        let uniquesectorlist = [];
        result && result.map((x) => {
          sectorlist.push(
            x.sectorName
          )
        })
        var unique = [...new Set(sectorlist)];

        unique.map((x) => {
          uniquesectorlist.push({
            label: x,
            value: x
          })
        })

        setSectoroption(uniquesectorlist);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const ClearFilter = () => {
    setArnf([]);
    setFromdatef(null);
    setTodatef(null);
    setCategoryf([]);
    setSectorf([]);
    setCompanyname("");
    setErrors({});
    setPageNo(1);
    setCount(0);
    setLoading(true)
    let data = {
      userArnCode: null,
      market_Scope_ID: 0,
      sector_Name: null,
      companyName: null,
      startDate: null,
      endDate: null,
      supervisorId: supervisorid,
      pageNo:1,
      PageSize:5,
      type: "Pagination",
    }
    getmarketscopereportdetails(data).then((res) => {
      if (res.data.response.status === 1) {
        let result = res.data.response.data;
        setCount(result.count);
        setData(result.history);
      } else {
        setData([]);
        setCount(0);
      }
      setLoading(false);
    }).catch((err) => {
      setData([]);
      setCount(0);
      setLoading(false);
      console.log(err, "err in getadminmsreportdetails")
    })
  }
  const GetMSDetails = (search) => {
    let valid = validate();
    if (valid) {
      if(search !== undefined){
        setPageNo(search)
    }
      setLoading(true);
      let data = {
        userArnCode: arnf.label === undefined ? null : arnf.label,
        market_Scope_ID: categoryf.value === undefined ? 0 : categoryf.value,
        sector_Name: sectorf.label === undefined ? null : sectorf.label,
        companyName: companyname === "" ? null : companyname,
        startDate: fromdatef === null ? null : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
        endDate: todatef === null ? null : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
        supervisorId: supervisorid,
        pageNo:search  === undefined ? pageNo  : search,
        PageSize:5,
        type: "Pagination",
      }
      getmarketscopereportdetails(data).then((res) => {
        if (res.data.response.status === 1) {
          let result = res.data.response.data;
          setCount(result.count);
          setData(result.history);
        } else {
          setCount(0);
          setData([]);
        }
        setLoading(false);
      }).catch((err) => {
        setCount(0);
        setData([]);
        setLoading(false);
        console.log(err, "err in getadminmsreportdetails")
      })
    }
  }
  const GetUserArns = () => {
    getsupervisorwiseuserarn(supervisorid).then((res) => {
      if (res.data.response.status === 1) {
        let arnlist = res.data.response.data.arnCodeList
        setArnOption(arnlist)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handlePreview = (data) => {
    setPreviewData(data);
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
    setPreviewData({});
  }
  const validate = () => {
    let valid = true;
    let error = {};
    if (fromdatef == null && todatef != null) {
      error['fromdatef'] = 'Please select from date';
      valid = false;
    }
    if (todatef == null && fromdatef != null) {
      error['todatef'] = 'Please select to date';
      valid = false;
    }
    setErrors(error);
    return valid;

  }
  const handlePageChange = (pageNumber) =>  {
    // console.log(`active page is ${pageNumber}`);
    setPageNo(pageNumber);
  }
  return (
    <div className='p-4'>
      {loading === true ? <LoadingScreen /> : null}

      <Grid container spacing={0} padding={0} rowSpacing={2}>
        <Grid item md={6} xs={6} alignContent="flex-start">
          <div className='sub-heading'>Market Scope Reports of all users</div>
        </Grid>
        <Grid item md={6} xs={6} className={'right'}>
          <div>
            <Button onClick={handleDownloadExcel} variant="contained" className='p-2' disabled={data.length === 0}>download excel</Button>

            <Button
              className='p-2 m-2'
              variant="contained"
              onClick={() => downloadpdf()}
              disabled={data.length === 0}
            >
              Download PDF
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={12} className={'p-4'}>
          <Grid container spacing={2} padding={0} rowSpacing={1.5} className={'admin__search-bar'}>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth className="textfield">
                <label>ARN Code</label>
                <Select
                  value={arnf}
                  options={arnOption}
                  onChange={(selected) => {
                    setArnf(selected);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth className="textfield">
                <label>Category</label>
                <Select
                  value={categoryf}
                  options={categoryoption}
                  onChange={(selected) => {
                    setCategoryf(selected);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth className="textfield">
                <label>Sector</label>
                <Select
                  value={sectorf}
                  options={sectoroption}
                  onChange={(selected) => {
                    setSectorf(selected);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth className="textfield">
                <label>Company Name</label>
                <input
                  type="text"
                  className=""
                  placeholder='Enter company name'
                  value={companyname}
                  onChange={(e) => {
                    setCompanyname(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth className="textfield">
                <label>From Date</label>
                <DatePicker
                  className="textfield"
                  placeholderText="dd-mm-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  maxDate={new Date()}
                  // minDate={new Date(startDate)}
                  onChange={(date) => {
                    setFromdatef(date);
                    setTodatef(new Date());
                    setErrors({ ...errors, fromdatef: '' });
                  }}
                  dateFormat="dd-MM-yyyy"
                  name="startdate"
                  autoComplete="off"
                  selected={fromdatef}
                  withPortal
                />
              </FormControl>
              {errors ? (
                <div style={{ fontSize: 12, color: 'red' }}>
                  {errors.fromdatef}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth className="textfield">
                <label>To Date</label>
                <DatePicker
                  popperPlacement='right-top'
                  className="form-control"
                  placeholderText="dd-mm-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  minDate={new Date(fromdatef)}
                  maxDate={new Date()}
                  onChange={(date) => {
                    setTodatef(date);
                    setErrors({ ...errors, todatef: '' });
                  }}
                  withPortal
                  dateFormat="dd-MM-yyyy"
                  name="startdate"
                  autoComplete="off"
                  selected={todatef}
                />
              </FormControl>
              {errors ? (
                <div style={{ fontSize: 12, color: 'red' }}>
                  {errors.todatef}
                </div>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12} className={'center p-t-5'}>
              <Button variant="contained" className='btn-grad' onClick={() => GetMSDetails(1)} >Search</Button>
              <Button variant="contained" className='btn-grad' onClick={() => ClearFilter()} >Clear</Button>
            </Grid>
          </Grid>
        </Grid>
        {data.length !== 0 ?
        <Grid item xs={12} md={12}>
          <div className="overflow-admin">
            <table id="dsr" border={1} >
              <thead>
                <tr className="thead" >
                  <td rowSpan={2}>Sr. No.</td>
                  <td rowSpan={2} className="p-r-100">Agent Name</td>
                  <td rowSpan={2} className="p-r-100">ARN Code</td>
                  <td rowSpan={2} className="p-r-100">Date</td>
                  <td rowSpan={2} className="p-r-100">Sector</td>
                  <td rowSpan={2}>Company Name</td>
                  {/* <td rowSpan={2}>Email</td> */}
                  {/* <td rowSpan={2} className="p-r-100">Designation</td> */}
                  <td rowSpan={2}>Contact Person</td>
                  <td rowSpan={2}>Contact no.</td>
                  {/* <td rowSpan={2}>District</td> */}
                  {/* <td rowSpan={2}>Nature Of Inv.</td> */}
                  <td rowSpan={2}>Action</td>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((msdetails, ind) => (
                    <tr key={ind} className={msdetails.show === 0 ? "showRed" : null} >
                      <td>{(ind + 1) +( (pageNo * 5) - (5) )}</td>
                      <td>{msdetails.userName}</td>
                      <td>{msdetails.userArnCode}</td>
                      <td>{moment(msdetails.date).format('DD-MM-yyyy')}</td>
                      <td>{msdetails.sectorName}</td>
                      <td>{msdetails.companyName}</td>
                      {/* <td>{msdetails.emailAddress}</td> */}
                      {/* <td>{msdetails.designation}</td> */}
                      <td>{msdetails.contactPerson}</td>
                      <td>{msdetails.contactNumber}</td>
                      {/* <td>{msdetails.district}</td> */}
                      {/* <td>{msdetails.natureInvName}</td> */}
                      <td className="icons-wrap">
                        <IconButton
                          color="primary"
                          title='Preview'
                          onClick={() => handlePreview(msdetails)}
                        >
                          <Preview />
                        </IconButton>
                      </td>


                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Grid item md={12} xs={12} className={'center p-t-5'}>
            <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={pageNo}
                itemsCountPerPage={5}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                prevPageText="Previous"
                nextPageText="Next"
                onChange={handlePageChange}
            />
           </Grid>
        </Grid>:<img src={No_data} className="no_data" alt="no data"/>}

      </Grid>

      {show === true ?
        <div>
          <Modal size='lg' isOpen={show} toggle={handleClose}>
            <ModalHeader toggle={handleClose} className="modal-hd">
              Market Scope Preview
            </ModalHeader>
            <ModalBody>
              <Box>
                <form>
                  <Grid container padding={2} spacing={0} rowSpacing={1.5} columnSpacing={0} className="table-bordered" marginTop={2}>
                    <Grid item xs={12} md={6} className={'divider'}>
                      {' '}
                      <b>Sector : </b><br />
                      {previewData.sectorName}

                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      {' '}
                      <b>Company Name :</b>
                      <br /> {previewData.companyName}
                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Contact Person : </b><br />
                      {previewData.contactPerson}

                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Contact Number :</b><br />
                      {previewData.contactNumber}
                    </Grid>

                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Designation : </b><br />
                      {previewData.designation}
                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      {' '}
                      <b>District : </b><br />
                      {/* {moment(previewData.nextMeetingDate).format('DD/MM/yyyy')} */}
                      {previewData.district}

                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Email :</b> <br />
                      {previewData.emailAddress}
                    </Grid>

                    <Grid item xs={12} md={6} className={'divider'}>
                      {' '}
                      <b> Nature of Investment :</b> <br />
                      {previewData.natureInvName}
                    </Grid>

                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Pincode :</b> <br />{' '}
                      {previewData.pinCode}

                      {/* {`₹${new Intl.NumberFormat('en-IN', {
                        minimumFractionDigits: 2
                      }).format(Number(previewData.amount))}`} */}
                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Total Employees : </b><br />
                      {previewData.totalEmployees}
                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      <b> Turn over (in crores) :</b><br />
                      {`₹${new Intl.NumberFormat('en-IN', {
                        minimumFractionDigits: 2
                      }).format(Number(previewData.turnOver))}`}
                    </Grid>
                    <Grid item xs={12} md={6} className={'divider'}>
                      <div> <b>  Address :</b> <br />
                        {previewData.address}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button variant="contained" className="btn-grad" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div> : null}


    </div>
  )
}

export default SupervisorMarketScopeReportContent