import React, { useState, useEffect } from 'react';
import {  Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler, BarElement } from 'chart.js';
import { getdashboradgraphmonthdetailsbyuserid } from '../../Api/DashboardApi';
import Auth from '../../../modules/Auth';
import moment from 'moment/moment';
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  Filler
)

const LineCharComponent = () => {
    const UserId = Auth.getUserId();
    const [days, setDays] = useState([]);
    const [dachieveLumpsum, setDAchieveLumpsum] = useState([]);
    const [dtargetLumpsum, setDTargetLumpsum] = useState([]);
    const [dachieveSIP, setDAchieveSIP] = useState([]);
    const [dtargetSIP, setDTargetSIP] = useState([]);
  
    useEffect(() => {
      GetdashboardGraphMonthdetails();
    }, [])
  
    const GetdashboardGraphMonthdetails = () => {
      getdashboradgraphmonthdetailsbyuserid(UserId).then((res) => {
  
        if (res.data.response.status === 1) {
          let result = res.data.response.data
          setDays(result.months);
          setDAchieveLumpsum(result.achieveLumpsum);
          setDTargetLumpsum(result.lumpsumTarget);
          setDAchieveSIP(result.achieveSIP);
          setDTargetSIP(result.sipTarget);
        } else {
          setDays([]);
          setDAchieveLumpsum([]);
          setDTargetLumpsum([]);
          setDAchieveSIP([]);
          setDTargetSIP([]);
        }
      }).catch((err) => {
        setDays([]);
          setDAchieveLumpsum([]);
          setDTargetLumpsum([]);
          setDAchieveSIP([]);
          setDTargetSIP([]);
        console.log(err, "err in fetching graph details")
      })
    }
   

    
      const sipDataM = {
      datasets: [
        {
          label: "Target",
          data: dtargetSIP,
          backgroundColor: '#F05454',
          borderColor: '#F05454',
          tension: 0.4,
          pointBackgroundColor: '#fff',
        },
        {
          label: "Achieved Target",
          data: dachieveSIP,
          backgroundColor: '#344CB7',
          borderColor: '#344CB7',
          tension: 0.4,
          pointBackgroundColor: '#fff',
        }
      ]
    }
     const lumpsumDataM = {
     
      datasets: [
        {
          label: "Target",
          data: dtargetLumpsum,
          backgroundColor: '#F05454',
          borderColor: '#F05454',
          tension: 0.4,
          pointBackgroundColor: '#fff',
          // showLine:true
        },
        {
          label: "Achieved Target",
          data: dachieveLumpsum,
          backgroundColor: '#344CB7',
          borderColor: '#344CB7',
          tension: 0.4,
          pointBackgroundColor: '#fff',
          // showLine:true
        }
      ]
    }
      const optionsM = {
      scales: {
        x: {
          title: {
            color: 'black',
            display: true,
            text: 'Days'
          },
          type: 'category',
          labels:  days,
        },
        y: {
          title: {
            color: 'black',
            display: true,
            text: 'Rs. in Lakh'
          },
        }
      }
    }
    return (
      <div className="App" >
  
        <div className='container-fluid' >
          
          <div className='row p-3'>
          <div className='col chart__center'>
            <div className='chart__heading '>{`${moment(new Date()).format("MMMM-yyyy")} (SIP)`}</div>
            <div className='chart__wd-ht'> <Line data={sipDataM} options={optionsM} /></div>
          </div>
          <div className='col chart__center' >
          <div className='chart__heading'>{`${moment(new Date()).format("MMMM-yyyy")} (Lumpsum)`}</div>
         <div className='chart__wd-ht'> <Line data={lumpsumDataM} options={optionsM} /></div>
          </div>
          </div>
          
        </div>
      </div >
    )
}

export default LineCharComponent