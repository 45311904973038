import React, { useState, useEffect } from 'react';
import TopNavigation from '../../layouts/TopNavigation';
import SideNavigation from '../../layouts/SideNavigation';
import { IconButton, Typography, Grid, Button } from '@mui/material';
import './dailysalesreport.css';
import { Edit } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import AddIcon from '@mui/icons-material/Add';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import CalendarIcon from '@mui/icons-material/CalendarMonthTwoTone';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import AddUpdateDailySR from './AddUpdateDailySR';
import Auth from '../../../modules/Auth/index';
import {
	getdailysalesreportbyuseridanddate,
	getdailysalesreportheaderdetails,
	DeleteDailySalesReport
} from '../../Api/DailySalesReportApi';
import moment from 'moment';
import Swal from 'sweetalert2';
import LoadingScreen from '../../layouts/LoadingScreen';
import { Link } from 'react-router-dom';
// import zIndex from '@mui/material/styles/zIndex';
export const DailySalesReportContent = () => {
	const UserId = Auth.getUserId();
	const [modal, setmodal] = useState(false);
	const [data, setData] = useState([]);
	const [date, setDate] = useState(new Date());
	const [headerDetails, setHeaderDetails] = useState([]);
	const [dsrId, setDsrId] = useState(0);
	const [dailySalesRData, setDailySalesRData] = useState({});
	const [loading, setLoading] = useState(false);
	const handleshowmodal = () => {
		setmodal(!modal);
		setDsrId(0);
		setDailySalesRData({});
	};

	useEffect(() => {
		GetDailySalesReportDetails();
		GetDailySalesReportHeader();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);
	// useEffect(() => {
	// 	GetDailySalesReportHeader();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	const GetDailySalesReportHeader = () => {
		let selectedDate = moment(date).format('yyyy-MM-DD');
		getdailysalesreportheaderdetails(UserId, selectedDate)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.detailsByUserID[0];
					setHeaderDetails(result);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const GetDailySalesReportDetails = () => {
		setLoading(true);
		let selectedDate = moment(date).format('yyyy-MM-DD');
		getdailysalesreportbyuseridanddate(UserId, selectedDate)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.detailByUserID;
					setData(result);
				} else {
					setData([]);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err, 'err in get daily sales report');
			});
	};
	const handleEditPopup = (editData) => {
		setDsrId(editData.dailyReportId);
		setDailySalesRData(editData);
		setmodal(true);
	};
	const DeleteDailyReportById = (dailyreportid) => {
		Swal.fire({
			icon: 'warning',
			title: 'Are you sure you want to delete this record?',
			confirmButtonText: 'Yes',
			showCancelButton: true
		}).then((result) => {
			if (result.isConfirmed) {
				DeleteDailySalesReport(dailyreportid)
					.then((res) => {
						if (res.data.response.status === 1) {
							Swal.fire('Daily Sales Report Record Delete Successfully', '', 'success');
							GetDailySalesReportDetails();
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		});
	};
	return (
		<div>
			<TopNavigation />
			<SideNavigation />
			{loading === true ? <LoadingScreen /> : null}
			<Grid container spacing={2} padding={3} rowSpacing={3} paddingLeft={'80px'}>
				<Grid item xs={12} md={12} alignContent="flex-start">
					<Typography variant="h4" className="sub-heading">
						Daily Sales Report
					</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					<div className="dsr__container-user-blue">
						<div className="dsr__container-user-details">
							<p>ARN Code</p>
							<h4>{headerDetails.userArnCode}</h4>
						</div>

						<div className="dsr__container-user-details">
							<p>Name</p>
							<h4>{headerDetails.userName}</h4>
						</div>

						<div className="dsr__container-user-details">
							<p>Location</p>
							<h4>_</h4>
						</div>

						<div className="dsr__container-user-details">
							<p>Region</p>
							<h4>{headerDetails.region}</h4>
						</div>

						<div className="dsr__container-user-details">
							<p>Lumpsum target<br /> ({moment(date).format('MMMM YYYY')})</p>
							<h4>&#x20B9;{' '} {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
								headerDetails.lumpsumTarget === undefined ? 0 : headerDetails.lumpsumTarget
							)}</h4>
						</div>

						<div className="dsr__container-user-details">
							<p>SIP target<br />({moment(date).format('MMMM YYYY')})</p>
							<h4>&#x20B9;{' '} {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
								headerDetails.sipTarget === undefined ? 0 : headerDetails.sipTarget
							)}</h4>
						</div>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={2} padding={2} rowSpacing={2} paddingLeft={'80px'}>
				<Grid item xs={12} md={6} textAlign="left" sx={{ zIndex: 100 }}>
					<div className="dsr__date d-flex">

						Select Date :{' '}<div>
							<DatePicker
								maxDate={new Date()}
								selected={date}
								minDate={new Date(headerDetails.dateOfJoining)}
								onChange={(date) => setDate(date)}
								// startIcon={<CalendarIcon />}
								sx={{ border: '0', outerWidth: 'fit-content' }}
								popperPlacement="top-start"
								dateFormat="dd/MM/yyyy"
								showMonthDropdown
								showYearDropdown
								scrollableYearDropdown
							/></div>
					</div>
				</Grid>


				<Grid item xs={12} md={6} textAlign="right">
					<div><Link to='/dailyreportfilter'>
						<Button startIcon={<DateRangeIcon />} color="warning" >
							Filters - Date Range
						</Button>
					</Link>
						<Button startIcon={<AddIcon />} onClick={handleshowmodal}>
							Add New
						</Button>

						<AddUpdateDailySR
							show={modal}
							handleshowhide={handleshowmodal}
							recalldailysalesreport={GetDailySalesReportDetails}
							DailySalesReportData={dailySalesRData}
							dailysalesreportId={dsrId}
							selectdate={date}
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={12}>
					<div className="overflow">
						<table id="dsr" border={1}>
							<thead>
								<tr className="thead">
									<td rowSpan={2}>Sr.No.</td>
									<td rowSpan={2} className="p-r-100">Time</td>
									<td rowSpan={2}>Investor Type</td>
									<td rowSpan={2} className="p-r-100">Client Name</td>
									<td rowSpan={2}>Call Type</td>
									<td rowSpan={2}>Contact no.</td>
									<td rowSpan={2}>
										Products <br />Pitched
									</td>
									<td rowSpan={2}>Source</td>
									<td rowSpan={2}>Next Meeting</td>
									<td rowSpan={2}>
										Busi. Sourced<br />/Expected
									</td>
									<td colSpan={5} style={{ textAlign: 'center' }}>
										Details of Business
									</td>
									<td rowSpan={2}>Status</td>
									<td rowSpan={2}>Remarks</td>
									<td rowSpan={2}>Action</td>
								</tr>
								<tr className="thead2">
									<td>Amount</td>
									<td>Total SIP Amt</td>
									<td>No. of SIP</td>
									<td className="p-r-100">Scheme</td>
									<td>Lumpsum/SIP</td>
								</tr>
							</thead>
							<tbody>
								{data &&
									data.map((dailysreport, ind) => (
										<tr key={ind}>
											<td>{ind + 1}</td>
											<td>{dailysreport.time}</td>
											<td>{dailysreport.investorType}</td>
											<td>{dailysreport.clientName}</td>
											<td>{dailysreport.callType}</td>
											<td>{dailysreport.contactNumber}</td>
											<td>{dailysreport.productsPitched.label}</td>
											<td>{dailysreport.source.label}</td>
											<td>{moment(dailysreport.nextMeetingDate).format('DD/MM/yyyy')}</td>
											<td>{dailysreport.businessSourcedOrExpected}</td>
											<td>{`₹${new Intl.NumberFormat('en-IN', {
												minimumFractionDigits: 2
											}).format(Number(dailysreport.amount))}`}</td>
											<td>
												{dailysreport.investmentType === 'SIP' ? (
													`₹${new Intl.NumberFormat('en-IN', {
														minimumFractionDigits: 2
													}).format(Number(dailysreport.amount * dailysreport.numberOfSip))}`
												) : (
													'-'
												)}
											</td>
											<td>
												{dailysreport.investmentType === 'SIP' ? dailysreport.numberOfSip : '-'}
											</td>
											<td >{dailysreport.scheme.label}</td>
											<td>{dailysreport.investmentType}</td>
											<td>
												<p className={dailysreport.status === 'Open' ? 'open' : 'closed'}>
													{dailysreport.status}
												</p>
											</td>
											<td>
												{dailysreport.remarks.value === 3 ? (
													dailysreport.otherRemark
												) : (
													dailysreport.remarks.label
												)}
											</td>
											<td className="icons-wrap">
												<IconButton
													color="primary"
													title='Edit'
													disabled={new Date(dailysreport.submitDate) < new Date()}
													onClick={() => handleEditPopup(dailysreport)}

												>
													<Edit />
												</IconButton>
												<IconButton
													color="error"
													title="Delete"
													disabled={new Date(dailysreport.submitDate) < new Date()}
													onClick={() => DeleteDailyReportById(dailysreport.dailyReportId)}
												>
													<DeleteOutlineIcon />
												</IconButton>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
export default DailySalesReportContent;
