import React from 'react'
import DSRStatusContent from '../sections/DSRStatus/DSRStatusContent'
import TopNavigation from '../layouts/TopNavigation';
import SideNavigation from '../layouts/SideNavigation';


const DSRStatus = () => {
    return (
        <div>
            <TopNavigation />
            <SideNavigation />
            <DSRStatusContent />
        </div>
    )
}

export default DSRStatus