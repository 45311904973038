import React from 'react'
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation'
import MonthlyReviewContent from '../sections/MonthlyReview/MonthlyReviewContent'

const MonthlyReview = () => {
    return (
        <div>
            <SupervisorTopNavigation />
            <MonthlyReviewContent />
        </div>
    )
}

export default MonthlyReview