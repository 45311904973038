import BaseUrl from '../../modules/config/BaseUrl';
import Auth from '../../modules/Auth';
import axios from '../../modules/helpers/axiosInstance';
const Id = Auth.getUserId();
const getsupervisorwiseuserlist = (date) => {
    return axios({
        method: "GET",
        url: `${BaseUrl.MainGateWayUrl}/Admin/GetUserSupervisorDetails?Date=${date}&SupervisorId=${Id}`,
    })
}
const getsupervisorwisedsrdetails = (data) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterDSRSupervisorDetails`,
        data: data
    })
}
const getsupervisorwiseuserarn = (supervisorId) => {
    return axios({
        method: "GET",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetSupervisorUserArnCode?SupervisorId=${supervisorId}`,
    })
}
const getmarketscopereportdetails = (data) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterMSSupervisorDetails`,
        data: data
    })
}
const getsupervisormonthlyreview = (data) => {
    return axios({
        method: 'POST',
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/GetSupervisorMonthlyReview`,
        data: data
    })
}

const savemonthlyreview = (data) => {
    return axios({
        method: 'POST',
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/SaveSupervisorMonthlyReview`,
        data: data
    })
}
const submitmonthlyreview = (data) => {
    return axios({
        method: 'POST',
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/SubmitSupervisorMonthlyReview`,
        data: data
    })
}

const reviewOTP = (supervisorId, userId) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/MonthlyReviewOtp?SupervisorId=${supervisorId}&UserId=${userId}`,
    })
}
const getmonthlysixmonthlyreviewreport = (data) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterMonthlySupervisorDetails`,
        data: data
    })
}
const getarndsrstatusreport = (userid) => {
    return axios({
        method: "GET",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/DSRCalendarDate?UserId=${userid}`
    })
}
const sixmonthreviewalert = (supervisorId) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/SupervisorApproachingTypeAlert?SupervisorId=${supervisorId}`
    })
}

const monthreviewalert = (supervisorId) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/SupervisorMonthlyApproachingTypeAlert?SupervisorId=${supervisorId}`
    })
}
const getsummeryforday = (data) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardDSRDetailsByAdminSupervisor`,
        data: data
    })
}
const getsummeryforyear = (data) => {
    return axios({
        method: "POST",
        url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardDSRDetailsYearlyByAdminSupervisor`,
        data: data
    })
}
const getsixmonthreviewlatestdate = (data) => {
    return axios({
        method: 'POST',
        url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/SixMonthlyDateSupervisorDetails`,
        data: data
    })
}
export {
    getsupervisorwiseuserlist,
    getsupervisorwisedsrdetails,
    getsupervisorwiseuserarn,
    getmarketscopereportdetails,
    getsupervisormonthlyreview,
    savemonthlyreview,
    reviewOTP,
    submitmonthlyreview,
    getmonthlysixmonthlyreviewreport,
    getarndsrstatusreport,
    sixmonthreviewalert,
    getsummeryforday,
    getsummeryforyear,
    getsixmonthreviewlatestdate,
    monthreviewalert
}