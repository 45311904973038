import './App.css';
import './Calendar.css';
import { BrowserRouter } from 'react-router-dom';
import Content from './component/content/Content';
function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Content />
			</div>
		</BrowserRouter>
	);
}

export default App;
