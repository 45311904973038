import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import loginreducer from './loginReducer';
import keyindustryreducer from './keyIndustryReducer';
import topcooperbanksreducer from './topcooperbanksReducer';
import topcoopersocietyreducer from './topcoopersocietyReducer';
import topfmcgreducer from './topFMCGReducer';
import topindassoreducer from './topindustryasso';
import topinfluencersreducer from './topinfuinlocation';
import staticmarketscopedataReducer from './staticmarketscopedataReducer';
// const persistConfig = {
// 	key: 'logindetails',
// 	storage
// };

const reducers = combineReducers({
	// login: loginreducer,
	keyinddata: keyindustryreducer,
	cobanksdata: topcooperbanksreducer,
	cosocietydata: topcoopersocietyreducer,
	fmcgdata: topfmcgreducer,
	influencersdata: topinfluencersreducer,
	indassodata: topindassoreducer,
	staticmarketdata: staticmarketscopedataReducer
});
// const persistedReducer = persistReducer(persistConfig, reducers);
export default reducers;
