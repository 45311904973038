import React from 'react';
import RegisterContent from '../sections/Register/RegisterContent';

const Registration = () => {
	return (
		<div  style={{height:'100%'}}>
			<RegisterContent />
		</div>
	);
};

export default Registration;
