import React, { useState, useEffect } from 'react';
import TopNavigation from '../../layouts/TopNavigation';
import SideNavigation from '../../layouts/SideNavigation';
import KeyIndustry from './KeyIndustry';
import TCoOpBanksPre from './TCoOpBanksPre';
import TCoOpSocietyPre from './TCoOpSocietyPre';
import TopFMCG from './TopFMCG';
import TInfuInLocation from './TInfuInLocation';
import TIndAssociations from './TIndAssociations';
import { Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Auth from '../../../modules/Auth/index';
import { getallmarketscopedetails, AddUpdateMarketScopeDetails } from '../../Api/MarketScopeApi';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../../redux';
import { bindActionCreators } from 'redux';
import { store } from '../../../redux/store';
import LoadingScreen from '../../layouts/LoadingScreen';
import Swal from 'sweetalert2';

const MarketScopeContent = () => {
	const childFunc = React.useRef(null);
	const UserId = Auth.getUserId();
	/* redux store*/
	const dispatch = useDispatch();
	const {
		clearkeyinddetails,
		cleartopercobanks,
		cleartopercosociety,
		cleartopfmcg,
		cleartopinfluencers,
		clearindassociations,
		setstaticmarketscope,
		clearstaticmarketscope
	} = bindActionCreators(actionCreators, dispatch);
	/* end redux store */

	const [show, setShow] = useState(1);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		GetAlltabsDetailsDetails();
		return () => {
			ValidateData();
		};
		// eslint-disable-next-line
	}, []);

	const handleNavigation = (number) => {
		setShow(number);
	};
	const GetAlltabsDetailsDetails = () => {
		setLoading(true);
		getallmarketscopedetails(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data;
					setstaticmarketscope(result);
				} else {
					let data = {
						coOperativeBankDetails: [],
						coOperativeSocietyDetails: [],
						fmcgDetails: [],
						industryAssociationDetails: [],
						influencerDetails: [],
						keyIndustryDetails: []
					}
					setstaticmarketscope(data);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log('err in get market scope details');
			});
	};

	const ValidateData = async () => {
		let keyindChange = false;
		let CobanksChange = false;
		let CosocietyChange = false;
		let fmcgChange = false;
		let influlocationChange = false;
		let indassociationChange = false;
		let state = store.getState();
		let RStaticMarketScope = state.staticmarketdata.marketscopedata;
		let keydetails = RStaticMarketScope.keyIndustryDetails;
		let cobanks = RStaticMarketScope.coOperativeBankDetails;
		let cosociety = RStaticMarketScope.coOperativeSocietyDetails;
		let fmcg = RStaticMarketScope.fmcgDetails;
		let influLocation = RStaticMarketScope.influencerDetails;
		let indAssociation = RStaticMarketScope.industryAssociationDetails;
		let Rkeydetails = state.keyinddata.keyindDetails;
		let Rcobankdetails = state.cobanksdata.cobanksDetails;
		let Rcosocietydetails = state.cosocietydata.cosocietyDetails;
		let Rfmcgdetails = state.fmcgdata.fmcgDetails;
		let Rinflulocation = state.influencersdata.influencersDetails;
		let Rindassociation = state.indassodata.indassociationsDetails;
		if (keydetails) {
			if (Rkeydetails.length - 1 === keydetails.length) {
				keydetails.forEach((element, ind) => {
					let details = Rkeydetails.find((f) => f.keyIndustryId === element.keyIndustryId);
					if (
						Number(details.sector) === element.sector &&
						details.company_Name === element.company_Name &&
						details.contact_Person === element.contact_Person &&
						details.designation === element.designation &&
						details.address === element.address &&
						details.district === element.district &&
						Number(details.pinCode) === element.pinCode &&
						details.contact_No === element.contact_No &&
						details.email === element.email &&
						Number(details.turn_Over) === element.turn_Over &&
						Number(details.nature_of_Investment) === element.nature_of_Investment &&
						Number(details.total_Employees) === element.total_Employees &&
						details.userId === element.userId &&
						details.marketScopeId === element.marketScopeId
					) {
						console.log(ind, 'not change');
					} else {
						keyindChange = true;
					}
				});
			} else if (keydetails.length < Rkeydetails.length - 1) {
				keyindChange = true;
			}
		}
		if (cobanks) {
			if (Rcobankdetails.length - 1 === cobanks.length) {
				cobanks.forEach((element, ind) => {
					let details = Rcobankdetails.find((f) => f.bankId === element.bankId);
					if (
						details.bank_Name === element.bank_Name &&
						details.contact_Person === element.contact_Person &&
						details.designation === element.designation &&
						details.address === element.address &&
						details.district === element.district &&
						Number(details.pinCode) === element.pinCode &&
						details.contact_No === element.contact_No &&
						details.email === element.email &&
						Number(details.turn_Over) === element.turn_Over &&
						Number(details.nature_of_Investment) === element.nature_of_Investment &&
						Number(details.total_Employees) === element.total_Employees &&
						details.userId === element.userId &&
						details.marketScopeId === element.marketScopeId
					) {
						console.log(ind, 'not change');
					} else {
						CobanksChange = true;
					}
				});
			} else if (cobanks.length < Rcobankdetails.length - 1) {
				CobanksChange = true;
			}
		}
		if (cosociety) {
			if (Rcosocietydetails.length - 1 === cosociety.length) {
				cosociety.forEach((element, ind) => {
					let details = Rcosocietydetails.find((f) => f.societyId === element.societyId);
					if (
						details.society_Name === element.society_Name &&
						details.contact_Person === element.contact_Person &&
						details.designation === element.designation &&
						details.address === element.address &&
						details.district === element.district &&
						Number(details.pinCode) === element.pinCode &&
						details.contact_No === element.contact_No &&
						details.email === element.email &&
						Number(details.turn_Over) === element.turn_Over &&
						Number(details.nature_of_Investment) === element.nature_of_Investment &&
						Number(details.total_Employees) === element.total_Employees &&
						details.userId === element.userId &&
						details.marketScopeId === element.marketScopeId
					) {
						console.log(ind, 'not change');
					} else {
						CosocietyChange = true;
					}
				});
			} else if (cosociety.length < Rcosocietydetails.length - 1) {
				CosocietyChange = true;
			}
		}
		if (fmcg) {
			if (Rfmcgdetails.length - 1 === fmcg.length) {
				fmcg.forEach((element, ind) => {
					let details = Rfmcgdetails.find((f) => f.fmcgId === element.fmcgId);
					if (
						Number(details.fmcgSectorId) === element.fmcgSectorId &&
						details.fmcgName === element.fmcgName &&
						details.contactPerson === element.contactPerson &&
						details.designation === element.designation &&
						details.address === element.address &&
						details.district === element.district &&
						Number(details.pinCode) === element.pinCode &&
						details.contactNumber === element.contactNumber &&
						details.emailAddress === element.emailAddress &&
						Number(details.turnOver) === element.turnOver &&
						Number(details.natureInvId) === element.natureInvId &&
						Number(details.totalEmployees) === element.totalEmployees &&
						details.userId === element.userId &&
						details.marketScopeId === element.marketScopeId
					) {
						console.log(ind, 'not change');
					} else {
						fmcgChange = true;
					}
				});
			} else if (fmcg.length < Rfmcgdetails.length - 1) {
				fmcgChange = true;
			}
		}
		if (influLocation) {
			if (Rinflulocation.length - 1 === influLocation.length) {
				influLocation.forEach((element, ind) => {
					let details = Rinflulocation.find((f) => f.influencersId === element.influencersId);
					if (
						Number(details.locationId) === element.locationId &&
						details.influencers_Name === element.influencers_Name &&
						details.contact_Person === element.contact_Person &&
						details.designation === element.designation &&
						details.address === element.address &&
						details.district === element.district &&
						Number(details.pinCode) === element.pinCode &&
						details.contact_No === element.contact_No &&
						details.email === element.email &&
						Number(details.turn_Over) === element.turn_Over &&
						Number(details.nature_of_Investment) === element.nature_of_Investment &&
						Number(details.total_Employees) === element.total_Employees &&
						details.userId === element.userId &&
						details.marketScopeId === element.marketScopeId
					) {
						console.log(ind, 'not change');
					} else {
						influlocationChange = true;
					}
				});
			} else if (influLocation.length < Rinflulocation.length - 1) {
				influlocationChange = true;
			}
		}
		if (indAssociation) {
			if (Rindassociation.length - 1 === indAssociation.length) {
				indAssociation.forEach((element, ind) => {
					let details = Rindassociation.find(
						(f) => f.industryAssociationId === element.industryAssociationId
					);
					if (
						details.industryAssociation_Name === element.industryAssociation_Name &&
						details.contact_Person === element.contact_Person &&
						details.designation === element.designation &&
						details.address === element.address &&
						details.district === element.district &&
						Number(details.pinCode) === element.pinCode &&
						details.contact_No === element.contact_No &&
						details.email === element.email &&
						Number(details.turn_Over) === element.turn_Over &&
						Number(details.nature_of_Investment) === element.nature_of_Investment &&
						Number(details.total_Employees) === element.total_Employees &&
						details.userId === element.userId &&
						details.marketScopeId === element.marketScopeId
					) {
						console.log(ind, 'not change');
					} else {
						indassociationChange = true;
					}
				});
			} else if (indAssociation.length < Rindassociation.length - 1) {
				indassociationChange = true;
			}
		}
		if (
			keyindChange ||
			CobanksChange ||
			CosocietyChange ||
			fmcgChange ||
			influlocationChange ||
			indassociationChange
		) {
			if (window.confirm(`Your unsaved data will be clear`)) {
				console.log('go to the dashboard');
				clearkeyinddetails();
				cleartopercobanks();
				cleartopercosociety();
				cleartopfmcg();
				cleartopinfluencers();
				clearindassociations();
				clearstaticmarketscope();
			} else {
				navigate('/marketscope', { replace: true });
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}
		} else {
			clearkeyinddetails();
			cleartopercobanks();
			cleartopercosociety();
			cleartopfmcg();
			cleartopinfluencers();
			clearindassociations();
		}
	};
	const MarketScopeSubmit = (call) => {
		setLoading(true);
		const state = store.getState();
		let editopen = true;
		let Skeydetails = state.keyinddata.keyindDetails;
		let Scobankdetails = state.cobanksdata.cobanksDetails;
		let Scosocietydetails = state.cosocietydata.cosocietyDetails;
		let Sfmcgdetails = state.fmcgdata.fmcgDetails;
		let Sinflulocation = state.influencersdata.influencersDetails;
		let Sindassociation = state.indassodata.indassociationsDetails;

		let keydetailsdata = Skeydetails.map((S, ind) => {
			return {
				sectorId: Number(S.sector),
				keyIndustryName: S.company_Name,
				contactPerson: S.contact_Person,
				designation: S.designation,
				address: S.address,
				district: S.district,
				pinCode: Number(S.pinCode),
				contactNumber: S.contact_No,
				emailAddress: S.email,
				turnOver: Number(S.turn_Over),
				natureInvId: Number(S.nature_of_Investment),
				totalEmployees: Number(S.total_Employees),
				userId: S.userId,
				keyIndustryId: S.keyIndustryId,
				marketScopeId: S.marketScopeId,
				edit: S.edit
			};
		});
		let bankdetailsdata = Scobankdetails.map((S, ind) => {
			return {
				BankName: S.bank_Name,
				contactPerson: S.contact_Person,
				designation: S.designation,
				address: S.address,
				district: S.district,
				pinCode: Number(S.pinCode),
				contactNumber: S.contact_No,
				emailAddress: S.email,
				turnOver: Number(S.turn_Over),
				natureInvId: Number(S.nature_of_Investment),
				totalEmployees: Number(S.total_Employees),
				userId: S.userId,
				bankId: S.bankId,
				marketScopeId: S.marketScopeId,
				edit: S.edit
			};
		});
		let societydetailsdata = Scosocietydetails.map((S, ind) => {
			return {
				SocietyName: S.society_Name,
				contactPerson: S.contact_Person,
				designation: S.designation,
				address: S.address,
				district: S.district,
				pinCode: Number(S.pinCode),
				contactNumber: S.contact_No,
				emailAddress: S.email,
				turnOver: Number(S.turn_Over),
				natureInvId: Number(S.nature_of_Investment),
				totalEmployees: Number(S.total_Employees),
				userId: S.userId,
				societyId: S.societyId,
				marketScopeId: S.marketScopeId,
				edit: S.edit
			};
		});
		let fmcgdetailsdata = Sfmcgdetails.map((S, ind) => {
			return {
				fmcgSectorId: Number(S.fmcgSectorId),
				fmcgName: S.fmcgName,
				contactPerson: S.contactPerson,
				designation: S.designation,
				address: S.address,
				district: S.district,
				pinCode: Number(S.pinCode),
				contactNumber: S.contactNumber,
				emailAddress: S.emailAddress,
				turnOver: Number(S.turnOver),
				natureInvId: Number(S.natureInvId),
				totalEmployees: Number(S.totalEmployees),
				userId: S.userId,
				fmcgId: S.fmcgId,
				marketScopeId: S.marketScopeId,
				edit: S.edit
			};
		});
		let influencersdetailsdata = Sinflulocation.map((S, ind) => {
			return {
				locationId: Number(S.locationId),
				influencersName: S.influencers_Name,
				contactPerson: S.contact_Person,
				designation: S.designation,
				address: S.address,
				district: S.district,
				pinCode: Number(S.pinCode),
				contactNumber: S.contact_No,
				emailAddress: S.email,
				turnOver: Number(S.turn_Over),
				natureInvId: Number(S.nature_of_Investment),
				totalEmployees: Number(S.total_Employees),
				userId: S.userId,
				influencersId: S.influencersId,
				marketScopeId: S.marketScopeId,
				edit: S.edit
			};
		});
		let indassodetailsdata = Sindassociation.map((S, ind) => {
			return {
				IndustryAssociationName: S.industryAssociation_Name,
				contactPerson: S.contact_Person,
				designation: S.designation,
				address: S.address,
				district: S.district,
				pinCode: Number(S.pinCode),
				contactNumber: S.contact_No,
				emailAddress: S.email,
				turnOver: Number(S.turn_Over),
				natureInvId: Number(S.nature_of_Investment),
				totalEmployees: Number(S.total_Employees),
				userId: S.userId,
				industryAssociationId: S.industryAssociationId,
				marketScopeId: S.marketScopeId,
				edit: S.edit
			};
		});
		keydetailsdata.pop();
		bankdetailsdata.pop();
		societydetailsdata.pop();
		fmcgdetailsdata.pop();
		influencersdetailsdata.pop();
		indassodetailsdata.pop();

		// let array = keydetailsdata
		// 	.concat(bankdetailsdata)
		// 	.concat(societydetailsdata)
		// 	.concat(fmcgdetailsdata)
		// 	.concat(influencersdetailsdata)
		// 	.concat(indassodetailsdata);
		let array = indassodetailsdata
			.concat(influencersdetailsdata)
			.concat(fmcgdetailsdata)
			.concat(societydetailsdata)
			.concat(bankdetailsdata)
			.concat(keydetailsdata);
		// console.log(array, 'all array');
		let tabid = 0
		array.map((f) => {
			if (f.edit === true) {
				editopen = false;
				tabid = f.marketScopeId
			}
			return null;
		});
		if (editopen) {
			AddUpdateMarketScopeDetails(array)
				.then((res) => {
					if (res.data.response.status === 1) {
						clearkeyinddetails();
						cleartopercobanks();
						cleartopercosociety();
						cleartopfmcg();
						cleartopinfluencers();
						clearindassociations();
						setLoading(false);
						Swal.fire({
							icon: 'success',
							title: 'Details submitted successfully',
							confirmButtonText: 'Ok'
						}).then((result) => {
							GetAlltabsDetailsDetails();
							if (call === 1) {
								childFunc.keyind();
							} else if (call === 2) {
								childFunc.cobank();
							} else if (call === 3) {
								childFunc.cosociety();
							} else if (call === 4) {
								childFunc.topfmcg();
							} else if (call === 5) {
								childFunc.indassociation();
							} else if (call === 6) {
								childFunc.inflocation();
							}
						});
					}
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		} else {
			setLoading(false);
			Swal.fire({
				// icon: 'warning',
				title: 'Record is in edit mode',
				confirmButtonText: 'Ok'
			}).then((result) => {
				if (show !== tabid) {
					setShow(tabid);
				}
			});
		}
	};
	return (
		<div>
			{loading === true ? <LoadingScreen /> : null}
			<TopNavigation />
			<SideNavigation />
			<Grid container spacing={2} padding={5} rowSpacing={3} paddingLeft={'80px'}>
				<Grid item xs={12} md={12}>
					<Typography variant="h4" className="sub-heading">
						Market Scope
					</Typography>
					{/*<Breadcrumbs aria-label="breadcrumb" className="bread">
						<Link underline="hover" color="inherit" to="/dashboard" className="bread">
							Dashboard
						</Link>

						<Typography color="text.primary">Market Scope</Typography>
					</Breadcrumbs>*/}
				</Grid>
				<Grid item xs={6} md={2}>
					<Card className={show === 1 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
						<CardActionArea onClick={() => setShow(1)}>
							<CardContent>
								<Typography>Key Industry</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={6} md={2}>
					<Card className={show === 2 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
						<CardActionArea onClick={() => setShow(2)}>
							<CardContent>
								<Typography>Top Bank / Co-operative Bank Present</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={6} md={2}>
					<Card className={show === 3 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
						<CardActionArea onClick={() => setShow(3)}>
							<CardContent>
								<Typography>Top Co-operative Society Present</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={6} md={2}>
					<Card className={show === 4 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
						<CardActionArea onClick={() => setShow(4)}>
							<CardContent>
								<Typography>Top FMCG</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={6} md={2}>
					<Card className={show === 5 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
						<CardActionArea onClick={() => setShow(5)}>
							<CardContent>
								<Typography>Top Influencers in location</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={6} md={2}>
					<Card className={show === 6 ? 'marketscope-tabs-active' : 'marketscope-tabs-inactive '}>
						<CardActionArea onClick={() => setShow(6)}>
							<CardContent>
								<Typography>Top Industry Associations</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
			<div>
				{show === 1 ? (
					<KeyIndustry
						ChangeTabK={handleNavigation}
						RecallFunctionK={GetAlltabsDetailsDetails}
						childFuncK={childFunc}
						SubmitK={MarketScopeSubmit}
					/>
				) : show === 2 ? (
					<TCoOpBanksPre
						ChangeTabB={handleNavigation}
						RecallFunctionB={GetAlltabsDetailsDetails}
						childFuncB={childFunc}
						SubmitB={MarketScopeSubmit}
					/>
				) : show === 3 ? (
					<TCoOpSocietyPre
						RecallFunction={() => GetAlltabsDetailsDetails()}
						ChangeTabS={handleNavigation}
						childFuncS={childFunc}
						SubmitS={MarketScopeSubmit}
					/>
				) : show === 4 ? (
					<TopFMCG
						ChangeTabF={handleNavigation}
						RecallFunctionF={GetAlltabsDetailsDetails}
						childFuncF={childFunc}
						SubmitF={MarketScopeSubmit}
					/>
				) : show === 5 ? (
					<TInfuInLocation
						ChangeTabI={handleNavigation}
						RecallFunctionI={GetAlltabsDetailsDetails}
						childFuncI={childFunc}
						SubmitI={MarketScopeSubmit}
					/>
				) : (
					<TIndAssociations
						ChangeTabA={handleNavigation}
						RecallFunctionA={GetAlltabsDetailsDetails}
						childFuncA={childFunc}
						SubmitA={MarketScopeSubmit}
					/>
				)}
			</div>
		</div>
	);
};

export default MarketScopeContent;
