import React from 'react'
import AdminTopNavigation from '../layouts/AdminTopNavigation';
import AdminArnDSRStatusReportcontent from '../sections/AdminArnDSRStatusReport/AdminArnDSRStatusReportContent';

const AdminArnDSRStatusReport = () => {
    return (
        <div>
            <AdminTopNavigation />
            <AdminArnDSRStatusReportcontent />
        </div>
    )
}

export default AdminArnDSRStatusReport