import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler, BarElement } from 'chart.js';
import { getdashboradgraphdetailsbyuserid } from '../../Api/DashboardApi';
import Auth from '../../../modules/Auth';
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  Filler
)

const ChartComponent = () => {
  const UserId = Auth.getUserId();
  
  const [footer, setFooter] = useState([]);
  const [months, setMonths] = useState([]);
  const [achieveLumpsum, setAchieveLumpsum] = useState([]);
  const [targetLumpsum, setTargetLumpsum] = useState([]);
  const [achieveSIP, setAchieveSIP] = useState([]);
  const [targetSIP, setTargetSIP] = useState([]);
 
  

  useEffect(() => {
    GetdashboardGraphdetails();
  }, [])
  const GetdashboardGraphdetails = () => {
    getdashboradgraphdetailsbyuserid(UserId).then((res) => {

      if (res.data.response.status === 1) {
        let result = res.data.response.data
        setMonths(result.months);
        setAchieveLumpsum(result.achieveLumpsum);
        setTargetLumpsum(result.lumpsumTarget);
        setAchieveSIP(result.achieveSIP);
        setTargetSIP(result.sipTarget);
        setFooter(result.footer);
      } else {
        setMonths([]);
        setAchieveLumpsum([]);
        setTargetLumpsum([]);
        setAchieveSIP([]);
        setTargetSIP([]);
      }
    }).catch((err) => {
      setMonths([]);
        setAchieveLumpsum([]);
        setTargetLumpsum([]);
        setAchieveSIP([]);
        setTargetSIP([]);
      console.log(err, "err in fetching graph details")
    })
  }
 
 
  const sipDataYear = {
    datasets: [
      {
        label: "Target",
        data: targetSIP,
        backgroundColor: '#2ccfe2',
        borderColor: '#2ccfe2',   
        // tension: 0.4,
        pointBackgroundColor: '#fff',
        barPercentage:1,
        // catogoryPercentage:0.1,
        barThickness: 9,
      },
      {
        label: "Achieved Target",
        data: achieveSIP,
        backgroundColor: '#2480ab',
        borderColor: '#2480ab',
        // tension: 0.4,
        pointBackgroundColor: '#fff',
        barPercentage:1,
        // catogoryPercentage:0.1,
         barThickness: 9,
      }
    ]
  }
 
  const lumpsumDataYear = {
    
    datasets: [
      {
        label: "Target",
        data: targetLumpsum,
        backgroundColor: '#2ccfe2',
        borderColor: '#2ccfe2',
        // tension: 0.4,
        pointBackgroundColor: '#fff',
        // innerWidth:60,
        barPercentage:1,
        barThickness: 9,
        // showLine:true
      },
      {
        label: "Achieved Target",
        data: achieveLumpsum,
        backgroundColor: '#2480ab',
        borderColor: '#2480ab',
        // tension: 0.4,
        pointBackgroundColor: '#fff',
        // showLine:true
        barPercentage:1,
        barThickness: 9,
      },

    ]
    
  }
  // });

  const options = {
    scales: {
      x: {
        title: {
          color: 'black',
          display: true,
          text: footer
        },
        type: 'category',
        labels:  months,
        
      },
      y: {
        title: {
          color: 'black',
          display: true,
          text: 'Rs. in Lakh'
        },
      },
      
      
    }
  }
  
  

   
  
  return (
    <div className="App" >

      <div className='container-fluid' >
     
     
        <div className='row p-3'>
         <div className='col  chart__center' >
         <div className='chart__heading '>YTD (SIP)</div>
         <div className='chart__wd-ht'>   <Bar data={sipDataYear} options={options} /></div>
        </div>
        <div className='col  chart__center'>
        <div className='chart__heading '>YTD (Lumpsum)</div>
        <div className='chart__wd-ht'> <Bar  data={lumpsumDataYear} options={options} /></div>
        </div> 
        </div>
        
    
      </div>
    </div >
  )
}

export default ChartComponent
