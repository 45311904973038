import React from 'react';
import ComingsoonContent from '../sections/ComingSoon/ComingsoonContent.js';
const Comingsoon = () => {
	return (
		<div>
			<ComingsoonContent />
		</div>
	);
};

export default Comingsoon;
