import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
const LoadingScreen = () => {
	return (
		<div>
			{' '}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={true}
				// onClick={handleClose}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
};

export default LoadingScreen;
