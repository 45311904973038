import React from 'react'
import AdminMasterManageContent from '../sections/AdminMasterManage/AdminMasterManageContent'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
const AdminMasterManage = () => {
    return (
        <div>
            <AdminTopNavigation />
            <AdminMasterManageContent />
        </div>
    )
}

export default AdminMasterManage