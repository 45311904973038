import React, { useState, useEffect } from 'react'
import {
	Button, Grid, FormControl
} from '@mui/material';
import Select from 'react-select';
import { Box } from '@mui/system';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import LoadingScreen from '../../layouts/LoadingScreen';
import { State, City } from 'country-state-city';
import { addsupervisor, updatesupervisor } from '../../Api/AdminApi';
import Swal from 'sweetalert2';
const AddUpdateSupervisor = (props) => {
	const { show, handleshowhide, supervisorid, supervisorData, getsupervisordetails } = props;
	const [Error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const [supervisorName, setSupervisorName] = useState("");
	const [mobileno, setMobileno] = useState("");
	const [emailid, setEmailid] = useState("");
	const [stateSelected, setStateSelected] = useState([]);
	const [citiesSelected, setCitiesSelected] = useState([]);
	const [statelist, setStatelist] = useState([]);
	const [citieslist, setCitieslist] = useState([]);
	const handleClose = () => {
		handleshowhide();
		handleClear();
	};
	useEffect(
		() => {
			if (supervisorid !== 0 && Object.keys(supervisorData).length !== 0) {
				let States = State.getStatesOfCountry('IN');
				let findstate = States.find((f) => f.name === supervisorData.supervisorState);
				setStateSelected({
					label: supervisorData.supervisorState,
					value: supervisorData.supervisorState,
					isoCode: findstate !== undefined ? findstate.isoCode : "",
					countryCode: "IN"
				})
				if (findstate !== undefined) {
					getCities(findstate.countryCode, findstate.isoCode);
				}
				setCitiesSelected({
					label: supervisorData.supervisorCity,
					value: supervisorData.supervisorCity,
					stateCode: findstate !== undefined ? findstate.isoCode : "",
					countryCode: 'IN'
				});
				setSupervisorName(supervisorData.supervisorName);
				setMobileno(supervisorData.supervisorMobileNo);
				setEmailid(supervisorData.supervisorEmailId)
			}
		},
		[supervisorData, supervisorid]
	);
	const handleClear = () => {
		setSupervisorName("");
		setMobileno("");
		setEmailid("");
		setStateSelected({});
		setCitiesSelected({});
		setCitieslist([]);
		setError({});
	}
	useEffect(() => {
		const getStates = () => {
			let States = State.getStatesOfCountry('IN');
			let result = States.map((f) => {
				return { label: f.name, value: f.name, isoCode: f.isoCode, countryCode: f.countryCode };
			});
			setStatelist(result);
		};
		getStates();
	}, []);

	const getCities = (countryCode, stateCode) => {
		let Cities = City.getCitiesOfState(countryCode, stateCode);
		let result = Cities.map((f) => {
			return { label: f.name, value: f.name, stateCode: f.stateCode, countryCode: f.countryCode };
		});
		setCitieslist(result);
	};
	const handlechangeStates = (selected) => {
		setStateSelected(selected);
		getCities(selected.countryCode, selected.isoCode);
		setCitiesSelected([]);
		setError({ ...Error, stateSelected: '', citiesSelected: '' });
	};
	const handlechangeCities = (selected) => {
		setCitiesSelected(selected);
		setError({ ...Error, citiesSelected: '' });
	};
	const validate = () => {
		const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const phoneregex = /^[6-9]\d{9}$/gi;
		const letters = /^[aA-zZ,.'"\s]+$/;
		let isvalid = true
		let error = {};
		if (supervisorName.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "" || supervisorName === null) {
			isvalid = false;
			error['supervisorName'] = 'Please enter full name';
		} else if (!letters.test(supervisorName)) {
			isvalid = false
			error['supervisorName'] = 'Only alphabets are allowed for contact person field';
		}
		if (emailid.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "" || emailid === null) {
			isvalid = false;
			error['emailid'] = 'Please enter email id';
		} else if (emailregex.test(emailid) === false) {
			isvalid = false;
			error['emailid'] = 'Please enter valid email id';
		}
		if (mobileno === "" || mobileno === null) {
			isvalid = false;
			error['mobileno'] = 'Please enter mobile number';
		} else if (phoneregex.test(Number(mobileno)) === false) {
			error['mobileno'] = 'Please enter 10 digit valid mobile number';
			isvalid = false;
		}
		if (Object.entries(stateSelected).length < 1) {
			error['stateSelected'] = 'Please select state';
			isvalid = false;
		}
		if (Object.entries(citiesSelected).length < 1 && citieslist.length !== 0) {
			error['citiesSelected'] = 'Please select city';
			isvalid = false;
		}
		setError(error);
		return isvalid
	}
	const AddSupervisor = () => {
		const valid = validate();
		if (valid) {
			let supervisorData = {
				supervisorName: supervisorName,
				supervisorCity: citiesSelected.length === 0 ? "" : citiesSelected.value,
				supervisorState: stateSelected.value,
				supervisorEmailId: emailid,
				supervisorMobileNo: mobileno
			}
			setLoading(true);
			addsupervisor(supervisorData).then((res) => {
				setLoading(false);
				if (res.data.response.status === 1) {
					Swal.fire("Supervisor add successfully", "", "success");
					getsupervisordetails();
					handleClose();
				} else {
					Swal.fire("Email or mobile number already exist.")
				}
			}).catch((err) => {
				setLoading(false);
				console.log(err, "err in add supervisor");
			})
		}
	}
	const UpdateSupervisor = () => {
		const valid = validate();
		if (valid) {
			let supervisorData = {
				supervisorId: supervisorid,
				supervisorName: supervisorName,
				supervisorCity: citiesSelected.length === 0 ? "" : citiesSelected.value,
				supervisorState: stateSelected.value,
				supervisorEmailId: emailid,
				supervisorMobileNo: mobileno
			}
			setLoading(true);
			updatesupervisor(supervisorData).then((res) => {
				setLoading(false);
				if (res.data.response.status === 1) {
					Swal.fire("Supervisor update successfully", "", "success");
					getsupervisordetails();
					handleClose();
				} else {
					Swal.fire(`${res.data.response.message}`, "", "warning")
				}
			}).catch((err) => {
				setLoading(false);
				console.log(err, "err in add supervisor");
			})
		}
	}
	return (
		<div>
			{loading === true ? <LoadingScreen /> : null}
			<Modal size='lg' isOpen={show} toggle={handleClose}>
				<ModalHeader toggle={handleClose} className="modal-hd">
					{supervisorid === 0 ? 'Add New Supervisor' : 'Update Supervisor'}
				</ModalHeader>
				<ModalBody>
					<Box>
						<form>
							<Grid container padding={2} spacing={1} rowSpacing={1.5} columnSpacing={2} className="border-grid">
								<Grid item xs={12} md={12}>
									Full Name<span className="text-danger">*</span><br />
									<input
										className="txtfield"
										value={supervisorName}
										onChange={(e) => {
											setSupervisorName(e.target.value);
											setError({ ...Error, supervisorName: '' });
										}}
									/>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.supervisorName}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Mobile no.<span className="text-danger">*</span><br />
									<input
										type="number"
										className="txtfield"
										value={mobileno}
										onChange={(e) => {
											setMobileno(e.target.value);
											setError({ ...Error, mobileno: '' });
										}}
									/>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.mobileno}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6} >
									Email id<span className="text-danger">*</span><br />
									<input
										className="txtfield"
										value={emailid}
										onChange={(e) => {
											setEmailid(e.target.value);
											setError({ ...Error, emailid: '' });
										}}
									/>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.emailid}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6} >
									<FormControl fullWidth>
										<label id="sel-country">Select State<span className="text-danger">*</span></label>
										<Select options={statelist} value={stateSelected} onChange={handlechangeStates} />
									</FormControl>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.stateSelected}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth>
										<label id="sel-country">Select City<span className="text-danger">*</span></label>
										<Select options={citieslist} value={citiesSelected} onChange={handlechangeCities} isDisabled={citieslist.length === 0} />
										{Error ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{Error.citiesSelected}
											</div>
										) : null}
									</FormControl>
								</Grid>
							</Grid>
						</form>
					</Box>
				</ModalBody>
				<ModalFooter>
					<div>
						{supervisorid === 0 ? (
							<Button className="btn-grad" variant="contained"
								onClick={() => AddSupervisor()}
							>
								Save
							</Button>
						) : (
							<Button className="btn-grad" variant="contained"
								onClick={() => UpdateSupervisor()}
							>
								Update
							</Button>
						)}{' '}
					</div>
					<div>
						<Button variant="contained" className="btn-grad" onClick={handleClose}>
							Cancel
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default AddUpdateSupervisor