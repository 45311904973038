import React from 'react';
import UserProfileContent from '../sections/UserProfile/UserProfileContent';
import ChangePassword from '../sections/UserProfile/ChangePassword';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TopNavigation from '../layouts/TopNavigation';
import SideNavigation from '../layouts/SideNavigation';
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tab-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tab-${index}`
	};
}
const UserProfile = () => {
	const [ value, setValue ] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<div>
			<TopNavigation />
			<SideNavigation />
			<Box sx={{  }} paddingLeft={'80px'} paddingTop={4}>
				<Tabs					
					variant="scrollable"
					centered={true}
					value={value}
					
					onChange={handleChange}
					aria-label="Vertical tabs example"
					sx={{ borderRight: 1, borderColor: 'divider', textAlign:'right' }}
				>
					<Tab label="My Profile" {...a11yProps(0)} />
					<Tab label="Change Password" {...a11yProps(1)} />
				</Tabs>
				<TabPanel value={value} index={0} >
					<UserProfileContent />
				</TabPanel>
				<TabPanel value={value} index={1} >
					<ChangePassword />
				</TabPanel>
			</Box>
		</div>
	);
};

export default UserProfile;
