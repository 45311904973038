import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import {
	Investor_Type,
	Call_Type,
	Business_Sourced_Expected,
	Lumpsum_Sip,
	Status,
	Time_slot,
	Type_Of_Fund
} from '../../../modules/helpers/constant';
import DatePicker from 'react-datepicker';
import {
	getProductpitchedDetails,
	getSchemeDetails,
	getRemarkDetails,
	getSourceDetails,
	AddDailySalesReport,
	UpdateDailySalesReport
} from '../../Api/DailySalesReportApi';
import Auth from '../../../modules/Auth';
import Swal from 'sweetalert2';
import Cleave from 'cleave.js/react';
import LoadingScreen from '../../layouts/LoadingScreen';

// import moment from 'moment';
const AddUpdateDailySR = (props) => {
	const {
		show,
		handleshowhide,
		recalldailysalesreport,
		DailySalesReportData,
		dailysalesreportId,
		selectdate
	} = props;
	const UserId = Auth.getUserId();
	const [time, setTime] = useState('');
	const [investorType, setInvestorType] = useState('');
	const [clientName, setClientName] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [callType, setCallType] = useState('');
	const [nextmeetingDate, setNextmeetingDate] = useState(new Date());
	const [productpitched, setProductpitched] = useState('');
	const [source, setSource] = useState('');
	const [businessS_E, setBusinessS_E] = useState('');
	const [amount, setAmount] = useState('');
	const [typeofund, setTypeofund] = useState('');
	const [scheme, setScheme] = useState('');
	const [lumpsumsip, setLumpsumsip] = useState('');
	const [noofsip, setNoofsip] = useState('');
	const [status, setStatus] = useState('');
	const [remark, setRemark] = useState('');
	const [otherremark, setOtherremark] = useState('');
	const [productpitchedlist, setProductpitchedlist] = useState([]);
	const [schemelist, setSchemelist] = useState([]);
	const [remarklist, setRemarklist] = useState([]);
	const [sourcelist, setSourcelist] = useState([]);
	const [submitDate] = useState(new Date());
	const [Error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const [staticdata, setStaticdata] = useState({
		amount: '',
		typeofund: '',
		scheme: '',
		lumpsumsip: '',
		noofsip: '',
		status: ''
	})
	useEffect(() => {
		GetProductPitchedDetails();
		GetRemarkDetails();
		GetSourceDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (typeofund !== '') {
			GetSchemeDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeofund]);
	useEffect(
		() => {
			if (dailysalesreportId !== 0 && Object.keys(DailySalesReportData).length !== 0) {
				setTime(DailySalesReportData.time);
				setInvestorType(DailySalesReportData.investorType);
				setClientName(DailySalesReportData.clientName);
				setContactNumber(DailySalesReportData.contactNumber);
				setCallType(DailySalesReportData.callType);
				setNextmeetingDate(new Date(DailySalesReportData.nextMeetingDate));
				setProductpitched(DailySalesReportData.productsPitched.value);
				setSource(DailySalesReportData.source.value);
				setBusinessS_E(DailySalesReportData.businessSourcedOrExpected);
				setAmount(DailySalesReportData.amount);
				setTypeofund(DailySalesReportData.typesOfFunds);
				setScheme(DailySalesReportData.scheme.value);
				setLumpsumsip(DailySalesReportData.investmentType);
				setStatus(DailySalesReportData.status);
				setRemark(DailySalesReportData.remarks.value);
				setOtherremark(DailySalesReportData.remarks.value === 3 ? DailySalesReportData.otherRemark : '');
				setNoofsip(DailySalesReportData.numberOfSip);
				setStaticdata({
					amount: DailySalesReportData.amount,
					typeofund: DailySalesReportData.typesOfFunds,
					scheme: DailySalesReportData.scheme.value,
					lumpsumsip: DailySalesReportData.investmentType,
					noofsip: DailySalesReportData.numberOfSip,
					status: DailySalesReportData.status
				})
			}
		},
		[DailySalesReportData, dailysalesreportId]
	);
	const GetProductPitchedDetails = () => {
		getProductpitchedDetails()
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.products;
					let ProductpitchedDetails = [];
					result &&
						result.map((f) =>
							ProductpitchedDetails.push({
								label: f.productsPitchedName,
								value: f.productsPitchedId
							})
						);
					setProductpitchedlist(ProductpitchedDetails);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const GetSchemeDetails = () => {
		setLoading(true);
		getSchemeDetails(typeofund)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.schemePlanMaster;
					let SchemeDetails = [];
					result &&
						result.map((f) =>
							SchemeDetails.push({
								label: f.schDesc,
								value: f.schDesc
							})
						);
					setSchemelist(SchemeDetails);
				} else {
					setSchemelist([]);
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setSchemelist([]);
			});
	};
	const GetSourceDetails = () => {
		getSourceDetails()
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.source;
					let SourceDetails = [];
					result &&
						result.map((f) =>
							SourceDetails.push({
								label: f.sourceName,
								value: f.sourceId
							})
						);
					setSourcelist(SourceDetails);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const GetRemarkDetails = () => {
		getRemarkDetails()
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.remarks;
					let RemarkDetails = [];
					result &&
						result.map((f) =>
							RemarkDetails.push({
								label: f.remarksName,
								value: f.remarksId
							})
						);
					setRemarklist(RemarkDetails);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleClose = () => {
		handleshowhide();
		handleClear();
	};
	const validate = () => {
		const phoneregex = /^[6-9]\d{9}$/gi;
		let valid = true;
		let formError = {};
		if (time === '') {
			valid = false;
			formError['time'] = 'Please select time slot.';
		}
		if (investorType === '') {
			valid = false;
			formError['investorType'] = 'Please select investor.';
		}
		if (clientName === '') {
			valid = false;
			formError['clientName'] = 'Please enter client name.';
		}
		if (contactNumber === '') {
			valid = false;
			formError['contactNumber'] = 'Please enter contact number.';
		} else if (phoneregex.test(contactNumber) === false) {
			valid = false;
			formError['contactNumber'] = 'Please enter valid mobile number.';
		}
		if (callType === '') {
			valid = false;
			formError['callType'] = 'Please select call type.';
		}
		if (productpitched === '') {
			valid = false;
			formError['productpitched'] = 'Please select product pitched.';
		}
		if (source === '') {
			valid = false;
			formError['source'] = 'Please select source.';
		}
		if (businessS_E === '') {
			valid = false;
			formError['businessS_E'] = 'Please select business sourced/expected.';
		}
		if ((amount === '' || Number(amount) <= 0) && businessS_E !== "No") {
			valid = false;
			formError['amount'] = 'Please enter amount.';
		}
		if (scheme === '' && businessS_E !== "No") {
			valid = false;
			formError['scheme'] = 'Please select scheme.';
		}
		if (typeofund === '' && businessS_E !== "No") {
			valid = false;
			formError['typeofund'] = 'Please select type of fund.';
		}
		if (lumpsumsip === '' && businessS_E !== "No") {
			valid = false;
			formError['lumpsumsip'] = 'Please select Lumpsum/SIP.';
		}
		if ((lumpsumsip === 'SIP') && (noofsip === '' || Number(noofsip) <= 0)) {
			valid = false;
			formError['noofsip'] = 'Please enter number of SIP.';
		}
		if (status === '') {
			valid = false;
			formError['status'] = 'Please select status';
		}
		if (remark === '') {
			valid = false;
			formError['remark'] = 'Please select remark';
		}
		if (remark === '3' && otherremark === '') {
			valid = false;
			formError['remark'] = 'Please enter other remark';
		}
		setError(formError);
		return valid;
	};
	const fixTimezoneOffset = (date) => {
		if (!date) return '';
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
	};
	const AddDailyReportDetails = () => {
		let isValid = validate();
		if (isValid) {
			let data = {
				date: new Date(selectdate),
				// date: moment(selectdate).format('DD/MM/yyyy'),
				time: time,
				investorType: investorType,
				clientName: clientName,
				callType: callType,
				contactNumber: contactNumber,
				productsPitchedId: parseInt(productpitched),
				sourceId: parseInt(source),
				nextMeetingDate: new Date(nextmeetingDate),
				businessSourcedOrExpected: businessS_E,
				amount: Number(amount),
				typesOfFunds: typeofund,
				scheme: scheme,
				investmentType: lumpsumsip,
				numberOfSip: lumpsumsip === 'SIP' ? parseInt(noofsip) : 0,
				status: status,
				remarksId: parseInt(remark),
				otherRemark: parseInt(remark) === 3 ? otherremark : '',
				submitDate: fixTimezoneOffset(
					new Date(submitDate.getFullYear(), submitDate.getMonth(), submitDate.getDate(), 23, 59, 59)
				),
				userId: UserId
			};

			AddDailySalesReport(data)
				.then((res) => {
					if (res.data.response.status === 1) {
						Swal.fire('Record saved successfully', '', 'success');
						recalldailysalesreport();
						handleshowhide();
						handleClear();
					} else {
						Swal.fire('Something Went Wrong', '', 'error');
					}

				})
				.catch((err) => {
					Swal.fire('Something Went Wrong', '', 'error');
					console.log(err);
				});
		}
	};
	const UpdateDailyReportDetails = () => {
		let isValid = validate();
		// console.log(isValid)
		if (isValid) {
			let data = {
				date: new Date(selectdate),
				// date: moment(selectdate).format('DD/MM/yyyy'),
				dailyReportId: dailysalesreportId,
				time: time,
				investorType: investorType,
				clientName: clientName,
				callType: callType,
				contactNumber: contactNumber,
				productsPitchedId: parseInt(productpitched),
				sourceId: parseInt(source),
				nextMeetingDate: fixTimezoneOffset(nextmeetingDate),
				businessSourcedOrExpected: businessS_E,
				amount: Number(amount),
				typesOfFunds: typeofund,
				scheme: scheme,
				investmentType: lumpsumsip,
				numberOfSip: lumpsumsip === 'SIP' ? parseInt(noofsip) : 0,
				status: status,
				remarksId: parseInt(remark),
				otherRemark: parseInt(remark) === 3 ? otherremark : '',
				userId: UserId
			};

			UpdateDailySalesReport(data)
				.then((res) => {
					if (res.data.response.status === 1) {
						Swal.fire('Daily sales report record update successfully', '', 'success');
						recalldailysalesreport();
						handleshowhide();
						handleClear();
					} else {
						Swal.fire('Something Went Wrong', '', 'error');
					}
				})
				.catch((err) => {
					Swal.fire('Something Went Wrong', '', 'error');
					console.log(err);
				});
		}
	};
	const handleClear = () => {
		setTime('');
		setInvestorType('');
		setClientName('');
		setContactNumber('');
		setCallType('');
		setNextmeetingDate(new Date());
		setProductpitched('');
		setSource('');
		setBusinessS_E('');
		setAmount('');
		setTypeofund('');
		setSchemelist([]);
		setScheme('');
		setLumpsumsip('');
		setNoofsip('');
		setStatus('');
		setRemark('');
		setError({});
		setOtherremark('');
		setStaticdata({
			amount: '',
			typeofund: '',
			scheme: '',
			lumpsumsip: '',
			noofsip: '',
			status: ''
		})
	};
	return (
		<div>
			{loading === true ? <LoadingScreen /> : null}
			<Modal size='lg' isOpen={show} toggle={handleClose}>
				<ModalHeader toggle={handleClose} className="modal-hd">
					{dailysalesreportId === 0 ? 'Add New DSR' : 'Update DSR'}
				</ModalHeader>
				<ModalBody>
					<Box>
						<form>
							<Grid container padding={2} spacing={1} rowSpacing={1.5} columnSpacing={4} className="border-grid">
								<Grid item xs={12} md={6}>
									{' '}
									Time <br />
									<select
										className="txtfield"
										value={time}
										onChange={(e) => {
											setTime(e.target.value);
											setError({ ...Error, time: '' });
										}}
									>
										<option value="">Select</option>
										{Time_slot &&
											Time_slot.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.time}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									{' '}
									Investor Type <br />
									<select
										className="txtfield"
										value={investorType}
										onChange={(e) => {
											setInvestorType(e.target.value);
											setError({ ...Error, investorType: '' });
										}}
									>
										<option value="">Select</option>
										{Investor_Type &&
											Investor_Type.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.investorType}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Client Name<br />
									<input
										className="txtfield"
										value={clientName}
										onChange={(e) => {
											setClientName(e.target.value);
											setError({ ...Error, clientName: '' });
										}}
									/>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.clientName}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Contact Number<br />
									<input
										type="number"
										className="txtfield"
										value={contactNumber}
										onChange={(e) => {
											setContactNumber(e.target.value);
											setError({ ...Error, contactNumber: '' });
										}}
									/>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.contactNumber}
										</div>
									) : null}
								</Grid>

								<Grid item xs={12} md={6}>
									Call Type <br />
									<select
										className="txtfield"
										value={callType}
										onChange={(e) => {
											setCallType(e.target.value);
											setError({ ...Error, callType: '' });
										}}
									>
										<option value="">Select</option>
										{Call_Type &&
											Call_Type.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.callType}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									{' '}
									Next Meeting Date <br />
									{/* <input className="txtfield" /> */}
									<DatePicker
										className="txtfield"
										selected={nextmeetingDate}
										dateFormat="dd/MM/yyyy"
										onChange={(date) => setNextmeetingDate(date)}
										showMonthDropdown
										showYearDropdown
										scrollableYearDropdown
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									Products Pitched<br />
									<select
										className="txtfield"
										value={productpitched}
										onChange={(e) => {
											setProductpitched(e.target.value);
											setError({ ...Error, productpitched: '' });
										}}
									>
										<option value="">Select</option>
										{productpitchedlist &&
											productpitchedlist.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.productpitched}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Source<br />
									<select
										className="txtfield"
										value={source}
										onChange={(e) => {
											setSource(e.target.value);
											setError({ ...Error, source: '' });
										}}
									>
										<option value="">Select</option>
										{sourcelist &&
											sourcelist.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.source}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									{' '}
									Business Sourced/Expected <br />
									<select
										className="txtfield"
										value={businessS_E}
										onChange={(e) => {
											setBusinessS_E(e.target.value);
											if (e.target.value === "No") {
												setAmount('');
												setTypeofund('');
												setScheme('');
												setSchemelist([]);
												setLumpsumsip('');
												setNoofsip('');
												setStatus('Closed');
												setError({ ...Error, businessS_E: '', amount: '', scheme: '', lumpsumsip: '', noofsip: '', typeofund: '' });
											} else {
												setAmount(staticdata.amount)
												setTypeofund(staticdata.typeofund);
												setScheme(staticdata.scheme);
												setLumpsumsip(staticdata.lumpsumsip);
												setNoofsip(staticdata.noofsip);
												setStatus('');
												setError({ ...Error, businessS_E: '', amount: '', scheme: '', lumpsumsip: '', noofsip: '', typeofund: '' });
											}
										}}
									>
										<option value="" disabled >Select</option>
										{Business_Sourced_Expected &&
											Business_Sourced_Expected.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.businessS_E}
										</div>
									) : null}
								</Grid>

								<Grid item xs={12} md={6}>
									Amount<br />{' '}
									<Cleave
										className="txtfield"
										autoComplete="off"
										value={amount}
										options={{ numeral: true }}
										onChange={(e) => {
											setAmount(Number(e.target.rawValue) <= 0 ? "" : e.target.rawValue);
											setError({ ...Error, amount: '' });
											setStaticdata({ ...staticdata, amount: Number(e.target.rawValue) <= 0 ? "" : e.target.rawValue });
										}}
										disabled={businessS_E === "No"}
									/>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.amount}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Type of Fund<br />
									<select
										className="txtfield"
										value={typeofund}
										onChange={(e) => {
											setTypeofund(e.target.value);
											setStaticdata({ ...staticdata, typeofund: e.target.value });
											setScheme('');
											setError({ ...Error, typeofund: '' });
										}}
										disabled={businessS_E === "No"}
									>
										<option value="">Select</option>
										{Type_Of_Fund &&
											Type_Of_Fund.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>{' '}
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.typeofund}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Scheme<br />
									<select
										className="txtfield"
										value={scheme}
										onChange={(e) => {
											setScheme(e.target.value);
											setError({ ...Error, scheme: '' });
											setStaticdata({ ...staticdata, scheme: e.target.value });
										}}
										disabled={businessS_E === "No"}
									>
										<option value="">Select</option>
										{schemelist &&
											schemelist.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>{' '}
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.scheme}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Lumpsum/SIP<br />
									<select
										className="txtfield"
										value={lumpsumsip}
										onChange={(e) => {
											setLumpsumsip(e.target.value);
											setStaticdata({ ...staticdata, lumpsumsip: e.target.value });
											setNoofsip('');
											setError({ ...Error, lumpsumsip: '' });
										}}
										disabled={businessS_E === "No"}
									>
										<option value="">Select</option>
										{Lumpsum_Sip &&
											Lumpsum_Sip.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>{' '}
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.lumpsumsip}
										</div>
									) : null}
								</Grid>
								{lumpsumsip === 'SIP' ? (
									<Grid item xs={12} md={6}>
										No. of SIP<br />
										<input
											type="number"
											className="txtfield"
											value={noofsip}
											onChange={(e) => {
												setNoofsip(e.target.value);
												setStaticdata({ ...staticdata, noofsip: e.target.value });
												setError({ ...Error, noofsip: '' });
											}}
										/>
										{Error ? (
											<div style={{ fontSize: 12, color: 'red' }}>
												{Error.noofsip}
											</div>
										) : null}
									</Grid>
								) : null}
								<Grid item xs={12} md={6}>
									Status<br />
									<select
										className="txtfield"
										value={status}
										onChange={(e) => {
											setStatus(e.target.value);
											setStaticdata({ ...staticdata, status: e.target.value });
											setError({ ...Error, status: '' });
										}}
										disabled={businessS_E === "No" ? true : false}
									>
										<option value="">Select</option>
										{Status &&
											Status.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>{' '}
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.status}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={6}>
									Remarks<br />
									<select
										className="txtfield"
										value={remark}
										onChange={(e) => {
											setRemark(e.target.value);
											setError({ ...Error, remark: '' });
											setOtherremark('');
										}}
									>
										<option value="">Select</option>
										{remarklist &&
											remarklist.map((x, indx) => {
												return (
													<option key={indx} value={x.value}>
														{x.label}
													</option>
												);
											})}
									</select>
									{Error ? (
										<div style={{ fontSize: 12, color: 'red' }}>
											{Error.remark}
										</div>
									) : null}
								</Grid>
								{/* <Grid item xs={12} md={6}>
									<input
										className="form-check-input"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio1"
										value="option1"
									/>
									<label className="form-check-label" for="inlineRadio1">
										Lumpsum
									</label>

									<input
										className="form-check-input"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio2"
										value="option2"
									/>
									<label className="form-check-label" for="inlineRadio2">
										SIP
									</label>
								</Grid> */}
								{parseInt(remark) === 3 ? (
									<Grid item xs={12} md={12}>
										<textarea
											rows="2"
											cols="40"
											name="comment"
											form="usrform"
											className="txtfield"
											value={otherremark}
											onChange={(e) => setOtherremark(e.target.value)}
										/>
									</Grid>
								) : null}
							</Grid>
						</form>
					</Box>
				</ModalBody>
				<ModalFooter>
					<div>
						{dailysalesreportId === 0 ? (
							<Button className="btn-grad" variant="contained" onClick={() => AddDailyReportDetails()}>
								Save
							</Button>
						) : (
							<Button className="btn-grad" variant="contained" onClick={() => UpdateDailyReportDetails()}>
								Update
							</Button>
						)}{' '}
					</div>
					<div>
						<Button variant="contained" className="btn-grad" onClick={handleClose}>
							Cancel
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default AddUpdateDailySR;
