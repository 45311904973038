import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
/* redux */
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
// import { persistStore } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';
// let persistor = persistStore(store);
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		{/* <PersistGate persistor={persistor}> */}
		<App />
		{/* </PersistGate> */}
	</Provider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
