import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import marketscope_icon from '../../../assets/images/marketscope-w.png';
import dailyreport_icon from '../../../assets/images/dailyreport-w.png';
import { Link } from 'react-router-dom';
//import { minWidth } from "@mui/system";
// import { Container, Row, Col } from "react-bootstrap";
import TopNavigation from '../../layouts/TopNavigation';
import SideNavigation from '../../layouts/SideNavigation-dashboard';
import './dashboardContent.css';
import { getdashboarddetailsbyuserid, getdashboarddsrsummerybyuserid, getdashboardyearlysummerybyuserid, alertForMonths, alertForSingleMonth, getsip_lumpsumtarget } from '../../Api/DashboardApi';
import Auth from '../../../modules/Auth';
import { dailyreportcallalert, dsrdatealert } from '../../Api/DailySalesReportApi';
import { ToastContainer, toast, Flip } from 'react-toastify';
import moment from 'moment';
import ChartComponent from './ChartComponent';
import LineCharComponent from './LineCharComponent';

const DashboardContent = () => {
	const UserId = Auth.getUserId();
	const [dashboarddetails, setdashboarddetails] = useState({});
	const [totalleads, setTotalleads] = useState({});
	const [DailyDsrdetails, setDailyDsrdetails] = useState({});
	const [MonthlyDsrdetails, setMonthlyDsrdetails] = useState({});
	const [YearlyDsrdetails, setYearlyDsrdetails] = useState({});
	const [YearlyDsrtargetdetails, setYearlyDsrtargetdetails] = useState({});
	const [Datedetails, setDatedetails] = useState({});
	const [siplumpsumtarget, setSiplumpsumtarget] = useState({});
	const [fromdate, setFromdate] = useState(null);
	const [todate, setTodate] = useState(null);
	const [nimfsystemmonth, setNimfsystemmonth] = useState({});
	const [nimfsystemyear, setNimfsystemyear] = useState({});
	useEffect(() => {
		const callalert = JSON.parse(localStorage.getItem('DashboardDailyCallAlert'));

		if (callalert !== 1) {
			DSRDateAlert();
			DailySalesCallReport();
			sixMonthReviewAlert();
			singleMonthReviewAlert();
			localStorage.setItem('DashboardDailyCallAlert', 1);
		}
		GetDashBoardDetails();
		GetDashboradDsrSummeryByUserID();
		GetDashboradDsrYearlySummeryByUserID();
		GetSip_LumpsumTarget();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const GetSip_LumpsumTarget = () => {
		let selecteddate = moment(new Date).format('yyyy-MM-DD');
		let data = {
			"UserId": UserId,
			"Date": selecteddate
		}
		getsip_lumpsumtarget(data).then((res) => {
			if (res.data.response.status === 1) {
				let result = res.data.response.data;
				setNimfsystemmonth(result.nimfMTargets === null ? {} : result.nimfMTargets);
				setNimfsystemyear(result.nimfYTargets  === null ? {} : result.nimfYTargets);
				setSiplumpsumtarget(result.monthly[0])
			}
		}).catch((err) => {
			console.log(err);
		})
	}
	const GetDashboradDsrSummeryByUserID = () => {
		getdashboarddsrsummerybyuserid(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let resultdaily =
						res.data.response.data.dsrPrevDay[0] === undefined
							? {}
							: res.data.response.data.dsrPrevDay[0];
					let resultmonthly =
						res.data.response.data.dsrMonthlyDetails[0] === undefined
							? {}
							: res.data.response.data.dsrMonthlyDetails[0];
					let userdatedetails =
						res.data.response.data.userDetails[0] === undefined
							? {}
							: res.data.response.data.userDetails[0];
					setDailyDsrdetails(resultdaily);
					setMonthlyDsrdetails(resultmonthly);
					setDatedetails(userdatedetails)
				}
			})
			.catch((err) => {
				console.log(err, 'err in dsr summery');
			});
	};
	const GetDashboradDsrYearlySummeryByUserID = () => {
		getdashboardyearlysummerybyuserid(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let resultyearly =
						res.data.response.data.dsrYearly[0] === undefined
							? {}
							: res.data.response.data.dsrYearly[0];
					let resulttargetyearly =
						res.data.response.data.querynTargetYearly[0] === undefined
							? {}
							: res.data.response.data.querynTargetYearly[0];
					setYearlyDsrdetails(resultyearly);
					setYearlyDsrtargetdetails(resulttargetyearly);
					setFromdate(res.data.response.data.convertDate);
					setTodate(res.data.response.data.oneYearDate);
				}
			})
			.catch((err) => {
				console.log(err, 'err in dsr year summery');
			});
	};
	const GetDashBoardDetails = () => {
		getdashboarddetailsbyuserid(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result =
						res.data.response.data.marketScopeDetails[0] === undefined
							? {}
							: res.data.response.data.marketScopeDetails[0];
					setdashboarddetails(result);
					let leads =
						res.data.response.data.totalLeads[0] === undefined ? {} : res.data.response.data.totalLeads[0];
					setTotalleads(leads);
				} else {
					setdashboarddetails({});
				}
			})
			.catch((err) => {
				setdashboarddetails({});
				console.log(err);
			});
	};
	const DailySalesCallReport = () => {
		dailyreportcallalert(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					if (res.data.response.data.alertShow === 0) {
						let targetcallcount = res.data.response.data.targetCallCount;
						let callcount = res.data.response.data.callCount;
						let remaining_callcount = targetcallcount - callcount;
						toast.warn(
							`Today's call targets are not achieved. Today's remaining call count is ${remaining_callcount}`,
							{ theme: 'dark', autoClose: false }
						);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const DSRDateAlert = () => {
		var date = new Date();
		date.setDate(date.getDate() - 1);
		let previousday = moment(date).format('DD/MM/yyyy');
		dsrdatealert(UserId, previousday)
			.then((res) => {
				if (res.data.response.status === 1) {
					if (res.data.response.data.alertShow === 0) {
						toast.warn(`DSR dated ${previousday} is not filled`, { theme: 'dark', autoClose: false });
					}
					if (res.data.response.data.alertShow === 3) {
						toast(`Welcome to Nippon India Mutual Fund`, {
							theme: 'light',
							transition: Flip,
							position: 'top-left',
							hideProgressBar: false,
							autoClose: true
						});
					}

				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const sixMonthReviewAlert = () => {

		alertForMonths(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let message = res.data.response.message;

					toast.warn(` ${message}`, { theme: 'dark', autoClose: false });

				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const singleMonthReviewAlert = () => {

		alertForSingleMonth(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let message = res.data.response.message;
					toast.warn(` ${message}`, { theme: 'dark', autoClose: false });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<Box sx={{ width: '100%' }}>

			<ToastContainer
				position="bottom-center"
				// autoClose={false}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<TopNavigation />
			<SideNavigation style={{ innerHeight: '190vh' }} />

			<div className='doj right' style={{ paddingRight: '90px' }}>DOJ: {Datedetails.createDate === undefined ? "" : moment(new Date(Datedetails.createDate)).format("DD-MM-yyyy")}<span className='doj-days'> {Datedetails.days === undefined ? "" : `(${parseInt(Datedetails.days)}) days`}</span></div>

			<Grid container padding={2} rowSpacing={2} spacing={3} width={'100%'} paddingLeft={'90px'}>
				{/*<Grid item xs={12} md={8}>
			<div className='card__welcome'>
				<div className='card__welcome_text'>
				<h4>Welcome Username</h4>
				You have following task to complete.
				<ul>
					<li>Today’s call target not achieved.</li>
					<li>Today’s remaining call count is 10.</li>
					<li>DSR dated 25-08-2022 not filled.</li>
				</ul>
				</div>
			</div>
		</Grid>
		<Grid item xs={6} md={2}>
		
		<Card className="card-counter">
						<CardContent>
							<Typography variant="h3" className="count">
								<CountUp end={dashboarddetails.clients} duration={5} />
							</Typography>
							<Typography>Scope points</Typography>
						</CardContent>
					</Card>
		</Grid>
		<Grid item xs={6} md={2}>
		<Card className="card-counter2">
						<CardContent>
							<Typography variant="h3" className="count">
								<CountUp end={totalleads.totalLeads} duration={5} separator={3} />
							</Typography>
							<Typography>Total Leads</Typography>
						</CardContent>
					</Card>
		</Grid>*/}


				<Grid item xs={12} md={12}>
					<div className="cards">
						<div className="card card-1">
							<Link to="/marketscope">
								<div className="card__title">Market Scope </div>
								<div className="card__icon">
									<img src={marketscope_icon} alt="Market Scope" className="img-fluid" />
									{/*<div className="card__title">{dashboarddetails.clients} </div> */}
								</div>
							</Link>
						</div>

						<div className="card card-2">
							<Link to="/dailysalesreport">
								<div className="card__title">Daily Reports</div>
								<div className="card__icon">
									<img src={dailyreport_icon} alt="Daily Report" className="img-fluid" />
								</div>
							</Link>
						</div>

						<div className="card card-3">
							<Link to="/scopepoints">
								<div className="card__title">Scope Points</div>
								<div className="card__icon card__count card__count__mt">
									{/* <CountUp end={dashboarddetails.clients} duration={1}  className="card__count" /> */}
									<span>{dashboarddetails.clients === undefined ? 0 : dashboarddetails.clients}</span>
								</div>
							</Link>
						</div>

						<div className="card card-5">
							{/* <Link to="/totalleads"> */}
							<div className="card__title">Total Leads</div>
							<div className="card__icon card__count card__count__mt" >
								{/* <CountUp end={totalleads.totalLeads} duration={1} separator={3}  className="card__count"/> */}
								<span>{totalleads.totalLeads === undefined ? 0 : totalleads.totalLeads}</span>
							</div>
							{/* </Link> */}
						</div>
					</div>
				</Grid>

				<Grid container padding={2} rowSpacing={2} spacing={3} paddingLeft={'30px'} rTGowSpacing={5}>
					<Grid item xs={12} md={6}>
						<div className="dashboard__counter-header">
							{moment(new Date()).format('MMMM')}
							<div className="dashboard__counter-header-bg" />
						</div>
					</Grid>
					{/* <Grid item xs={12} md={6} textAlign={'right'} >
					<div className='doj'>DOJ: {Datedetails.createDate === undefined ? "" : moment(new Date(Datedetails.createDate)).format("DD-MM-yyyy")}<span className='doj-days'> { Datedetails.days === undefined ? "" : `(${parseInt(Datedetails.days)}) days` }</span></div>
					</Grid> */}
					<Grid item xs={12} md={12}>
						<div className="dashboard__counter-border">

							<div className="dashboard__counters">
								<div className="dashboard__counter">
									<div className="dashboard__counter-content">
										<h6>As per NIMF system (Lumpsum)</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												nimfsystemmonth.nimfMLumpsum === undefined ? 0 : nimfsystemmonth.nimfMLumpsum
											)}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>As per NIMF system (SIP)</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												nimfsystemmonth.nimfMSip === undefined ? 0 : nimfsystemmonth.nimfMSip
											)}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>


								</div>
								<div className="dashboard__counter-divider" />
								<div className="dashboard__counter">

									<div className="dashboard__counter-content">

										<h6>Lumpsum Target</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												siplumpsumtarget.lumpsumTarget === undefined ? 0 : siplumpsumtarget.lumpsumTarget
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">

										<h6>SIP Target</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												siplumpsumtarget.sipTarget === undefined ? 0 : siplumpsumtarget.sipTarget
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
									</div>

								</div>


								<div className="dashboard__counter-divider" />

								<div className="dashboard__counter">

									<div className="dashboard__counter-content">

										<h6>Lumpsum Achivement</h6>
										<h4>
											&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												MonthlyDsrdetails.lumpsumAmount === undefined ? 0 : MonthlyDsrdetails.lumpsumAmount
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>SIP Achivement</h6>
										<h4>
											&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												MonthlyDsrdetails.sipTotalAmount === undefined ? 0 : MonthlyDsrdetails.sipTotalAmount
											)}
										</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>

								</div>

								<div className="dashboard__counter-divider" />

								<div className="dashboard__counter">


									<div className="dashboard__counter-content">
										<h6>Lumpsum Application</h6>
										<h4>{MonthlyDsrdetails.lumpsumApplication === undefined ? 0 : MonthlyDsrdetails.lumpsumApplication}</h4>
										{/* <p>
										<span className="decrease">- 5% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>SIP Application</h6>
										<h4>{MonthlyDsrdetails.sipApplication === undefined ? 0 : MonthlyDsrdetails.sipApplication}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>

								</div>
								<div className="dashboard__counter-divider" />
								<div className="dashboard__counter">


									<div className="dashboard__counter-content-wrap" >
										<div className="dashboard__counter-content"><h6>Open Leads</h6>
											<h4>{MonthlyDsrdetails.openLeads === undefined ? 0 : MonthlyDsrdetails.openLeads}</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}</div>
										<div className="dashboard__counter-content">
											<h6>Closed Leads</h6>
											<h4>{MonthlyDsrdetails.closedLeads === undefined ? 0 : MonthlyDsrdetails.closedLeads}</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}</div>
									</div>

									<div className="dashboard__counter-content">
										<h6>Total Meetings</h6>
										<h4>{MonthlyDsrdetails.totalMeetingsDone === undefined ? 0 : MonthlyDsrdetails.totalMeetingsDone}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>
								</div>

							</div>
						</div>
					</Grid>

					<Grid item xs={12} md={12}>
						<LineCharComponent />
					</Grid>

					<Grid item xs={12} md={12}>
						<div className="dashboard__counter-header">
							Previous Day
							<div className="dashboard__counter-header-bg" />
						</div>
					</Grid>
					<Grid item xs={12} md={12}>
						<div className="dashboard__counter-border">

							<div className="dashboard__counters">
								<div className="dashboard__counter">

									<div className="dashboard__counter-content">
										<h6>Total Meetings</h6>
										<h4>
											{DailyDsrdetails.totalMeetingsDone === undefined ? (
												0
											) : (
												DailyDsrdetails.totalMeetingsDone
											)}
										</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
									</div>
								</div>

								<div className="dashboard__counter-divider" />

								<div className="dashboard__counter">
									<div className="dashboard__counter-content">
										<h6>Lumpsum Achivement</h6>
										<h4>
											&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												DailyDsrdetails.lumpsumAmount === undefined
													? 0
													: DailyDsrdetails.lumpsumAmount
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from yesterday
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>SIP Achivement</h6>
										<h4>
											&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												DailyDsrdetails.sipTotalAmount === undefined
													? 0
													: DailyDsrdetails.sipTotalAmount
											)}
										</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
									</div>

								</div>

								<div className="dashboard__counter-divider" />

								<div className="dashboard__counter">
									<div className="dashboard__counter-content">
										<h6>Lumpsum Application</h6>
										<h4>
											{DailyDsrdetails.lumpsumApplication === undefined ? (
												0
											) : (
												DailyDsrdetails.lumpsumApplication
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 5% </span> from yesterday
									</p> */}
									</div>

									<div className="dashboard__counter-content">
										<h6>SIP Application</h6>
										<h4>
											{DailyDsrdetails.sipApplication === undefined ? (
												0
											) : (
												DailyDsrdetails.sipApplication
											)}
										</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
									</div>

								</div>
								<div className="dashboard__counter-divider" />
								<div className="dashboard__counter">

									<div className="dashboard__counter-content">
										<h6>Open Leads</h6>
										<h4>{DailyDsrdetails.openLeads === undefined ? 0 : DailyDsrdetails.openLeads}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>Closed Leads</h6>
										<h4>
											{DailyDsrdetails.closedLeads === undefined ? 0 : DailyDsrdetails.closedLeads}
										</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from yesterday
									</p> */}
									</div>
								</div>

							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={12}>
						<div className="dashboard__counter-header">
							{/* {moment(new Date()).format('MMMM')} */}
							YTD <span>{fromdate === null || todate === null ? "" : `(From: ${fromdate == null ? "" : moment(new Date(fromdate)).format('DD/MM/yyyy')} - To: ${todate == null ? "" : moment(new Date(todate)).format('DD/MM/yyyy')})`}</span>
							<div className="dashboard__counter-header-bg" />
						</div>
					</Grid>
					<Grid item xs={12} md={12}>
						<div className="dashboard__counter-border">

							<div className="dashboard__counters">
								<div className="dashboard__counter">
									<div className="dashboard__counter-content">
										<h6>As per NIMF system (Lumpsum)</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												nimfsystemyear.nimfYLumpsum === undefined ? 0 : nimfsystemyear.nimfYLumpsum
											)}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>As per NIMF system (SIP)</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												nimfsystemyear.nimfYSip === undefined ? 0 : nimfsystemyear.nimfYSip
											)}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>


								</div>
								<div className="dashboard__counter-divider" />
								<div className="dashboard__counter">

									<div className="dashboard__counter-content">

										<h6>Lumpsum Target</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												YearlyDsrtargetdetails.lumpsumTarget === undefined ? 0 : YearlyDsrtargetdetails.lumpsumTarget
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">

										<h6>SIP Target</h6>
										<h4>&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												YearlyDsrtargetdetails.sipTarget === undefined ? 0 : YearlyDsrtargetdetails.sipTarget
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
									</div>

								</div>
								<div className="dashboard__counter-divider" />
								<div className="dashboard__counter">

									<div className="dashboard__counter-content">

										<h6>Lumpsum Achivement</h6>
										<h4>
											&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												YearlyDsrdetails.lumpsumAmount === undefined ? 0 : YearlyDsrdetails.lumpsumAmount
											)}
										</h4>
										{/* <p>
										<span className="decrease">- 15% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>SIP Achivement</h6>
										<h4>
											&#x20B9;{' '}
											{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
												YearlyDsrdetails.sipTotalAmount === undefined ? 0 : YearlyDsrdetails.sipTotalAmount
											)}
										</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>

								</div>

								<div className="dashboard__counter-divider" />

								<div className="dashboard__counter">


									<div className="dashboard__counter-content">
										<h6>Lumpsum Application</h6>
										<h4>{YearlyDsrdetails.lumpsumApplication === undefined ? 0 : YearlyDsrdetails.lumpsumApplication}</h4>
										{/* <p>
										<span className="decrease">- 5% </span> from July
									</p> */}
									</div>
									<div className="dashboard__counter-content">
										<h6>SIP Application</h6>
										<h4>{YearlyDsrdetails.sipApplication === undefined ? 0 : YearlyDsrdetails.sipApplication}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>

								</div>
								<div className="dashboard__counter-divider" />
								<div className="dashboard__counter">


									<div className="dashboard__counter-content-wrap" >
										<div className="dashboard__counter-content"><h6>Open Leads</h6>
											<h4>{YearlyDsrdetails.openLeads === undefined ? 0 : YearlyDsrdetails.openLeads}</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}</div>
										<div className="dashboard__counter-content">
											<h6>Closed Leads</h6>
											<h4>{YearlyDsrdetails.closedLeads === undefined ? 0 : YearlyDsrdetails.closedLeads}</h4>
											{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}</div>
									</div>

									<div className="dashboard__counter-content">
										<h6>Total Meetings</h6>
										<h4>{YearlyDsrdetails.totalMeetingsDone === undefined ? 0 : YearlyDsrdetails.totalMeetingsDone}</h4>
										{/* <p>
										<span className="increase">+ 15% </span> from July
									</p> */}
									</div>
								</div>

							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={12}>
						<ChartComponent />
					</Grid>
				</Grid>

				{/* 	<Grid container spacing={5} padding={6} rowSpacing={5} display="flex" justifyContent={'center'}>
				
				<Grid item xs={4} md={2}>
					<Card className="card-counter">
						<CardContent>
							<Typography variant="h3" className="count">
								<CountUp end={dashboarddetails.lumpSumTarget} duration={5} />
							</Typography>
							<Typography>Lumpsum Target</Typography>
						</CardContent>
					</Card>
				</Grid> 
				<Grid item xs={4} md={2}>
					<Card className="card-counter">
						<CardContent>
							<Typography variant="h3" className="count">
								<CountUp end={dashboarddetails.sipTarget} duration={5} />
							</Typography>
							<Typography>SIP Target</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={4} md={2}>
					<Card className="card-counter">
						<CardContent>
							<Typography variant="h3" className="count">
								<CountUp end={dashboarddetails.openStatus} duration={5} />
							</Typography>
							<Typography>Open Leads</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={4} md={2}>
					<Card className="card-counter">
						<CardContent>
							<Typography variant="h3" className="count">
								<CountUp end={dashboarddetails.closedStatus} duration={5} />
							</Typography>
							<Typography>Closed Leads</Typography>
						</CardContent>
					</Card>
				</Grid>
				
			</Grid>*/}
			</Grid>
		</Box>
	);
};

export default DashboardContent;
