import React from "react";
import nippon_logo from "../../assets/images/nipponindia-mutual-fund-logo.png";
import { Grid } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Link } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Auth from "../../modules/Auth/index";
const SupervisorTopNavigation = () => {
  const Username = Auth.getUserName();
  return (
    <div>
 <div className='banner-bg-admin'>
        <Link to="/admindashboard" title='Go to Dashboard'><img src={nippon_logo} alt="Nippon India" className="img-fluid" /></Link>
        <div className="nav-user__logout"> 
        <Link to="/admindashboard" title='Go to Dashboard'><HomeOutlinedIcon /></Link>&nbsp;
        {" "}<a onClick={() => Auth.logout()} title="Logout" ><ExitToAppIcon /></a></div>
</div>
    </div>
  );
};

export default SupervisorTopNavigation;
