import React from "react";
import nippon_logo from "../../assets/images/nipponindia-mutual-fund-logo.png";
import { Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from "react-router-dom";
import Auth from "../../modules/Auth/index";
const TopNavigation = () => {
  const Username = Auth.getUserName();
  return (
    <div>
      <Grid container spacing={1} className="banner-bg top-nav">
        <div>
          <Link to="/dashboard" title='go to dashboard'>
            <img src={nippon_logo} alt="Nippon India" className="img-fluid" aria-label="This is aria label" />
          </Link>
        </div>


        <div className="nav-user" id="nav-user">
          <div className="dropdown">
            <div className="dropbtn">
              <span className="nav-username1"> {`${Username}`}</span>
              <AccountCircleOutlinedIcon className="nav-icon" />
            
            </div>
            <div className="dropdown-content">
              {/*<h3> {`${Username}`}</h3>*/}
              <ul>
                <li className="nav-username">{`${Username}`}</li>
                <li className="dashed">
                  {" "}
                  <Link to="/userprofile">Edit Profile</Link>
                </li>
                <li>
                  {" "}

                  <a onClick={() => Auth.logout()} >Logout</a>

                </li>
              </ul>
            </div>
          </div>
          <Link to="/" title='Go to Dashboard'><HomeOutlinedIcon className="nav-icon" /></Link>
        </div>
      </Grid>
    </div>
  );
};

export default TopNavigation;
