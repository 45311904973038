import React, { useState, useEffect } from 'react';
import {
	getNatureOfInvDetails,
	gettopindassoDetails,
	IndassoDetailsDeleteById,
	// IndassoDetailsAddAndUpdate
} from '../../Api/MarketScopeApi';
import $ from 'jquery';
import { IconButton, Typography, Grid, Button, Tooltip } from '@mui/material';
import { Edit, Done } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Auth from '../../../modules/Auth/index';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
// import { store } from '../../../redux/store';
import LoadingScreen from '../../layouts/LoadingScreen';
const TIndAssociations = ({ ChangeTabA, RecallFunctionA, SubmitA, childFuncA }) => {
	const UserId = Auth.getUserId();
	/* redux */
	const state = useSelector((state) => state.indassodata);
	const dispatch = useDispatch();
	const { setindassociations, updateindassociations } = bindActionCreators(actionCreators, dispatch);
	/* end redux */
	const [data, setData] = useState([]);
	const [natureOfInv, setNatureOfInv] = useState([]);
	const [loading, setLoading] = useState(false);
	const GetNatureInvDetails = () => {
		getNatureOfInvDetails()
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.investmentDetails;
					let NatureInvDetails = [];
					result &&
						result.map((f) =>
							NatureInvDetails.push({
								label: f.natureInvName,
								value: f.natureInvId
							})
						);
					setNatureOfInv(NatureInvDetails);
				}
			})
			.catch((err) => {
				console.log(err, 'err in get sector details');
			});
	};
	const GetTopIndAssoDetails = () => {
		setLoading(true);
		gettopindassoDetails(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.industryDetailByUserID;
					result.push({
						industryAssociation_Name: '',
						contact_Person: '',
						designation: '',
						address: '',
						district: '',
						pinCode: '',
						contact_No: '',
						email: '',
						turn_Over: '',
						nature_of_Investment: '0',
						total_Employees: '',
						edit: true,
						userId: UserId,
						industryAssociationId: 0,
						marketScopeId: 6
					});
					setData(result);
					setindassociations(result);
				} else {
					let result = [];
					result.push({
						industryAssociation_Name: '',
						contact_Person: '',
						designation: '',
						address: '',
						district: '',
						pinCode: '',
						contact_No: '',
						email: '',
						turn_Over: '',
						nature_of_Investment: '0',
						total_Employees: '',
						edit: true,
						userId: UserId,
						industryAssociationId: 0,
						marketScopeId: 6
					});
					setData(result);
					setindassociations(result);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err, 'err in get top industry associations details');
			});
	};
	useEffect(() => {
		childFuncA.indassociation = GetTopIndAssoDetails
		GetNatureInvDetails();
		if (state.indassociationsDetails.length === 0) {
			GetTopIndAssoDetails();
		} else {
			setData(state.indassociationsDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	$('.tData').one('keyup', function () {
		if ($(this).closest('tr').is(':last-child')) {
			let newArr = [...data];
			newArr.push({
				industryAssociation_Name: '',
				contact_Person: '',
				designation: '',
				address: '',
				district: '',
				pinCode: '',
				contact_No: '',
				email: '',
				turn_Over: '',
				nature_of_Investment: '0',
				total_Employees: '',
				edit: true,
				userId: UserId,
				industryAssociationId: 0,
				marketScopeId: 6
			});
			setData(newArr);
			setindassociations(newArr);
		}
	});
	$('.drop_select').on('click', function () {
		if ($(this).closest('tr').is(':last-child')) {
			let newArr = [...data];
			newArr.push({
				industryAssociation_Name: '',
				contact_Person: '',
				designation: '',
				address: '',
				district: '',
				pinCode: '',
				contact_No: '',
				email: '',
				turn_Over: '',
				nature_of_Investment: '0',
				total_Employees: '',
				edit: true,
				userId: UserId,
				industryAssociationId: 0,
				marketScopeId: 6
			});
			setData(newArr);
			setindassociations(newArr);
		}
	});
	// const handleSubmitAndUpdate = () => {
	// 	setLoading(true);
	// 	let editopen = true;
	// 	let indassodetailsdata = data.map((S, ind) => {
	// 		if (S.edit === true && data.length -1 !== ind) {
	// 			editopen = false
	// 		}
	// 		return {
	// 			IndustryAssociationName: S.industryAssociation_Name,
	// 			contactPerson: S.contact_Person,
	// 			designation: S.designation,
	// 			address: S.address,
	// 			district: S.district,
	//     		pinCode:Number(S.pinCode),
	// 			contactNumber: S.contact_No,
	// 			emailAddress: S.email,
	// 			turnOver: Number(S.turn_Over),
	// 			natureInvId: Number(S.nature_of_Investment),
	// 			totalEmployees: Number(S.total_Employees),
	// 			userId: S.userId,
	// 			industryAssociationId: S.industryAssociationId,
	// 			marketScopeId: S.marketScopeId
	// 		};
	// 	});
	// 	if (editopen) {
	// 	indassodetailsdata.pop();
	// 	IndassoDetailsAddAndUpdate(indassodetailsdata)
	// 		.then((res) => {
	// 			if (res) {
	// 				setLoading(false);
	// 				Swal.fire({
	// 					icon: 'success',
	// 					title: 'Details saved successfully',
	// 					confirmButtonText: 'Ok'
	// 				}).then((result) => {
	// 					GetTopIndAssoDetails();
	// 					RecallFunctionA();
	// 				});
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			setLoading(false);
	// 			console.log(err, 'err in add and update');
	// 		});
	// 	} else {
	// 		setLoading(false);
	// 		Swal.fire('Record is in still edit mode','','warning')
	// 		  }
	// };
	const handleChange = (e, idx) => {
		const { name, value } = e.target;
		let values = name === "turn_Over" ? Number(value) <= 0 ? 0 : value : value;
		let newArr = [...data];
		newArr[idx][name] = values;
		setData(newArr);
		updateindassociations(newArr);
	};
	const handleNatureInvchange = (e, idx) => {
		let selected = e.target.value;
		let newArr = [...data];
		let name = 'nature_of_Investment';
		newArr[idx][name] = selected;
		setData(newArr);
		updateindassociations(newArr);
	};
	const handleEditchange = (value, idx) => {
		const isvalid = value === true ? handleValidate(idx) : true;
		if (isvalid) {
			let newArr = [...data];
			let name = 'edit';
			newArr[idx][name] = !value;
			setData(newArr);
			updateindassociations(newArr);
		}
	};
	const handleDeleteByindustryAssociationId = (ind, IndAssoID) => {
		Swal.fire({
			icon: 'warning',
			title: 'Are you sure you want to delete this record?',
			confirmButtonText: 'Yes',
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				if (IndAssoID !== 0) {
					IndassoDetailsDeleteById(IndAssoID)
						.then((res) => {
							if (res.data.response.status === 1) {
								let newArr = [...data];
								newArr.splice(ind, 1);
								setData(newArr);
								updateindassociations(newArr);

								setLoading(false);
								Swal.fire({
									icon: 'success',
									title: 'Record Delete Successfully',
									confirmButtonText: 'Ok'
								}).then((result) => {
									RecallFunctionA();
								})
							}
						})
						.catch((err) => {
							setLoading(false);
							console.log(err, 'err in delete');
						});
				} else {
					let newArr = [...data];
					newArr.splice(ind, 1);
					setData(newArr);
					updateindassociations(newArr);
					setLoading(false);
					Swal.fire('Record Delete Successfully', '', 'success');
				}
			}
		});
	};
	const handleValidate = (idx) => {
		const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
		const letters = /^[aA-zZ,.'"\s]+$/;
		const pincoderegex = /^(\d{6})$/;
		let valid = true;
		let validate = [...data];
		let industryAssociation_Name = "industryAssociation_Name"
		let contact_Person = "contact_Person"
		let designation = "designation"
		let address = "address"
		let district = "district"
		let pinCode = "pinCode"
		let contact_No = "contact_No"
		let email = "email"
		let turn_Over = "turn_Over"
		let nature_of_Investment = "nature_of_Investment"
		let total_Employees = "total_Employees"

		if (validate[idx][industryAssociation_Name].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter association name", { theme: "colored" });
			valid = false
		}
		if (validate[idx][contact_Person].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter contact person name", { theme: "colored" });
			valid = false
		} else if (!letters.test(validate[idx][contact_Person])) {
			toast.error("Only alphabets are allowed for contact person field", { theme: "colored" });
			valid = false
		}
		if (validate[idx][designation].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter designation", { theme: "colored" });
			valid = false
		}
		if (validate[idx][address].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter address", { theme: "colored" });
			valid = false
		}
		if (validate[idx][district] !== null) {
			if (validate[idx][district].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
				toast.error("Please enter district", { theme: "colored" });
				valid = false
			}
		} else if (validate[idx][district] === null) {
			toast.error("Please enter district", { theme: "colored" });
			valid = false
		}
		if (validate[idx][pinCode] === "") {
			toast.error("Please enter pincode", { theme: "colored" });
			valid = false
		} else if (pincoderegex.test(Number(validate[idx][pinCode])) === false) {
			toast.error("Invalid pincode", { theme: "colored" });
			valid = false
		}
		if (validate[idx][contact_No] === "") {
			toast.error("Please enter contact number", { theme: "colored" });
			valid = false
		} else if (validate[idx][contact_No].length >= 13) {
			toast.error("Contact number is not more than 12 digit", { theme: "colored" });
			valid = false
		}
		// else if (phoneregex.test(Number(validate[idx][contact_No])) === false) {
		// 	toast.error("Invalid contact number", { theme: "colored" });
		// 	valid = false
		// }
		if (validate[idx][email].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter email", { theme: "colored" });
			valid = false
		} else if (emailregex.test(validate[idx][email]) === false) {
			toast.error("Invalid email", { theme: "colored" });
			valid = false
		}
		if (validate[idx][turn_Over] === "") {
			toast.error("Please enter turn over", { theme: "colored" });
			valid = false
		}
		if (Number(validate[idx][nature_of_Investment]) === 0) {
			toast.error("Please select nature of investment", { theme: "colored" });
			valid = false
		}
		if (validate[idx][total_Employees] === "") {
			toast.error("Please enter total employees", { theme: "colored" });
			valid = false
		}
		return valid
	}

	return (
		<div>
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{loading === true ? <LoadingScreen /> : null}
			<Grid container spacing={0} padding={0} rowSpacing={1.5} paddingLeft={'80px'}>
				<Grid item xs={12} alignContent="flex-start">
					<Typography variant='h4' className='sub-heading'>
						Top Industry Associations
					</Typography>

				</Grid>
				<Grid item xs={12} md={12}>
					<div className='overflow'>
						<table
							id="mytable"
							className="normaltxt table-responsive"
							style={{ width: "100%" }}
						>
							<tbody>
								<tr>
									<th>Industry Associations Name</th>
									<th>Contact Person</th>
									<th>Designation</th>
									<th>Address</th>
									<th>District</th>
									<th>Pin Code</th>
									<th>Contact no.</th>
									<th>Email</th>
									<th>FD Amount</th>
									<th>Nature of Investment</th>
									<th>Total Employees</th>
									<th>Action</th>
								</tr>
								{data &&
									data.map(
										(
											{
												industryAssociation_Name,
												contact_Person,
												designation,
												address,
												district,
												pinCode,
												contact_No,
												email,
												turn_Over,
												nature_of_Investment,
												total_Employees,
												industryAssociationId,
												edit,
											},
											ind
										) => (
											<tr key={ind}>

												<td>
													{edit === false ? (
														<Typography>{industryAssociation_Name}</Typography>
													) : (
														<input
															name="industryAssociation_Name"
															className="market_txtfield tData"
															value={industryAssociation_Name}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{contact_Person}</Typography>
													) : (
														<input
															name="contact_Person"
															className="market_txtfield tData"
															value={contact_Person}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{designation}</Typography>
													) : (
														<input
															name="designation"
															className="market_txtfield tData"
															value={designation}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{address}</Typography>
													) : (
														<input
															name="address"
															className="market_txtfield_email tData"
															value={address}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{district}</Typography>
													) : (
														<input
															name="district"
															className="market_txtfield tData"
															value={district}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{pinCode}</Typography>
													) : (
														<input
															name="pinCode"
															type="Number"
															className="market_txtfield_pincode tData"
															value={pinCode}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{contact_No}</Typography>
													) : (
														<input
															type="Number"
															name="contact_No"
															className="market_txtfield tData"
															value={contact_No}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{email}</Typography>
													) : (
														<input
															type="email"
															name="email"
															className="market_txtfield_email tData"
															value={email}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{`₹${new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
															turn_Over
														)}`}</Typography>
													) : (
														<input
															type="Number"
															name="turn_Over"
															className="market_txtfield tData"
															placeholder='Amount(in crores)'
															value={turn_Over}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td className="tData" style={{ width: "10%" }}>
													{edit === false ? (
														<>
															{" "}
															{natureOfInv &&
																natureOfInv.map((f, i) => {
																	if (f.value === Number(nature_of_Investment)) {
																		return (
																			<Typography key={i}>{f.label}</Typography>
																		);
																	}
																	return null;
																})}{" "}
														</>
													) : (
														<select
															className="market_txtfield drop_select"
															value={nature_of_Investment}
															onChange={(selected) =>
																handleNatureInvchange(selected, ind)
															}
														>
															<option value={0}>Select</option>
															{natureOfInv &&
																natureOfInv.map((x, indx) => {
																	return (
																		<option key={indx} value={x.value}>
																			{x.label}
																		</option>
																	);
																})}
														</select>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{total_Employees}</Typography>
													) : (
														<input
															name="total_Employees"
															className="market_txtfield tData"
															type="Number"
															value={total_Employees}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td className='icons-wrap'>
													<Tooltip title={edit === false ? "Edit" : "Save"}>
														<IconButton
															aria-label="edit"
															color="primary"
															onClick={() => handleEditchange(edit, ind)}
															disabled={ind === data.length - 1}
														>
															{edit === false ? <Edit /> : <Done />}
														</IconButton>
													</Tooltip>
													<Tooltip title="Delete">
														<IconButton
															aria-label="delete"
															color="error"
															onClick={() =>
																handleDeleteByindustryAssociationId(ind, industryAssociationId)
															}
															disabled={ind === data.length - 1}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</Tooltip>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</div>
					<div className='p-t-5'>
						<IconButton variant="contained" onClick={() => ChangeTabA(5)} size="large"><NavigateBeforeIcon /></IconButton>
						<Button onClick={() => SubmitA(5)} variant="contained" className='btn-blue'>Save</Button>
						{/* <Button onClick={MarketScopeSubmit} variant="contained" className='btn-sm'>Submit</Button> */}
						{/* <IconButton variant="contained"><NavigateNextIcon /></IconButton> */}
					</div>

				</Grid>
			</Grid>

		</div>
	);
};





export default TIndAssociations