import React, { useState, useEffect } from 'react'
import { activeinactivesupervisor, getallsupervisorlist } from '../../Api/AdminApi';
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import LoadingScreen from '../../layouts/LoadingScreen';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import { Edit, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import AddUpdateSupervisor from './AddUpdateSupervisor';
import { exportExcel } from '../../../modules/helpers/exportExcel';

const AdminSupervisorManagementContent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [supervisorId, setSupervisorId] = useState(0);
  const [supervisorData, setSupervisorData] = useState({})
  const [search, setSearch] = useState("");

  const handleshowmodal = () => {
    setmodal(!modal);
    setSupervisorId(0);
    setSupervisorData({});
  };
  useEffect(() => {
    if (search === "") {
      GetSupervisorDetails();
    }
  }, [search])

  const GetSupervisorDetails = (type) => {
    setLoading(true);
    let data
    if (type === 'clear') {
      data = {
        supervisorName: ""
      }
    }
    else {
      data = {
        supervisorName: search.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '')
      }
    }
    getallsupervisorlist(data).then((res) => {
      setLoading(false);
      if (res.data.response.status === 1) {
        let result = res.data.response.data.supervisor
        setData(result);
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err, "err in getsupervisorlist")
    })
  }
  const handleDownloadExcel = async () => {
    let table1 = data.map((S, ind) => {
      return {
        supervisorName: S.supervisorName,
        supervisorEmailId: S.supervisorEmailId,
        supervisorMobileNo: S.supervisorMobileNo,
        supervisorCity: S.supervisorCity,
        supervisorState: S.supervisorState,
      };
    })
    let columns = [
      { header: 'Supervisor name', key: 'supervisorName', width: 30 },
      { header: 'Email', key: 'supervisorEmailId', width: 40 },
      { header: 'Mobile Number', key: 'supervisorMobileNo', width: 16 },
      { header: 'Region', key: 'supervisorState', width: 15 },
    ]
    exportExcel('SupervisorList', columns, table1)
  }
  const UserActiveDeActive = (supervisorId, act, idx) => {
    setLoading(true);
    let newArr = [...data];
    let supervisorData = {
      supervisorId: supervisorId,
      active: 1 === act ? 0 : 1
    }
    activeinactivesupervisor(supervisorData).then((res) => {
      setLoading(false);
      if (res.data.response.status === 1) {
        newArr[idx]["active"] = 1 === act ? 0 : 1;
        setData(newArr);
        Swal.fire(res.data.response.message, "", "success");
      }
    }).catch((err) => {
      setLoading(false);
      console.log("err active and inactive")
    })
  }
  const handleEditPopup = (editData) => {
    setSupervisorId(editData.supervisorId);
    setSupervisorData(editData);
    setmodal(true);
  };
  return (
    <div className='p-4'>
      {loading === true ? <LoadingScreen /> : null}
      <Grid container spacing={0} padding={0} rowSpacing={1.5}>
        <Grid item md={12} xs={12} alignContent="flex-start">
          <div className='sub-heading'>Supervisor Management</div>
        </Grid>
        <Grid item md={6} xs={6} className={'left'}>
          <div>
            <TextField
              id="input-with-icon-textfield"
              label="Search here"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search style={{
                      cursor: search.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "" ? 'not-allowed' : 'pointer'
                    }} onClick={() => {
                      if (search.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') !== "") {
                        GetSupervisorDetails()
                      }
                    }} />
                    {search.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "" ? null : <CancelIcon
                      onClick={() => {
                        GetSupervisorDetails('clear');
                        setSearch("");
                      }} style={{
                        cursor: 'pointer'
                      }} />}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </div>
        </Grid>

        <Grid item md={6} xs={6} className={'right'}>
          <div>
            <Button onClick={handleDownloadExcel} variant="contained" className='p-2' disabled={data.length === 0}>download excel</Button>

            <Button startIcon={<AddIcon />}
              className='p-2 m-2'
              variant="contained"
              onClick={handleshowmodal}
            >
              Add New Supervisor
            </Button>
            <AddUpdateSupervisor
              show={modal}
              handleshowhide={handleshowmodal}
              supervisorid={supervisorId}
              supervisorData={supervisorData}
              getsupervisordetails={GetSupervisorDetails}
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12} >
          <div className='overflow'>
            <table
              id="mytable"
              className="normaltxt table-responsive"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile No.</th>
                  <th>City</th>
                  <th>State</th>
                  {/* <th>Pin Code</th> */}
                  <th>Active/Inactive</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map(
                  (
                    details, ind) => (

                    <tr key={ind} >
                      <td >{details.supervisorName}</td>
                      <td >{details.supervisorEmailId}</td>
                      <td >{details.supervisorMobileNo}</td>
                      <td >{details.supervisorCity}</td>
                      <td >{details.supervisorState}</td>
                      {/* <td >{details.supervisorPinCode}</td> */}
                      <td>
                        <Switch checked={1 === details.active ? true : false}
                          onChange={() => UserActiveDeActive(details.supervisorId, details.active, ind)}
                        />
                      </td>
                      <td className="icons-wrap">
                        <IconButton
                          color="primary"
                          title='Edit'
                          onClick={() => handleEditPopup(details)}

                        >
                          <Edit />
                        </IconButton>
                      </td>
                    </tr>

                  )
                )}
              </tbody>
            </table>
          </div>

        </Grid>

      </Grid>






    </div>
  )
}

export default AdminSupervisorManagementContent