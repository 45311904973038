import React from 'react'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
import AdminMSReportsManagementContent from '../sections/AdminMarketScopeReportManagement/AdminMSReportsManagementContent'

const AdminMarketScopeReport = () => {
  return (
      <div>
          <AdminTopNavigation/>
          <AdminMSReportsManagementContent/>
      </div>
  )
}

export default AdminMarketScopeReport