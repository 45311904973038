import React from 'react'
import Dashboard from '../sections/Admin/Dashboard'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
const AdminDashboard = () => {
  return (
    <div>
      <AdminTopNavigation />
      <Dashboard/>
      </div>
  )
}

export default AdminDashboard