import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import Auth from '../../modules/Auth';
const AfterloginUserRoute = () => {
	/* start redux */
	// for get data from redux store

	let token = Auth.getToken();
	let userRole = Auth.getUserRole();
	return token && userRole === 1 ? <Outlet /> : <Navigate to="/Login" />;
};

export default AfterloginUserRoute;
