import React from 'react';
import SupervisorTopNavigation from '../layouts/SupervisorTopNavigation.js';
import DashboardContent from '../sections/Supervisor/Dashboard.js';

const SupervisorDashboard = () => {
	return <div>
		<SupervisorTopNavigation />
		<DashboardContent />		
		</div>;
};

export default SupervisorDashboard;
