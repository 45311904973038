const Investor_Type = [
	{ label: 'Individual', value: 'Individual' },
	{ label: 'Non Individual', value: 'Non Individual' }
];
const Call_Type = [{ label: 'New', value: 'New' }, { label: 'Follow-up', value: 'Follow-up' }];

const Business_Sourced_Expected = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }];

const Lumpsum_Sip = [{ label: 'Lumpsum', value: 'Lumpsum' }, { label: 'SIP', value: 'SIP' }];

const Status = [{ label: 'Open', value: 'Open' }, { label: 'Closed', value: 'Closed' }];

const Time_slot = [
	{ label: '12:00 AM to 12:30 AM', value: '12:00 AM to 12:30 AM' },
	{ label: '12:30 AM to 01:00 AM', value: '12:30 AM to 01:00 AM' },
	{ label: '01:00 AM to 01:30 AM', value: '01:00 AM to 01:30 AM' },
	{ label: '01:30 AM to 02:00 AM', value: '01:30 AM to 02:00 AM' },
	{ label: '02:00 AM to 02:30 AM', value: '02:00 AM to 02:30 AM' },
	{ label: '02:30 AM to 03:00 AM', value: '02:30 AM to 03:00 AM' },
	{ label: '03:00 AM to 03:30 AM', value: '03:00 AM to 03:30 AM' },
	{ label: '03:30 AM to 04:00 AM', value: '03:30 AM to 04:00 AM' },
	{ label: '04:00 AM to 04:30 AM', value: '04:00 AM to 04:30 AM' },
	{ label: '04:30 AM to 05:00 AM', value: '04:30 AM to 05:00 AM' },
	{ label: '05:00 AM to 05:30 AM', value: '05:00 AM to 05:30 AM' },
	{ label: '05:30 AM to 06:00 AM', value: '05:30 AM to 06:00 AM' },
	{ label: '06:00 AM to 06:30 AM', value: '06:00 AM to 06:30 AM' },
	{ label: '06:30 AM to 07:00 AM', value: '06:30 AM to 07:00 AM' },
	{ label: '07:00 AM to 07:30 AM', value: '07:00 AM to 07:30 AM' },
	{ label: '07:30 AM to 08:00 AM', value: '07:30 AM to 08:00 AM' },
	{ label: '08:00 AM to 08:30 AM', value: '08:00 AM to 08:30 AM' },
	{ label: '08:30 AM to 09:00 AM', value: '08:30 AM to 09:00 AM' },
	{ label: '09:00 AM to 09:30 AM', value: '09:00 AM to 09:30 AM' },
	{ label: '09:30 AM to 10:00 AM', value: '09:30 AM to 10:00 AM' },
	{ label: '10:00 AM to 10:30 AM', value: '10:00 AM to 10:30 AM' },
	{ label: '10:30 AM to 11:00 AM', value: '10:30 AM to 11:00 AM' },
	{ label: '11:00 AM to 11:30 AM', value: '11:00 AM to 11:30 AM' },
	{ label: '11:30 AM to 12:00 PM', value: '11:30 AM to 12:00 PM' },
	{ label: '12:00 PM to 12:30 PM', value: '12:00 PM to 12:30 PM' },
	{ label: '12:30 PM to 01:00 PM', value: '12:30 PM to 01:00 PM' },
	{ label: '01:00 PM to 01:30 PM', value: '01:00 PM to 01:30 PM' },
	{ label: '01:30 PM to 02:00 PM', value: '01:30 PM to 02:00 PM' },
	{ label: '02:00 PM to 02:30 PM', value: '02:00 PM to 02:30 PM' },
	{ label: '02:30 PM to 03:00 PM', value: '02:30 PM to 03:00 PM' },
	{ label: '03:00 PM to 03:30 PM', value: '03:00 PM to 03:30 PM' },
	{ label: '03:30 PM to 04:00 PM', value: '03:30 PM to 04:00 PM' },
	{ label: '04:00 PM to 04:30 PM', value: '04:00 PM to 04:30 PM' },
	{ label: '04:30 PM to 05:00 PM', value: '04:30 PM to 05:00 PM' },
	{ label: '05:00 PM to 05:30 PM', value: '05:00 PM to 05:30 PM' },
	{ label: '05:30 PM to 06:00 PM', value: '05:30 PM to 06:00 PM' },
	{ label: '06:00 PM to 06:30 PM', value: '06:00 PM to 06:30 PM' },
	{ label: '06:30 PM to 07:00 PM', value: '06:30 PM to 07:00 PM' },
	{ label: '07:00 PM to 07:30 PM', value: '07:00 PM to 07:30 PM' },
	{ label: '07:30 PM to 08:00 PM', value: '07:30 PM to 08:00 PM' },
	{ label: '08:00 PM to 08:30 PM', value: '08:00 PM to 08:30 PM' },
	{ label: '08:30 PM to 09:00 PM', value: '08:30 PM to 09:00 PM' },
	{ label: '09:00 PM to 09:30 PM', value: '09:00 PM to 09:30 PM' },
	{ label: '09:30 PM to 10:00 PM', value: '09:30 PM to 10:00 PM' },
	{ label: '10:00 PM to 10:30 PM', value: '10:00 PM to 10:30 PM' },
	{ label: '10:30 PM to 11:00 PM', value: '10:30 PM to 11:00 PM' },
	{ label: '11:00 PM to 11:30 PM', value: '11:00 PM to 11:30 PM' },
	{ label: '11:30 PM to 12:00 AM', value: '11:30 PM to 12:00 AM' }
];
const Type_Of_Fund = [
	{ label: "EQUITY", value: "EQUITY" },
	{ label: "DEBT", value: "DEBT" },
	{ label: "LIQUID", value: "LIQUID" },
	{ label: "GOLD", value: "GOLD" },
]
const ReviewType = [
	{ label: "Monthly", value: "Monthly" },
	{ label: "Six Monthly", value: "Six_Monthly" }
]
export { Investor_Type, Call_Type, Business_Sourced_Expected, Lumpsum_Sip, Status, Time_slot, Type_Of_Fund, ReviewType };
