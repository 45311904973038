// import axios from 'axios';
import BaseUrl from '../../modules/config/BaseUrl';
import axios from '../../modules/helpers/axiosInstance';
const AddUpdateMarketScopeDetails = async (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/AddUpdateMarketScopeDetails`,
		data: data
	});
};

const getallmarketscopedetails = async (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/GetMarketScopeDetailsByUserID?UserId=${UserId}`
	});
};
const getSectorDetails = async () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/GetKeySectorMasterDetails`
	});
};

const getNatureOfInvDetails = async () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/GetNatureInvMasterDetails`
	});
};
const getKeyIndustryDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/GetKeyIndustryDetailsByUserID?UserId=${userid}`
	});
};

const KeyIndDetailsAddAndUpdate = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/AddUpdateKeyIndustryDetails`,
		data: data
	});
};
const KeyIndDetailsDeleteById = (KeyIndustryId) => {
	return axios({
		method: 'Delete',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/DeleteKeyIndustryDetailsByID?KeyIndustryId=${KeyIndustryId}`
	});
};
const getCoOperBanksDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/CoOperativeBank/GetCoOperativeBankDetailsByUserID?UserId=${userid}`
	});
};
const CoOperBanksDetailsAddAndUpdate = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/CoOperativeBank/AddUpdateCoOperativeBankDetails`,
		data: data
	});
};
const CoOperBanksDetailsDeleteById = (bankId) => {
	return axios({
		method: 'Delete',
		url: `${BaseUrl.MainGateWayUrl}/CoOperativeBank/DeleteCoOperativeBankDetailsByID?BankId=${bankId}`
	});
};
const getCoOperSocietyDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/CoOperativeSociety/GetCoOperativeSocietyDetailsByUserID?UserId=${userid}`
	});
};
const CoOperSocietyDetailsAddAndUpdate = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/CoOperativeSociety/AddUpdateCoOperativeSocietyDetails`,
		data: data
	});
};
const CoOperSocietyDetailsDeleteById = (SocietyId) => {
	return axios({
		method: 'Delete',
		url: `${BaseUrl.MainGateWayUrl}/CoOperativeSociety/DeleteCoOperativeSocietyDetailsByID?SocietyId=${SocietyId}`
	});
};
const getFMCGsectorDetails = async () => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Fmcg/GetFmcgSectorMasterDetails`
	});
};
const gettopFMCGDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Fmcg/GetFmcgDetailsByUserID?UserId=${userid}`
	});
};
const FMCGDetailsAddAndUpdate = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Fmcg/AddUpdateFmcgDetails`,
		data: data
	});
};
const FMCGDetailsDeleteById = (FmcgId) => {
	return axios({
		method: 'Delete',
		url: `${BaseUrl.MainGateWayUrl}/Fmcg/DeleteFmcgDetailsByID?FmcgId=${FmcgId}`
	});
};
const getInfluencersmasterDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Influencer/GetInfluencerSectorMasterDetails`
	});
};
const gettopInfluencersDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Influencer/GetInfluencerDetailsByUserID?UserId=${userid}`
	});
};
const InfluencersDetailsAddAndUpdate = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Influencer/AddUpdateInfluencerDetails`,
		data: data
	});
};
const InfluencersDetailsDeleteById = (InfluencersId) => {
	return axios({
		method: 'Delete',
		url: `${BaseUrl.MainGateWayUrl}/Influencer/DeleteInfluencerDetailsByID?InfluencersId=${InfluencersId}`
	});
};
const gettopindassoDetails = async (userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/IndustryAssociation/GetIndustryAssociationDetailsByUserID?UserId=${userid}`
	});
};
const IndassoDetailsAddAndUpdate = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/IndustryAssociation/AddUpdateIndustryAssociationDetails`,
		data: data
	});
};
const IndassoDetailsDeleteById = (IndustryAssociationId) => {
	return axios({
		method: 'Delete',
		url: `${BaseUrl.MainGateWayUrl}/IndustryAssociation/DeleteIndustryAssociationDetailsByID?IndustryAssociationId=${IndustryAssociationId}`
	});
};

const MarketScopePoints = (data) => {
	return axios({
		method: 'Post',
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/FilterMSARNDetails`,
		data: data
	})
}

const sectorByCategory = (catId, userid) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/KeyIndustry/GetSectorByScope?id=${catId}&UserId=${userid}`
	});
};


export {
	/* key idustry */
	getSectorDetails,
	getNatureOfInvDetails,
	getKeyIndustryDetails,
	KeyIndDetailsAddAndUpdate,
	KeyIndDetailsDeleteById,
	/* co operative banks */
	getCoOperBanksDetails,
	CoOperBanksDetailsDeleteById,
	CoOperBanksDetailsAddAndUpdate,
	/* co operative society */
	getCoOperSocietyDetails,
	CoOperSocietyDetailsDeleteById,
	CoOperSocietyDetailsAddAndUpdate,
	/* top FMCG */
	getFMCGsectorDetails,
	gettopFMCGDetails,
	FMCGDetailsDeleteById,
	FMCGDetailsAddAndUpdate,
	/* top influencers in location */
	getInfluencersmasterDetails,
	gettopInfluencersDetails,
	InfluencersDetailsDeleteById,
	InfluencersDetailsAddAndUpdate,
	/*Top industry associtations  */
	gettopindassoDetails,
	IndassoDetailsDeleteById,
	IndassoDetailsAddAndUpdate,
	/*Main SubmitButton Api */
	AddUpdateMarketScopeDetails,
	getallmarketscopedetails,
	MarketScopePoints,
	sectorByCategory
};
