import React from 'react';
import DashboardContent from '../sections/Dashboard.js/DashboardContent';

const Dashboard = () => {
	return (
		<div>
			<DashboardContent />
		</div>
	);
};

export default Dashboard;
