import React, { useState, useEffect } from 'react';
import {
	getFMCGsectorDetails,
	getNatureOfInvDetails,
	gettopFMCGDetails,
	FMCGDetailsDeleteById,
	// FMCGDetailsAddAndUpdate
} from '../../Api/MarketScopeApi';
import $ from 'jquery';
import { IconButton, Typography, Grid, Button, Tooltip } from '@mui/material';
import { Edit, Done } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Auth from '../../../modules/Auth/index';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import LoadingScreen from '../../layouts/LoadingScreen';

const TopFMCG = ({ ChangeTabF, RecallFunctionF, SubmitF, childFuncF }) => {
	const UserId = Auth.getUserId();
	/* redux */
	const state = useSelector((state) => state.fmcgdata);
	const dispatch = useDispatch();
	const { settopfmcg, updatetopfmcg } = bindActionCreators(actionCreators, dispatch);
	/* end redux */
	const [data, setData] = useState([]);
	const [natureOfInv, setNatureOfInv] = useState([]);
	const [fmcgsector, setFmcgsector] = useState([]);
	const [loading, setLoading] = useState(false)
	const GetFMCGSectorDetails = () => {
		getFMCGsectorDetails()
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.sectorDetails;
					let FmcgsectorDetails = [];
					result &&
						result.map((f) =>
							FmcgsectorDetails.push({
								label: f.fmcgSectorName,
								value: f.fmcgSectorId
							})
						);
					setFmcgsector(FmcgsectorDetails);
				}
			})
			.catch((err) => {
				console.log(err, 'err in get nature inv details');
			});
	};
	const GetNatureInvDetails = () => {
		getNatureOfInvDetails()
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.investmentDetails;
					let NatureInvDetails = [];
					result &&
						result.map((f) =>
							NatureInvDetails.push({
								label: f.natureInvName,
								value: f.natureInvId
							})
						);
					setNatureOfInv(NatureInvDetails);
				}
			})
			.catch((err) => {
				console.log(err, 'err in get nature inv details');
			});
	};
	const GetTopFMCGDetails = () => {
		setLoading(true)
		gettopFMCGDetails(UserId)
			.then((res) => {
				if (res.data.response.status === 1) {
					let result = res.data.response.data.industryDetailByUserID;
					result.push({
						fmcgSectorId: '0',
						fmcgName: '',
						contactPerson: '',
						designation: '',
						address: '',
						district: '',
						pinCode: '',
						contactNumber: '',
						emailAddress: '',
						turnOver: '',
						natureInvId: '0',
						totalEmployees: '',
						edit: true,
						userId: UserId,
						fmcgId: 0,
						marketScopeId: 4
					});
					setData(result);
					settopfmcg(result);
				} else {
					let result = [];
					result.push({
						fmcgSectorId: '0',
						fmcgName: '',
						contactPerson: '',
						designation: '',
						address: '',
						district: '',
						pinCode: '',
						contactNumber: '',
						emailAddress: '',
						turnOver: '',
						natureInvId: '0',
						totalEmployees: '',
						edit: true,
						userId: UserId,
						fmcgId: 0,
						marketScopeId: 4
					});
					setData(result);
					settopfmcg(result);
				}
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				console.log(err, 'err in get fmcg details');
			});
	};
	useEffect(() => {
		childFuncF.topfmcg = GetTopFMCGDetails
		GetFMCGSectorDetails();
		GetNatureInvDetails();
		if (state.fmcgDetails.length === 0) {
			GetTopFMCGDetails();
		} else {
			setData(state.fmcgDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	$('.tData').one('keyup', function () {
		if ($(this).closest('tr').is(':last-child')) {
			let newArr = [...data];
			newArr.push({
				fmcgSectorId: '0',
				fmcgName: '',
				contactPerson: '',
				designation: '',
				address: '',
				district: '',
				pinCode: '',
				contactNumber: '',
				emailAddress: '',
				turnOver: '',
				natureInvId: '0',
				totalEmployees: '',
				edit: true,
				userId: UserId,
				fmcgId: 0,
				marketScopeId: 4
			});
			setData(newArr);
			settopfmcg(newArr);
		}
	});
	$('.drop_select').on('click', function () {
		if ($(this).closest('tr').is(':last-child')) {
			let newArr = [...data];
			newArr.push({
				fmcgSectorId: '0',
				fmcgName: '',
				contactPerson: '',
				designation: '',
				address: '',
				district: '',
				pinCode: '',
				contactNumber: '',
				emailAddress: '',
				turnOver: '',
				natureInvId: '0',
				totalEmployees: '',
				edit: true,
				userId: UserId,
				fmcgId: 0,
				marketScopeId: 4
			});
			setData(newArr);
			settopfmcg(newArr);
		}
	});
	// const handleSubmitAndUpdate = () => {
	// 	setLoading(true)
	// 	let editopen = true;
	// 	let fmcgdetailsdata = data.map((S, ind) => {
	// 		if (S.edit === true && data.length -1 !== ind) {
	// 			editopen = false
	// 		}
	// 		return {
	// 			fmcgSectorId:Number(S.fmcgSectorId),
	// 			fmcgName: S.fmcgName,
	// 			contactPerson: S.contactPerson,
	// 			designation: S.designation,
	// 			address: S.address,
	// 			district: S.district,
	//    		    pinCode:Number(S.pinCode),
	// 			contactNumber: S.contactNumber,
	// 			emailAddress: S.emailAddress,
	// 			turnOver: Number(S.turnOver),
	// 			natureInvId: Number(S.natureInvId),
	// 			totalEmployees: Number(S.totalEmployees),
	// 			userId: S.userId,
	// 			fmcgId: S.fmcgId,
	// 			marketScopeId: S.marketScopeId
	// 		};
	// 	});
	// 	if (editopen) {
	// 		fmcgdetailsdata.pop();
	// 		FMCGDetailsAddAndUpdate(fmcgdetailsdata)
	// 			.then((res) => {
	// 				if (res) {
	// 					Swal.fire({
	// 						icon: 'success',
	// 						title: 'Details saved successfully',
	// 						confirmButtonText: 'Ok'
	// 					}).then((result) => {
	// 						GetTopFMCGDetails();
	// 						RecallFunctionF();
	// 					});
	// 				}
	// 				setLoading(false)
	// 			})
	// 			.catch((err) => {
	// 				setLoading(false)
	// 				console.log(err, 'err in add and update');
	// 			});
	// 	} else {
	// 		setLoading(false)
	// 		Swal.fire('Record is in still edit mode','','warning')
	// 		  }
	// };
	const handleChange = (e, idx) => {
		const { name, value } = e.target;
		let values = name === "turnOver" ? Number(value) <= 0 ? 0 : value : value;
		let newArr = [...data];
		newArr[idx][name] = values;
		setData(newArr);
		updatetopfmcg(newArr);
	};
	const handleSectorchange = (e, idx) => {
		let selected = e.target.value;
		let newArr = [...data];
		let name = 'fmcgSectorId';
		newArr[idx][name] = selected;
		setData(newArr);
		updatetopfmcg(newArr);

	};
	const handleNatureInvchange = (e, idx) => {
		let selected = e.target.value;
		let newArr = [...data];
		let name = 'natureInvId';
		newArr[idx][name] = selected;
		setData(newArr);
		updatetopfmcg(newArr);
	};
	const handleEditchange = (value, idx) => {
		const isvalid = value === true ? handleValidate(idx) : true
		if (isvalid) {
			let newArr = [...data];
			let name = 'edit';
			newArr[idx][name] = !value;
			setData(newArr);
			updatetopfmcg(newArr);
		}
	};
	const handleDeleteByFMCGId = (ind, tfmcgid) => {
		Swal.fire({
			icon: 'warning',
			title: 'Are you sure you want to delete this record?',
			confirmButtonText: 'Yes',
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true)
				if (tfmcgid !== 0) {
					FMCGDetailsDeleteById(tfmcgid)
						.then((res) => {
							if (res.data.response.status === 1) {
								console.log(res);
								let newArr = [...data];
								newArr.splice(ind, 1);
								setData(newArr);
								updatetopfmcg(newArr);
								Swal.fire({
									icon: 'success',
									title: 'Record Delete Successfully',
									confirmButtonText: 'Ok'
								}).then((result) => {
									RecallFunctionF();
								})
							}
							setLoading(false)
						})
						.catch((err) => {
							setLoading(false)
							console.log(err, 'err in delete');
						});
				} else {
					let newArr = [...data];
					newArr.splice(ind, 1);
					setData(newArr);
					updatetopfmcg(newArr);
					setLoading(false)
					Swal.fire('Record Delete Successfully', '', 'success');
				}
			}
		});
	};
	const handleValidate = (idx) => {
		const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
		const letters = /^[aA-zZ,.'"\s]+$/;
		const pincoderegex = /^(\d{6})$/;
		let valid = true;
		let validate = [...data];
		let fmcgSectorId = "fmcgSectorId"
		let fmcgName = "fmcgName"
		let contactPerson = "contactPerson"
		let designation = "designation"
		let address = "address"
		let district = "district"
		let pinCode = "pinCode"
		let contactNumber = "contactNumber"
		let emailAddress = "emailAddress"
		let turnOver = "turnOver"
		let natureInvId = "natureInvId"
		let totalEmployees = "totalEmployees"
		if (Number(validate[idx][fmcgSectorId]) === 0) {
			toast.error("Please select FMCG sector", { theme: "colored" });
			valid = false
		}
		if (validate[idx][fmcgName].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter FMCG name", { theme: "colored" });
			valid = false
		}
		if (validate[idx][contactPerson].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter contact person name", { theme: "colored" });
			valid = false
		} else if (!letters.test(validate[idx][contactPerson])) {
			toast.error("Only alphabets are allowed for contact person field", { theme: "colored" });
			valid = false
		}
		if (validate[idx][designation].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter designation", { theme: "colored" });
			valid = false
		}
		if (validate[idx][address].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter address", { theme: "colored" });
			valid = false
		}
		if (validate[idx][district] !== null) {
			if (validate[idx][district].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
				toast.error("Please enter district", { theme: "colored" });
				valid = false
			}
		} else if (validate[idx][district] === null) {
			toast.error("Please enter district", { theme: "colored" });
			valid = false
		}
		if (validate[idx][pinCode] === "") {
			toast.error("Please enter pincode", { theme: "colored" });
			valid = false
		} else if (pincoderegex.test(Number(validate[idx][pinCode])) === false) {
			toast.error("Invalid pincode", { theme: "colored" });
			valid = false
		}
		if (validate[idx][contactNumber] === "") {
			toast.error("Please enter contact number", { theme: "colored" });
			valid = false
		} else if (validate[idx][contactNumber].length >= 13) {
			toast.error("Contact number is not more than 12 digit", { theme: "colored" });
			valid = false
		}
		// else if (phoneregex.test(Number(validate[idx][contactNumber])) === false) {
		// 	toast.error("Invalid contact number", { theme: "colored" });
		// 	valid = false
		// }
		if (validate[idx][emailAddress].trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === "") {
			toast.error("Please enter email", { theme: "colored" });
			valid = false
		} else if (emailregex.test(validate[idx][emailAddress]) === false) {
			toast.error("Invalid email", { theme: "colored" });
			valid = false
		}
		if (validate[idx][turnOver] === "") {
			toast.error("Please enter turn over", { theme: "colored" });
			valid = false
		}
		if (Number(validate[idx][natureInvId]) === 0) {
			toast.error("Please select nature of investment", { theme: "colored" });
			valid = false
		}
		if (validate[idx][totalEmployees] === "") {
			toast.error("Please enter total employees", { theme: "colored" });
			valid = false
		}
		return valid
	}
	return (
		<div>
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{loading === true ? <LoadingScreen /> : null}
			<Grid container spacing={0} padding={0} rowSpacing={1.5} paddingLeft={'80px'}>
				<Grid item xs={12} alignContent="flex-start">
					<Typography variant='h4' className='sub-heading'>
						Top FMCG
					</Typography>

				</Grid>
				<Grid item xs={12} md={12}>
					<div className='overflow'>
						<table
							id="mytable"
							className="normaltxt table-responsive"
							style={{ width: "100%" }}
						>
							<tbody>
								<tr>
									<th>FMCG Sector</th>
									<th>FMCG Distributor Name</th>
									<th>Contact Person</th>
									<th>Designation</th>
									<th>Address</th>
									<th>District</th>
									<th>Pin Code</th>
									<th>Contact no.</th>
									<th>Email</th>
									<th>FD Amount</th>
									<th>Nature of Investment</th>
									<th>Total Employees</th>
									<th>Action</th>
								</tr>
								{data &&
									data.map(
										(
											{
												fmcgSectorId,
												fmcgName,
												contactPerson,
												designation,
												address,
												district,
												pinCode,
												contactNumber,
												emailAddress,
												turnOver,
												natureInvId,
												totalEmployees,
												fmcgId,
												edit,
											},
											ind
										) => (
											<tr key={ind}>
												<td style={{ width: "10%" }}>
													{edit === false ? (
														<>
															{" "}
															{fmcgsector &&
																fmcgsector.map((f, i) => {
																	if (f.value === Number(fmcgSectorId)) {
																		return (
																			<Typography key={i}>{f.label}</Typography>
																		);
																	}
																	return null;
																})}{" "}
														</>
													) : (
														<select
															className="market_txtfield drop_select"
															value={fmcgSectorId}
															onChange={(selected) =>
																handleSectorchange(selected, ind)
															}
														>
															<option value={0} disabled>
																Select FMCG Sector
															</option>
															{fmcgsector &&
																fmcgsector.map((x, indx) => {
																	return (
																		<option key={indx} value={x.value}>
																			{x.label}
																		</option>
																	);
																})}
														</select>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{fmcgName}</Typography>
													) : (
														<input
															name="fmcgName"
															className="market_txtfield tData"
															value={fmcgName}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{contactPerson}</Typography>
													) : (
														<input
															name="contactPerson"
															className="market_txtfield tData"
															value={contactPerson}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{designation}</Typography>
													) : (
														<input
															name="designation"
															className="market_txtfield tData"
															value={designation}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{address}</Typography>
													) : (
														<input
															name="address"
															className="market_txtfield_email tData"
															value={address}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{district}</Typography>
													) : (
														<input
															name="district"
															className="market_txtfield tData"
															value={district}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{pinCode}</Typography>
													) : (
														<input
															name="pinCode"
															type="Number"
															className="market_txtfield_pincode tData"
															value={pinCode}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{contactNumber}</Typography>
													) : (
														<input
															type="Number"
															name="contactNumber"
															className="market_txtfield tData"
															value={contactNumber}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{emailAddress}</Typography>
													) : (
														<input
															type="email"
															name="emailAddress"
															className="market_txtfield_email tData"
															value={emailAddress}
															onChange={(e) => handleChange(e, ind)}

														/>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{`₹${new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
															turnOver
														)}`}</Typography>
													) : (
														<input
															name="turnOver"
															type="Number"
															className="market_txtfield tData"
															placeholder='Amount(in crores)'
															value={turnOver}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td className="tData" style={{ width: "10%" }}>
													{edit === false ? (
														<>
															{" "}
															{natureOfInv &&
																natureOfInv.map((f, i) => {
																	if (f.value === Number(natureInvId)) {
																		return (
																			<Typography key={i}>{f.label}</Typography>
																		);
																	}
																	return null;
																})}{" "}
														</>
													) : (
														<select
															className="market_txtfield drop_select"
															value={natureInvId}
															onChange={(selected) =>
																handleNatureInvchange(selected, ind)
															}
														>
															<option value={0}>Select</option>
															{natureOfInv &&
																natureOfInv.map((x, indx) => {
																	return (
																		<option key={indx} value={x.value}>
																			{x.label}
																		</option>
																	);
																})}
														</select>
													)}
												</td>
												<td>
													{edit === false ? (
														<Typography>{totalEmployees}</Typography>
													) : (
														<input
															name="totalEmployees"
															className="market_txtfield tData"
															type="Number"
															value={totalEmployees}
															onChange={(e) => handleChange(e, ind)}
														/>
													)}
												</td>
												<td className='icons-wrap'>
													<Tooltip title={edit === false ? "Edit" : "Save"}>
														<IconButton
															aria-label="edit"
															color="primary"
															onClick={() => handleEditchange(edit, ind)}
															disabled={ind === data.length - 1}
														>
															{edit === false ? <Edit /> : <Done />}
														</IconButton>
													</Tooltip>
													<Tooltip title="Delete">
														<IconButton
															aria-label="delete"
															color="error"
															onClick={() =>
																handleDeleteByFMCGId(ind, fmcgId)
															}
															disabled={ind === data.length - 1}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</Tooltip>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</div>
					<div className='p-t-5'>
						<IconButton variant="contained" onClick={() => ChangeTabF(3)} size="large"><NavigateBeforeIcon /></IconButton>
						<Button onClick={() => SubmitF(4)} variant="contained" className='btn-blue'>Save</Button>
						<IconButton variant="contained" onClick={() => ChangeTabF(5)} size="large"><NavigateNextIcon /></IconButton>
					</div>
				</Grid>
			</Grid>


		</div>
	);
};

export default TopFMCG;


