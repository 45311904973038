import FileSaver from 'file-saver';
import ExcelJS from 'exceljs/dist/exceljs.min.js';
import moment from 'moment';
const exportExcel = async (filename, columns, data, condition) => {
    // Create Excel workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet');

    // Define columns in the worksheet, these columns are identified using a key.
    worksheet.columns = columns

    // Add rows from database to worksheet 
    for (const row of data) {
        worksheet.addRow(row);
    }
    worksheet.getRow(1).font = {
        name: 'Arial', family: 4, size: 12, bold: true,
    };
    if (condition === 'Agent') {
        worksheet.getColumn(3).numFmt = '#,##0.00;[Red]#,##0.00';
        worksheet.getColumn(4).numFmt = '#,##0.00;[Red]#,##0.00';
        worksheet.getColumn(5).numFmt = '#,##0.00;[Red]#,##0.00';
        worksheet.getColumn(6).numFmt = '#,##0.00;[Red]#,##0.00';
    }
    worksheet.eachRow(function (row, rowNumber) {

        row.eachCell((cell, colNumber) => {
            if (rowNumber === 1) {
                // First set the background of header row
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5b914' }
                }
            }
            if (condition === 'msreport' && colNumber === 9 && rowNumber !== 1) {
                cell.value = moment(new Date(cell.value)).format('DD-MM-yyyy');
            }
            if (condition === 'msreport' && colNumber === 18 && rowNumber !== 1) {
                cell.value = cell.value === 1 ? "false" : "true"
            }
            // Set border of each cell 
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        })
        //Commit the changed row to the stream
        row.commit();
    });
    const buf = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buf], { type: 'application/vnd.ms-excel' });
    FileSaver.saveAs(blob, `${filename}.xlsx`);
}
export {
    exportExcel
}
