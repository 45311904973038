import React, { useState, useEffect } from 'react';

//redux
// import { useSelector, useDispatch } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { actionCreators } from '../../../redux';

import {
	Paper,
	Typography,
	TextField,
	Grid,
	FormControl,
	InputAdornment,
	IconButton,
	InputLabel,
	OutlinedInput,
	Container,
	Button
} from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import nippon_logo from '../../../assets/images/nipponindia-mutual-fund-logo.png';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import Cleave from 'cleave.js/react';

import axios from 'axios';
import BaseUrl from '../../../modules/config/BaseUrl';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tab-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tab-${index}`
	};
}


const LoginContent = () => {
	/* start redux */
	// const state = useSelector((state) => state); // for get data from redux store
	// console.log(state);

	// const dispatch = useDispatch(); // for store data into redux store
	// const { loginsuccess, loginfail } = bindActionCreators(actionCreators, dispatch);
	/* end redux */
	const Navigate = useNavigate();
	const [password, setPassword] = useState(false);
	const [isverified, setIsverified] = useState(false);
	// Sign up states
	const [arn, setArn] = useState('');
	const [pass, setPass] = useState('');
	const [arnErr, setArnErr] = useState('');
	const [passErr, setpassErr] = useState('');
	const [captchaErr, setcaptchaErr] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingotp, setLoadingotp] = useState(false);
	const [memberloadingotp, setMemberloadingotp] = useState(false);
	const [loadingverifyotp, setLoadingverifyotp] = useState(false);
	const [memberloadingverifyotp, setmemberLoadingverifyotp] = useState(false);
	const [showotpmodal, setShowotpmodal] = useState(false);
	// const [refernceId, setRefernceId] = useState("");
	const [otp, setOtp] = useState("");
	const [showmemberotpmodal, setShowmemberotpmodal] = useState(false);
	// const [memberrefernceId, setMemberrefernceId] = useState("");
	const [memberotp, setMemberotp] = useState("");
	const [email, setEmail] = useState('');
	const [emailerr, setEmailerr] = useState('');
	const [memberisverified, setMemberisverified] = useState(false);
	const [membercaptchaerr, setMembercaptchaerr] = useState('');
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	/* otp timer */

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, [seconds]);
	const NavigateRegister = () => {
		Navigate('/register');
	};
	const handleClickShowPassword = () => {
		setPassword(!password);
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	// sign up onchange handlers
	const handleChangeLog = (e) => {
		const { name, value } = e.target;
		if (name === 'ARN Code') {
			setArn(value);
			setArnErr('');
		}
		if (name === 'pass') {
			setPass(value);
			setpassErr('');
		}
	};

	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
		setArn('');
		setPass('');
		setEmail('');
	};
	const validation = () => {
		// const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		// const passregex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
		let arnErr = {};
		let passErr = {};
		let capErr = {};
		let isValid = true;
		if (arn === '' || arn === null) {
			arnErr.arnerr = 'Please enter ARN code';
			isValid = false;
		} else if (arn.length !== 10) {
			arnErr.arnerr = 'Invalid ARN Code';
			isValid = false;
		}
		// else if (emailregex.test(arn) === false) {
		// 	emailErr.arnerr = 'please enter valid arn';
		// 	isValid = false;
		// }
		if (pass === '' || pass === null || !pass) {
			passErr.passederr = 'Please enter your password';
			isValid = false;
		} 
		// else if (passregex.test(pass) === false) {
		// 	passErr.passederr = 'Incorrect ARN Code or Password';
		// 	//'Password must combination of Number,Capital letter, special character and min length is 8 !!';
		// 	isValid = false;
		// }
		if (isverified === false) {
			capErr.caperr = 'Please click on the captcha field';
			isValid = false;
		}
		setArnErr(arnErr);
		setpassErr(passErr);
		setcaptchaErr(capErr);
		return isValid;
	};
	const validationotp = () => {
		// const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let arnErr = {};
		let capErr = {};
		let isValid = true;
		if (arn === '' || arn === null) {
			arnErr.arnerr = 'Please enter ARN code';
			isValid = false;
		} else if (arn.length !== 10) {
			arnErr.arnerr = 'Invalid ARN Code';
			isValid = false;
		}
		if (isverified === false) {
			capErr.caperr = 'Please click on the captcha field';
			isValid = false;
		}
		setArnErr(arnErr);
		setcaptchaErr(capErr);
		return isValid;
	};
	const LocationPermission = (button) => {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				// console.log('Latitude is :', position.coords.latitude);
				// console.log('Longitude is :', position.coords.longitude);
				if (button === 'login') {
					handleSubmitLogin(position.coords.latitude, position.coords.longitude)
				} else if (button === 'otp') {
					LoginVerifyOtp(position.coords.latitude, position.coords.longitude)
				}
			}
			,
			function (err) {
				if (button === 'login') {
					handleSubmitLogin(0, 0)
				} else if (button === 'otp') {
					LoginVerifyOtp(0, 0)
				}
			}
		);
	}
	const handleSubmitLogin = async (latitude, longitude) => {
		const isValid = validation();
		if (isValid) {
			let body = {
				userArnCode: arn,
				userPassword: pass,
				latitude: latitude,
				longitude: longitude,
				date: new Date()
			};
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setLoading(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/Authenticate`, body, headers)
				.then((res) => {
					if (res.status === 200) {
						// setLoading(false);
						const data = res.data.authenticationResponse;
						if (res.data.authenticationResponse.status === 1) {
							// window.location.href = "/";
							// if (data.subcriberType === 1) {
							// 	window.location.href = '/token';
							// } else if (data.isTwoFaenabled === true) {
							// 	window.location.href = '/Auth';
							// } else {
							// 	window.location.href = '/dashboard';
							// }

							const userDetails = {
								emailId: data.userEmailID,
								userRole: data.role_ID,
								userType: data.role_Name,
								token: data.token,
								userId: data.userID,
								userName: data.userName
								// loginType: "1",
								// twofactorEnabled: data.isTwoFaenabled,
								// twoFaAuth: false,
							};
							localStorage.setItem('User', JSON.stringify(userDetails));
							setLoading(false);
							window.location.href = '/dashboard';
							// loginsuccess(userDetails);
						} else {
							setLoading(false);
							// loginfail({});

							Swal.fire(res.data.authenticationResponse.message, '', 'info');
						}
					}
				})
				.catch((err) => {
					setLoading(false);
					// loginfail({});
					if (err.response.data !== undefined) {
						if (err.response.data.authenticationResponse.message === 'Account not verified') {
							Swal.fire(
								err.response.data.authenticationResponse.message,
								'Please verify your email and mobile number, before login',
								'info'
							);
						} else {
							Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
						}
					} else {
						console.log('err in registration', err);
					}
				});
		}


	};
	const OtpLogin = async () => {
		let isvalid = validationotp();
		if (isvalid) {
			let body = {
				userArnCode: arn
			}
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setLoadingotp(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/LoginOTP`, body, headers)
				.then((res) => {
					if (res.status === 200) {
						setLoadingotp(false);
						if (res.data.authenticationResponse.status === 1) {
							// setRefernceId(res.data.authenticationResponse.refNo);
							setShowotpmodal(true);
							setMinutes(1);
							setSeconds(30);
						} else {
							Swal.fire(res.data.authenticationResponse.message, '', 'info');
						}
					}
				})
				.catch((err) => {
					setLoadingotp(false);
					if (err.response.data !== undefined) {
						if (err.response.data.authenticationResponse.message === 'Account not verified') {
							Swal.fire(
								err.response.data.authenticationResponse.message,
								'Please verify your email before login',
								'info'
							);
						} else {
							Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
						}
					} else {
						console.log('err in registration', err);
					}
				});
		}
	};
	const LoginVerifyOtp = async (latitude, longitude) => {

		if (otp !== "") {
			let body = {
				userArnCode: arn,
				otp: otp,
				latitude: latitude,
				longitude: longitude,
				date: new Date()
			}
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setLoadingverifyotp(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/VerifyOTPLogin`, body, headers)
				.then((res) => {
					if (res.status === 200) {
						setLoadingverifyotp(false);
						const data = res.data.authenticationResponse;
						if (res.data.authenticationResponse.status === 1) {
							// window.location.href = "/";
							// if (data.subcriberType === 1) {
							// 	window.location.href = '/token';
							// } else if (data.isTwoFaenabled === true) {
							// 	window.location.href = '/Auth';
							// } else {
							// 	window.location.href = '/dashboard';
							// }

							const userDetails = {
								emailId: data.userEmailID,
								userRole: data.role_ID,
								userType: data.role_Name,
								token: data.token,
								userId: data.userID,
								userName: data.userName
								// loginType: "1",
								// twofactorEnabled: data.isTwoFaenabled,
								// twoFaAuth: false,
							};
							localStorage.setItem('User', JSON.stringify(userDetails));
							window.location.href = '/dashboard';
							// loginsuccess(userDetails);
						} else {
							setLoadingverifyotp(false);
							// loginfail({});

							Swal.fire("OTP is incorrect", '', 'error');
						}
					}
				})
				.catch((err) => {
					setLoadingverifyotp(false);
					if (err.response.data !== undefined) {
						if (err.response.data.authenticationResponse.message === 'Account not verified') {
							Swal.fire(
								err.response.data.authenticationResponse.message,
								'Please verify your email before login',
								'info'
							);
						} else {
							Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
						}
					} else {
						console.log('err in registration', err);
					}
				});
		}
	};
	const LoginResendOtp = async () => {
		setMinutes(1);
		setSeconds(30);
		let body = {
			userArnCode: arn
		}
		let headers = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		axios
			.post(`${BaseUrl.MainGateWayUrl}/Authentication/LoginOTP`, body, headers)
			.then((res) => {
				if (res.status === 200) {
					if (res.data.authenticationResponse.status === 1) {
						Swal.fire(" Verification OTP sent on your mobile number", '', 'success');
						// setRefernceId(res.data.authenticationResponse.refNo);
					} else {
						Swal.fire(res.data.authenticationResponse.message, '', 'info');
					}
				}
			})
			.catch((err) => {
				Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
				console.log('err in registration', err);
			});

	};
	const validationmemberotp = () => {
		const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let emailerr = {};
		let caperr = {};
		let isvalid = true;
		if (email === '' || email === null) {
			emailerr.emailErr = 'Please enter email address';
			isvalid = false;
		} else if (emailregex.test(email) === false) {
			emailerr.emailErr = 'please enter valid email address';
			isvalid = false;
		}
		if (memberisverified === false) {
			caperr.capErr = 'Please click on the captcha field';
			isvalid = false;
		}
		setEmailerr(emailerr);
		setMembercaptchaerr(caperr);
		return isvalid;
	}
	const MemberOtpLogin = async () => {
		let isvalid = validationmemberotp();
		if (isvalid) {
			let body = {
				emailId: email
			}
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setMemberloadingotp(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/SupervisorAdminLoginOTP`, body, headers)
				.then((res) => {
					if (res.status === 200) {
						setMemberloadingotp(false);
						if (res.data.authenticationResponse.status === 1) {
							// setMemberrefernceId(res.data.authenticationResponse.refNo);
							setShowmemberotpmodal(true);
							setMinutes(1);
							setSeconds(30);
						} else {
							Swal.fire(res.data.authenticationResponse.message, '', 'info');
						}
					}
				})
				.catch((err) => {
					setMemberloadingotp(false);
					if (err.response.data !== undefined) {
						if (err.response.data.authenticationResponse.message === 'Account not verified') {
							Swal.fire(
								err.response.data.authenticationResponse.message,
								'Please verify your email first before login',
								'info'
							);
						} else {
							Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
						}
					} else {
						console.log('err in registration', err);
					}
				});
		}
	}
	const MemberLoginVerifyOtp = async () => {

		if (memberotp !== "") {
			let body = {
				emailId: email,
				otp: memberotp,
				// refno: memberrefernceId
			}
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setmemberLoadingverifyotp(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/SupervisorAdminVerifyOTPLogin`, body, headers)
				.then((res) => {
					if (res.status === 200) {
						setmemberLoadingverifyotp(false);
						const data = res.data.authenticationResponse;
						if (res.data.authenticationResponse.status === 1) {
							// window.location.href = "/";
							// if (data.subcriberType === 1) {
							// 	window.location.href = '/token';
							// } else if (data.isTwoFaenabled === true) {
							// 	window.location.href = '/Auth';
							// } else {
							// 	window.location.href = '/dashboard';
							// }

							const userDetails = {
								emailId: data.userEmailID,
								userRole: data.role_ID,
								userType: data.role_Name,
								token: data.token,
								userId: data.userID,
								userName: data.userName
								// loginType: "1",
								// twofactorEnabled: data.isTwoFaenabled,
								// twoFaAuth: false,
							};
							localStorage.setItem('User', JSON.stringify(userDetails));
							window.location.href = data.role_ID === 2 ? '/admindashboard' : '/supervisordashboard';
							// loginsuccess(userDetails);
						} else {
							setmemberLoadingverifyotp(false);
							// loginfail({});

							Swal.fire("OTP is incorrect", '', 'error');
						}
					}
				})
				.catch((err) => {
					setmemberLoadingverifyotp(false);
					if (err.response.data !== undefined) {
						if (err.response.data.authenticationResponse.message === 'Account not verified') {
							Swal.fire(
								err.response.data.authenticationResponse.message,
								'Please verify your email  before login',
								'info'
							);
						} else {
							Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
						}
					} else {
						console.log('err in registration', err);
					}
				});
		}
	};
	const MemberLoginResendOtp = async () => {
		setMinutes(1);
		setSeconds(30);
		let body = {
			emailId: email
		}
		let headers = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		axios
			.post(`${BaseUrl.MainGateWayUrl}/Authentication/SupervisorAdminLoginOTP`, body, headers)
			.then((res) => {
				if (res.status === 200) {
					if (res.data.authenticationResponse.status === 1) {
						Swal.fire("Login OTP sent on your mobile number", '', 'success');
						// setMemberrefernceId(res.data.authenticationResponse.refNo);
					} else {
						Swal.fire(res.data.authenticationResponse.message, '', 'info');
					}
				}
			})
			.catch((err) => {
				Swal.fire(err.response.data.authenticationResponse.message, '', 'info');
				console.log('err in login', err);
			});

	};
	const handleClose = () => {
		// setRefernceId("");
		setShowotpmodal(false);
		// setMemberrefernceId("");
		setShowmemberotpmodal(false);
		setMinutes(0);
		setSeconds(0);
	}
	function onChange(value) {
		// console.log('Captcha value:', value);
		if (value !== null) {
			setIsverified(true);
		}
		setcaptchaErr('');
	}
	function onMemberChange(value) {
		// console.log('Captcha value:', value);
		if (value !== null) {
			setMemberisverified(true);
		}
		setMembercaptchaerr('');
	}
	const forgetpop = async () => {
		await Swal.fire({
			title: 'Forgot Password',
			input: 'text',
			inputLabel: 'Your email address',
			inputPlaceholder: 'Enter your email address',
			showCancelButton: true,
			inputAttributes: {
				autocapitalize: 'off'
			},
			confirmButtonText: 'Submit',
			preConfirm: async (login) => {
				let valid = true;
				const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (login === '') {
					Swal.showValidationMessage(`Please Enter Email`);
					valid = false;
				} else {
					if (!emailregex.test(login)) {
						Swal.showValidationMessage(`Please Enter Valid Email`);
						valid = false;
					}
				}
				if (valid === true) {
					let data = { userEmailId: login };
					let headers = {
						headers: {
							'Content-Type': 'application/json'
						}
					};
					return axios
						.post(`${BaseUrl.MainGateWayUrl}/Authentication/forgot-password`, data, headers)
						.then((res) => {

							if (res.status === 200) {
								if (res.data.authenticationResponse.status === 1) {
									return res.data.authenticationResponse.message;
									//
								} else {
									throw new Error('Wrong details');
								}
							} else {
								throw new Error('Wrong details');
							}
						})
						.catch((e) => {
							Swal.showValidationMessage(`Request failed: Wrong details`);
							console.log(e, 'err in forgot password');
						});
				}
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
				// console.log(result.value);
				Swal.fire('Forgot password link sent to your registered email', '', 'success');
			}
		});
	};

	const grecaptchaObject = window.grecaptcha;

	return (
		<div className="container-fluid login-bg">
			<Container>
				<Grid className="logo-img">
					<img src={nippon_logo} alt="Nippon India" />
				</Grid>
				<Grid sx={{ m: 2 }}>
					<Paper className="login-box" elevation={5}>
						{/* <Grid container spacing={0}>
							<Grid item xs={12} md={12} marginLeft={6} marginBottom={0}>
							<h3 className="label-heading-login">Login</h3>
							</Grid>
						</Grid> */}
						<Tabs
							variant="scrollable"
							centered={true}
							value={value}

							onChange={handleChange}
							aria-label="Vertical tabs example"
						>
							<Tab label="ARN LOGIN" {...a11yProps(0)} className={'label-heading-login'} />
							<Tab label="Member Login" {...a11yProps(1)} />
						</Tabs>

						<TabPanel value={value} index={0} >
							<form>
								<Grid container spacing={1.5} marginTop={0}>


									<Grid item xs={12} md={12}>
										<FormControl >
											{/* <TextField
											variant="outlined"
											className="txt-field-login"
											color="primary"
											size="small"
											type="text"
											placeholder="Enter Your User Id"
											label="ARN Code"
											name="ARN_Code"
											onChange={handleChangeLog}
										/> */}

											<Cleave
												name="ARN Code"
												className="txt-field-login1"
												placeholder="Enter your ARN number"
												options={{
													prefix: "ARN-",
													blocks: [10],
													uppercase: true,
													numericOnly: true
												}}
												value={arn}
												onChange={handleChangeLog}
											/>
											{Object.keys(arnErr).map((key) => {
												return <div style={{ fontSize: 12, color: 'red' }}>{arnErr[key]}</div>;
											})}
										</FormControl>
									</Grid>
									<Grid item xs={12} md={12} marginTop={1}>
										<FormControl
											variant="outlined" size='small'

										>
											<InputLabel htmlFor="outlined-adornment-password" className='m-label' >
												Password
											</InputLabel>
											<OutlinedInput
												variant="outlined"
												size="small"
												className="txt-field-login"
												type={password ? 'text' : 'password'}
												autoComplete="on"
												name="pass"
												placeholder='Enter your password'
												onChange={handleChangeLog}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge="end"
														>
															{password ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												label="Password"
											/>
											{Object.keys(passErr).map((key) => {
												return <div style={{ fontSize: 12, color: 'red' }}>{passErr[key]}</div>;
											})}
										</FormControl>
									</Grid>
									<Grid item xs={12} md={12} marginTop={.5}>
										<FormControl >
											<ReCAPTCHA
												onExpired={() => setIsverified(false)}
												grecaptcha={grecaptchaObject}
												data-type="image"
												className='captcha'
												data-theme="dark"
												sitekey="6LcW-r0gAAAAACqQhpduFZJ_4h5MXahnwNBmPF0O"
												onChange={onChange}
											/>
										</FormControl>
										{Object.keys(captchaErr).map((key) => {
											return <div style={{ fontSize: 12, color: 'red' }}>{captchaErr[key]}</div>;
										})}
									</Grid>
									<Grid item xs={12} md={12} marginTop={1}>
										<LoadingButton
											sx={{}}
											size="large"
											variant="contained"
											className="btn-login"

											onClick={() => LocationPermission('login')}
											loading={loading}
											loadingPosition="start"
										>
											Login
										</LoadingButton>
										<div style={{ padding: '10px' }}>OR</div>
										<LoadingButton
											// sx={{ }}
											size="large"
											variant="contained"
											className="btn-request"
											// startIcon={<LoginIcon />}
											onClick={OtpLogin}
											loading={loadingotp}
											loadingPosition="start"
										>
											Request OTP
										</LoadingButton>
									</Grid>
								</Grid>

								<Typography className="normaltxt" sx={{ m: 2 }}>
									Don’t have an account, yet? <span onClick={() => NavigateRegister()}>Sign up here</span>
								</Typography>
								<Typography className="normaltxt" onClick={forgetpop}>
									<span>Forgot your password?</span>
								</Typography>
							</form>
						</TabPanel>
						<TabPanel value={value} index={1}  >
							<form>
								<Grid container spacing={1.5} marginTop={0}>
									<Grid item xs={12} md={12}>
										<TextField
											variant="outlined"
											className="txt-field-login"
											color="primary"
											size="small"
											type="text"
											placeholder="Enter Your Email"
											label="Email"
											name="Email"
											value={email}
											onChange={(e) => {
												setEmail(e.target.value);
												setEmailerr({});
											}}
										/>
										{Object.keys(emailerr).map((key) => {
											return <div style={{ fontSize: 12, color: 'red' }}>{emailerr[key]}</div>;
										})}
									</Grid>
									<Grid item xs={12} md={12} marginTop={.5}>
										<FormControl >
											<ReCAPTCHA
												onExpired={() => setMemberisverified(false)}
												grecaptcha={grecaptchaObject}
												data-type="image"
												className='captcha'
												data-theme="dark"
												sitekey="6LcW-r0gAAAAACqQhpduFZJ_4h5MXahnwNBmPF0O"
												onChange={onMemberChange}
											/>
										</FormControl>
										{Object.keys(membercaptchaerr).map((key) => {
											return <div style={{ fontSize: 12, color: 'red' }}>{membercaptchaerr[key]}</div>;
										})}
									</Grid>
									<Grid item xs={12} md={12} marginTop={1}>
										<LoadingButton
											// sx={{ }}
											size="large"
											variant="contained"
											className="btn-request"
											startIcon={<LoginIcon />}
											onClick={MemberOtpLogin}
											loading={memberloadingotp}
											loadingPosition="start"
										>
											Request OTP
										</LoadingButton>
									</Grid>
								</Grid>

								{/* <Typography className="normaltxt" sx={{ m: 2 }}>
								Don’t have an account, yet? <span onClick={() => NavigateRegister()}>Sign up here</span>
							</Typography>
							<Typography className="normaltxt" onClick={forgetpop}>
								<span>Forgot your password?</span>
							</Typography> */}
							</form>
						</TabPanel>

					</Paper>
				</Grid>
			</Container>
			<div>
				<Modal isOpen={showotpmodal}
					toggle={handleClose}
					backdrop="static"
				>
					<ModalHeader
						toggle={handleClose}
						className="modal-hd">
						OTP Login
						{/* {dailysalesreportId === 0 ? 'Add New DSR' : 'Update DSR'} */}
					</ModalHeader>
					<ModalBody>
						<Box >
							<form>
								<Grid item xs={12} md={12} lg={12} padding={2}>
									<Typography className="normaltxt">{`Please enter the OTP sent to your mobile number`}</Typography>
								</Grid>
								<Grid item xs={12} md={12} lg={12} padding={2}>
									<TextField
										type="Number"
										id="outlined-basic"
										size="small"
										label="Enter OTP"
										variant="outlined"
										className='txt-field'
										name="loginotp"
										value={otp}
										onChange={(e) => setOtp(e.target.value)}
									/>
									{/* {FormErr ? (
									<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
										{FormErr.oldpassword}
									</div>
								) : null} */}
								</Grid>
								<Grid item xs={12} md={12} lg={12} padding={2} className="countdown-text">
									{seconds > 0 || minutes > 0 ? (
										<p>
											Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
											{seconds < 10 ? `0${seconds}` : seconds}
										</p>
									) : (
										<p>Didn't recieve code?</p>
									)}
									<button className="normaltxt" style={{ cursor: seconds > 0 || minutes > 0 ? "not-allowed" : "pointer", color: seconds > 0 || minutes > 0 ? "#3f4041" : "#3030ff" }}
										onClick={LoginResendOtp}
										disabled={seconds > 0 || minutes > 0}
									>
										Resend OTP.
									</button>
								</Grid>
							</form>
						</Box>
					</ModalBody>
					<ModalFooter>
						<div>

							<LoadingButton
								className="btn-grad"
								variant="contained"
								onClick={() => LocationPermission('otp')}
								loading={loadingverifyotp}
								loadingPosition="start"
							>
								Verify
							</LoadingButton>

						</div>
						<div>
							<Button variant="contained" className="btn-grad" onClick={handleClose}>
								Cancel
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal isOpen={showmemberotpmodal}
					toggle={handleClose}
					backdrop="static"
				>
					<ModalHeader
						toggle={handleClose}
						className="modal-hd">
						Member OTP Login
					</ModalHeader>
					<ModalBody>
						<Box >
							<form><Grid container padding={1} rowSpacing={2}>
								<Grid item xs={12} md={12} lg={12}>
									<Typography className="normaltxt">{`Please enter the OTP sent to your mobile number`}</Typography>
								</Grid>
								<Grid item xs={12} md={12} lg={12} padding={2}>
									<TextField
										type="Number"
										id="outlined-basic"
										size="small"
										label="Enter OTP"
										variant="outlined"
										className='txt-field'
										name="loginotp"
										value={memberotp}
										onChange={(e) => setMemberotp(e.target.value)}
									/>
									{/* {FormErr ? (
									<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
										{FormErr.oldpassword}
									</div>
								) : null} */}
								</Grid><Grid item xs={12} md={12} lg={12} padding={2} className="countdown-text">
									{seconds > 0 || minutes > 0 ? (
										<p>
											Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
											{seconds < 10 ? `0${seconds}` : seconds}
										</p>
									) : (
										<p>Didn't recieve code?</p>
									)}
									<button className="normaltxt" style={{ cursor: seconds > 0 || minutes > 0 ? "not-allowed" : "pointer", color: seconds > 0 || minutes > 0 ? "#3f4041" : "#3030ff" }}
										onClick={MemberLoginResendOtp}
										disabled={seconds > 0 || minutes > 0}
									>
										Resend OTP.
									</button>

								</Grid>
							</Grid>
							</form>
						</Box>
					</ModalBody>
					<ModalFooter>
						<div>

							<LoadingButton
								className="btn-grad"
								variant="contained"
								onClick={() => MemberLoginVerifyOtp()}
								loading={memberloadingverifyotp}
								loadingPosition="start"
							>
								Verify
							</LoadingButton>

						</div>
						<div>
							<Button variant="contained" className="btn-grad" onClick={handleClose}>
								Cancel
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			</div>
		</div>
	);
};

export default LoginContent;
