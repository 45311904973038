import React, { useState, useEffect } from 'react';
import LoadingScreen from '../../layouts/LoadingScreen';
import { Grid, FormControl, Button, Typography } from '@mui/material';
import Auth from '../../../modules/Auth';
import { getsupervisormonthlyreview, getsupervisorwiseuserarn, savemonthlyreview, reviewOTP, submitmonthlyreview, getsixmonthreviewlatestdate } from '../../Api/SupervisorAPI';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ReviewType } from '../../../modules/helpers/constant';

const MonthlyReviewContent = () => {
  const [arnf, setArnf] = useState([]);
  const [arnOption, setArnOption] = useState([]);
  const [monthDate, setMonthDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [monthvalidatedate, setMonthvalidatedate] = useState(null);
  // const [sixmonthvalidatedate, setSixmonthvalidatedate] = useState(null);
  const [plannedAndAchieveddata, setPlannedAndAchieveddata] = useState({
    meetingPlanned: "",
    lumpsumAmountPlanned: "",
    lumpsumApplicationPlanned: "",
    sipAmountPlanned: "",
    sipApplicationPlanned: "",

  });
  const [Achieveddata, setAchieveddata] = useState({
    meetingAchieved: "",
    lumpsumAmountAchieved: "",
    lumpsumApplicationAchieved: "",
    sipAmountAchieved: "",
    sipApplicationAchieved: "",
  })
  // const [Achieveddata, setAchieveddata] = useState([])
  const [otparameter, setOtparameter] = useState({
    whatwentwell: "",
    whatdidnotgowell: "",
    challengesfaced: "",
    trainingrequired: "",
    nimfsupportrequired: ""
  });
  const [supervisorOTP, setSupervisorOTP] = useState("");
  const [submmited, setSubmmited] = useState(1);
  const [reviewtype, setReviewtype] = useState({ label: "Monthly", value: "Monthly" });
  const [Errors, setErrors] = useState({});
  const supervisorid = Auth.getUserId();
  const username = Auth.getUserName();
  useEffect(() => {
    let sixmonthdate = moment(new Date()).format("yyyy-MM-DD")
    let dates = new Date(sixmonthdate);
    dates.setMonth(dates.getMonth() + 6);
    setEndDate(dates)
    GetUserArns();
  }, [])
  useEffect(() => {
    // if (arnf.value !== undefined) {
    //   GetMonthlyReview();
    // }
    if (arnf.value !== undefined && reviewtype.value === 'Six_Monthly') {
      GetSixMonthlyReviewDate();
    } else if (arnf.value !== undefined) {
      GetMonthlyReview();
    }
  }, [arnf, reviewtype, monthDate])
  useEffect(() => {
    if (arnf.value !== undefined && endDate !== null) {
      GetMonthlyReview();
    }
  }, [endDate])

  const GetUserArns = () => {
    setLoading(true);
    getsupervisorwiseuserarn(supervisorid).then((res) => {
      setLoading(false);
      if (res.data.response.status === 1) {
        let arnlist = res.data.response.data.arnCodeList
        setArnOption(arnlist)
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err)
    })
  }
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setPlannedAndAchieveddata({ ...plannedAndAchieveddata, [name]: value });
  }
  const handleOTChange = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setOtparameter({ ...otparameter, [name]: value });
  }
  const GetMonthlyReview = () => {
    setLoading(true);
    let userid = arnf.value;
    let date = moment(monthDate).format('yyyy-MM-DD');
    let startdates = startDate === null ? moment(new Date()).format('yyyy-MM-DD') : moment(startDate).format('yyyy-MM-DD');
    let enddates = endDate === null ? moment(new Date()).format('yyyy-MM-DD') : moment(endDate).format('yyyy-MM-DD');
    let data = {
      "SupervisorId": supervisorid,
      "UserId": userid,
      "Date": date,
      "ReviewType": reviewtype.value,
      "StartDate": startdates,
      "EndDate": enddates
    }
    getsupervisormonthlyreview(data).then((res) => {

      if (res.data.response.status === 1) {
        let result = res.data.response.data.detailByID;

        let resultSM = res.data.response.data.achievedDetails[0];



        resultSM === undefined ?
          setAchieveddata({
            meetingAchieved: "",
            lumpsumAmountAchieved: "",
            lumpsumApplicationAchieved: "",
            sipAmountAchieved: "",
            sipApplicationAchieved: "",
          })
          : setAchieveddata({
            meetingAchieved: resultSM.totalMeetingsDone,
            lumpsumAmountAchieved: resultSM.lumpsumAmount,
            lumpsumApplicationAchieved: resultSM.lumpsumApplication,
            sipAmountAchieved: resultSM.sipTotalAmount,
            sipApplicationAchieved: resultSM.sipApplication,
          })

        result == null ?
          setPlannedAndAchieveddata({
            ...plannedAndAchieveddata,
            meetingPlanned: "",
            lumpsumAmountPlanned: "",
            lumpsumApplicationPlanned: "",
            sipAmountPlanned: "",
            sipApplicationPlanned: "",

          })
          : setPlannedAndAchieveddata({
            ...plannedAndAchieveddata,
            meetingPlanned: result.clientMeetingsPlanned,
            lumpsumAmountPlanned: result.lumpsumAmountPlanned,
            lumpsumApplicationPlanned: result.lumpsumApplicationPlanned,
            sipAmountPlanned: result.sipAmountPlanned,
            sipApplicationPlanned: result.sipApplicationPlanned,

          });
        setOtparameter({
          ...otparameter,
          whatwentwell: result.whatWentWell,
          whatdidnotgowell: result.whatDidntGoWell,
          challengesfaced: result.challengesFaced,
          trainingrequired: result.trainingRequired,
          nimfsupportrequired: result.nimfSupportRequired
        });
        setSubmmited(result.submitted);
      } else {
        setPlannedAndAchieveddata({
          ...plannedAndAchieveddata,
          meetingPlanned: "",
          lumpsumAmountPlanned: "",
          lumpsumApplicationPlanned: "",
          sipAmountPlanned: "",
          sipApplicationPlanned: "",

        });
        setAchieveddata({
          meetingAchieved: "",
          lumpsumAmountAchieved: "",
          lumpsumApplicationAchieved: "",
          sipAmountAchieved: "",
          sipApplicationAchieved: "",
        })
        setOtparameter({
          ...otparameter,
          whatwentwell: "",
          whatdidnotgowell: "",
          challengesfaced: "",
          trainingrequired: "",
          nimfsupportrequired: ""
        });
        setSubmmited(1);
      }
      setLoading(false);
      setSupervisorOTP("");
    }).catch((err) => {
      setLoading(false);
      console.log(err)
    })
  }
  const SaveMonthlyReview = () => {
    const valid = validate('Save');
    if (valid) {

      let startdates = moment(startDate).format('yyyy-MM-DD');
      let enddates = moment(endDate).format('yyyy-MM-DD');
      let mdate = moment(monthDate).format('MM');
      let ydate = moment(monthDate).format('yyyy');
      let newDate = `${ydate}-${mdate}-01`
      let monthdates = newDate;
      setLoading(true);
      let data = {
        LumpsumAmountAchieved: Achieveddata.lumpsumAmountAchieved === "" ? 0 : Number(Achieveddata.lumpsumAmountAchieved),
        LumpsumApplicationAchieved: Achieveddata.lumpsumApplicationAchieved === "" ? 0 : Number(Achieveddata.lumpsumApplicationAchieved),
        SipApplicationAchieved: Achieveddata.sipApplicationAchieved === "" ? 0 : Number(Achieveddata.sipApplicationAchieved),
        SipAmountAchieved: Achieveddata.sipAmountAchieved === "" ? 0 : Number(Achieveddata.sipAmountAchieved),
        ClientMeetingsAchieved: Achieveddata.meetingAchieved === "" ? 0 : Number(Achieveddata.meetingAchieved),

        LumpsumAmountPlanned: plannedAndAchieveddata.lumpsumAmountPlanned === "" ? 0 : Number(plannedAndAchieveddata.lumpsumAmountPlanned),
        LumpsumApplicationPlanned: plannedAndAchieveddata.lumpsumApplicationPlanned === "" ? 0 : Number(plannedAndAchieveddata.lumpsumApplicationPlanned),
        SipApplicationPlanned: plannedAndAchieveddata.sipApplicationPlanned === "" ? 0 : Number(plannedAndAchieveddata.sipApplicationPlanned),
        SipAmountPlanned: plannedAndAchieveddata.sipAmountPlanned === "" ? 0 : Number(plannedAndAchieveddata.sipAmountPlanned),
        ClientMeetingsPlanned: plannedAndAchieveddata.meetingPlanned === "" ? 0 : Number(plannedAndAchieveddata.meetingPlanned),
        ChallengesFaced: otparameter.challengesfaced,
        NimfSupportRequired: otparameter.nimfsupportrequired,
        TrainingRequired: otparameter.trainingrequired,
        WhatDidntGoWell: otparameter.whatdidnotgowell,
        WhatWentWell: otparameter.whatwentwell,
        SupervisorId: supervisorid,
        ReviewType: reviewtype.value,
        UserId: arnf.value,
        Date: monthdates,
        StartDate: startdates,
        EndDate: enddates
      }
      savemonthlyreview(data).then((res) => {
        setLoading(false);
        if (res.data.response.status === 1) {
          Swal.fire("", `${res.data.response.message}`, "success");
          GetMonthlyReview();
        } else {
          Swal.fire("", `Something Went Error`, "error");
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    }
  }
  const SubmitMonthlyReview = () => {
    const valid = validate('Submit');
    if (valid) {
      setLoading(true);
      let startdates = moment(startDate).format('yyyy-MM-DD');
      let enddates = moment(endDate).format('yyyy-MM-DD');
      let mdate = moment(monthDate).format('MM');
      let ydate = moment(monthDate).format('yyyy');
      let newDate = `${ydate}-${mdate}-01`
      let monthdates = newDate;
      let data = {
        LumpsumAmountAchieved: Achieveddata.lumpsumAmountAchieved === "" ? 0 : Number(Achieveddata.lumpsumAmountAchieved),
        LumpsumApplicationAchieved: Achieveddata.lumpsumApplicationAchieved === "" ? 0 : Number(Achieveddata.lumpsumApplicationAchieved),
        SipApplicationAchieved: Achieveddata.sipApplicationAchieved === "" ? 0 : Number(Achieveddata.sipApplicationAchieved),
        SipAmountAchieved: Achieveddata.sipAmountAchieved === "" ? 0 : Number(Achieveddata.sipAmountAchieved),
        ClientMeetingsAchieved: Achieveddata.meetingAchieved === "" ? 0 : Number(Achieveddata.meetingAchieved),

        LumpsumAmountPlanned: plannedAndAchieveddata.lumpsumAmountPlanned === "" ? 0 : Number(plannedAndAchieveddata.lumpsumAmountPlanned),
        LumpsumApplicationPlanned: plannedAndAchieveddata.lumpsumApplicationPlanned === "" ? 0 : Number(plannedAndAchieveddata.lumpsumApplicationPlanned),
        SipApplicationPlanned: plannedAndAchieveddata.sipApplicationPlanned === "" ? 0 : Number(plannedAndAchieveddata.sipApplicationPlanned),
        SipAmountPlanned: plannedAndAchieveddata.sipAmountPlanned === "" ? 0 : Number(plannedAndAchieveddata.sipAmountPlanned),
        ClientMeetingsPlanned: plannedAndAchieveddata.meetingPlanned === "" ? 0 : Number(plannedAndAchieveddata.meetingPlanned),
        ChallengesFaced: otparameter.challengesfaced,
        NimfSupportRequired: otparameter.nimfsupportrequired,
        TrainingRequired: otparameter.trainingrequired,
        WhatDidntGoWell: otparameter.whatdidnotgowell,
        WhatWentWell: otparameter.whatwentwell,
        SupervisorId: supervisorid,
        ReviewType: reviewtype.value,
        UserId: arnf.value,
        Date: monthdates,
        StartDate: startdates,
        EndDate: enddates,
        otp: supervisorOTP
      }
      submitmonthlyreview(data).then((res) => {
        setLoading(false);
        if (res.data.response.status === 1) {
          Swal.fire("", `${res.data.response.message}`, "success");
          GetMonthlyReview();
        } else {
          Swal.fire("", `${res.data.response.message}`, "error");
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    }

  }
  const validate = (type) => {
    let error = {}
    let valid = true;
    if (Object.entries(arnf).length === 0) {
      valid = false
      Swal.fire("", "Please select ARN Code.", 'error')
      // error['arnf'] = 'Please select ARN Code.'
    }
    else if (supervisorOTP === "" && type == 'Submit') {
      valid = false
      Swal.fire("", "Please enter OTP before submitting.", 'error')
    }
    setErrors(error);
    return valid
  }
  const SendreviewOTP = () => {
    if (arnf.value !== undefined) {
      setLoading(true);
      let userid = arnf.value
      reviewOTP(supervisorid, userid).then((res) => {
        setLoading(false);
        if (res.data.response.status === 1) {
          Swal.fire("", "OTP Sent Successfully to Supervisor", "success")
        } else {
          Swal.fire("", "Something Went Wrong", "error")
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
    } else {
      Swal.fire("", "Please Select ARN Code", "error")
    }

  }
  const GetSixMonthlyReviewDate = () => {
    setLoading(true);
    let data = {
      "SupervisorId": supervisorid,
      "UserId": arnf.value,
      "ReviewType": "Six_Monthly"
    }
    getsixmonthreviewlatestdate(data).then((res) => {
      if (res.data.response.status === 1) {
        let result = res.data.response.data;
        let latest_date = result.latestSixMonthlyDate === "null" ? result.registrationDate : result.latestSixMonthlyDate;

        // setSixmonthvalidatedate(new Date(latest_date));
        setStartDate(new Date(latest_date));
        let date = new Date(latest_date)
        let sixmonthdate = moment(date).format("yyyy-MM-DD")
        let dates = new Date(sixmonthdate);
        dates.setMonth(dates.getMonth() + 6);
        setEndDate(dates);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }).catch((err) => {
      console.log(err);
      setStartDate(null);
      setEndDate(null);
    })
  }
  // const handleChangeArchieve = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value
  //   setAchieveddata({ ...Achieveddata, [name]: value });
  // }
  return (
    <div className='p-4'>
      {loading === true ? <LoadingScreen /> : null}
      <Grid container spacing={1} padding={0} rowSpacing={2}  >
        <Grid item md={12} className={'center'} >
          <Grid container spacing={1} padding={3} rowSpacing={0} width={'80%'} className={'admin__search-bar'}>
            <Grid item xs={12} md={3} lg={3}>
              <FormControl fullWidth className="textfield">
                <label>ARN Code</label>
                <Select
                  value={arnf}
                  options={arnOption}
                  onChange={(selected) => {
                    setArnf(selected);
                    let date = new Date(selected.registration_Date);
                    let mdate = moment(date).format('MM');
                    let ydate = moment(date).format('yyyy');
                    let newDate = `${mdate}-01-${ydate}`
                    setMonthvalidatedate(new Date(newDate));
                    setMonthDate(new Date());
                    setErrors({ ...Errors, arnf: '' });
                  }}
                />
              </FormControl>
              {Errors ? (
                <div style={{ fontSize: 12, color: 'red' }}>
                  {Errors.arnf}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormControl fullWidth className="textfield">
                <label>Select Review Type</label>
                <Select
                  value={reviewtype}
                  options={ReviewType}
                  onChange={(selected) => {
                    setReviewtype(selected);
                    setOtparameter({
                      ...otparameter,
                      whatwentwell: "",
                      whatdidnotgowell: "",
                      challengesfaced: "",
                      trainingrequired: "",
                      nimfsupportrequired: ""
                    });
                  }}
                />
              </FormControl>
            </Grid>
            {reviewtype.value === "Monthly" ?
              <Grid item xs={3} md={3} lg={3}>
                <FormControl fullWidth className="textfield">
                  <label>Select Date</label>
                  <DatePicker
                    selected={monthDate}
                    onChange={(date) => {
                      setMonthDate(date);
                    }}
                    sx={{ border: '0' }}
                    className='admin__date  '
                    popperPlacement="top-start"
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    minDate={new Date(monthvalidatedate)}
                    showYearDropdown
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </FormControl>

              </Grid> :
              <>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl fullWidth className="textfield">
                    <label>Select Start Date</label>
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => {
                      //   setStartDate(date);
                      //   let sixmonthdate = moment(date).format("yyyy-MM-DD")
                      //   let dates = new Date(sixmonthdate);
                      //   dates.setMonth(dates.getMonth() + 6);
                      //   setEndDate(dates);
                      // }}
                      sx={{ border: '0' }}
                      disabled={true}
                      className='admin__date  '
                      popperPlacement="top-start"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showFullMonthYearPicker
                    />
                  </FormControl>

                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl fullWidth className="textfield">
                    <label>End Date</label>
                    <DatePicker
                      selected={endDate}
                      // onChange={(date) => {
                      //   setEndDate(date);
                      // }}
                      disabled={true}
                      sx={{ border: '0' }}
                      className='admin__date  '
                      popperPlacement="top-start"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showFullMonthYearPicker
                    />
                  </FormControl>
                </Grid>
              </>
            }</Grid>
        </Grid>


        <Grid item md={12} className={'center'}>
          <Grid rowSpacing={1} container padding={3} className={'admin-summary '} >
            <Grid md={12} className={'center'}>
              <Typography variant='h5'>{reviewtype.label} Summary</Typography>
            </Grid>
            <Grid item md={12}>&nbsp;</Grid>
            <Grid item xs={12} md={6} lg={6} className={'divider'}>
              <strong>Business output parameter</strong>
            </Grid>
            <Grid item xs={12} md={3} lg={3} className={'divider'}>
              <strong>Planned</strong>
            </Grid>
            <Grid item xs={12} md={3} lg={3} className={'divider'}>
              <strong>Achieved</strong>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={'left'}>
              No. of Client Meetings
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input onChange={handleChange} disabled={0 === submmited} type="number" value={plannedAndAchieveddata.meetingPlanned} name="meetingPlanned" placeholder='Enter planned meeting' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input disabled={0 === submmited} type="number" value={Achieveddata.meetingAchieved} name="meetingAchieved" placeholder='Enter achieved meeting' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={'left'}>
              Total Lumpsum (in lacs)
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input onChange={handleChange} disabled={0 === submmited} type="number" value={plannedAndAchieveddata.lumpsumAmountPlanned} name="lumpsumAmountPlanned" placeholder='Enter planned Lumpsum amount' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input type="number" disabled={0 === submmited}  value={Achieveddata.lumpsumAmountAchieved} name="lumpsumAmountAchieved" placeholder='Enter achieved Lumpsum amount' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={'left'}>
              Total Lumpsum (No. of Applications)
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input onChange={handleChange} disabled={0 === submmited} type="number" value={plannedAndAchieveddata.lumpsumApplicationPlanned} name="lumpsumApplicationPlanned" placeholder='Enter planned Lumpsum application' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input type="number" disabled={0 === submmited}  value={Achieveddata.lumpsumApplicationAchieved} name="lumpsumApplicationAchieved" placeholder='Enter achieved Lumpsum application' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={'left'}>
              Total New SIPs (in lacs)
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input onChange={handleChange} disabled={0 === submmited} type="number" value={plannedAndAchieveddata.sipAmountPlanned} name="sipAmountPlanned" placeholder='Enter planned SIP amount' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input type="number" disabled={0 === submmited}  value={Achieveddata.sipAmountAchieved} name="sipAmountAchieved" placeholder='Enter achieved SIP amount' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={'left'}>
              Total SIP (No. of Applications)
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input onChange={handleChange} disabled={0 === submmited} type="number" value={plannedAndAchieveddata.sipApplicationPlanned} name="sipApplicationPlanned" placeholder='Enter planned SIP application' className='admin_txtfield' />
            </Grid>
            <Grid item xs={12} md={3} lg={3} >
              <input type="number" disabled={0 === submmited}  value={Achieveddata.sipApplicationAchieved} name="sipApplicationAchieved" placeholder='Enter achieved SIP application' className='admin_txtfield' />
            </Grid>
            <Grid item md={12}>&nbsp;</Grid>
            <Grid item xs={6} md={9} lg={9} className={'divider'}>
              <strong>Other Parameters</strong>
            </Grid>
            <Grid item xs={6} md={3} lg={3} className={'divider'}>
              <strong> Remarks</strong>
            </Grid>
            <Grid item xs={6} md={9} lg={9} className={'left'}>
              What Went Well?
            </Grid>
            <Grid item xs={6} md={3} lg={3} >
              <input value={otparameter.whatwentwell} name="whatwentwell" onChange={handleOTChange} disabled={0 === submmited} className='admin_txtfield' />
            </Grid>
            <Grid item xs={6} md={9} lg={9} className={'left'}>
              What Didn't Go Well?
            </Grid>
            <Grid item xs={6} md={3} lg={3} >
              <input value={otparameter.whatdidnotgowell} name="whatdidnotgowell" onChange={handleOTChange} disabled={0 === submmited} className='admin_txtfield' />
            </Grid>
            <Grid item xs={6} md={9} lg={9} className={'left'}>
              Challenges Faced
            </Grid>
            <Grid item xs={6} md={3} lg={3} >
              <input value={otparameter.challengesfaced} name="challengesfaced" onChange={handleOTChange} disabled={0 === submmited} className='admin_txtfield' />
            </Grid>
            <Grid item xs={6} md={9} lg={9} className={'left'}>
              Training Required
            </Grid>
            <Grid item xs={6} md={3} lg={3} >
              <input value={otparameter.trainingrequired} name="trainingrequired" onChange={handleOTChange} disabled={0 === submmited} className='admin_txtfield' />
            </Grid>
            <Grid item xs={6} md={9} lg={9} className={'left'}>
              NIMF Support Required
            </Grid>
            <Grid item xs={6} md={3} lg={3} >
              <input value={otparameter.nimfsupportrequired} name="nimfsupportrequired" onChange={handleOTChange} disabled={0 === submmited} className='admin_txtfield' />
            </Grid>


            <Grid item md={12} className={'divider'}>&nbsp;</Grid>
            <Grid item md={12}>&nbsp;</Grid>
            <Grid item xs={6} md={6} lg={6} className={'left'}>
              <strong> IMFD:</strong> <br />{arnf.value === undefined ? "" : arnf.label}
              <br />
              <strong> Name:</strong> <br />{arnf.arn_Name === undefined ? "" : arnf.arn_Name}
            </Grid>
            <Grid item xs={6} md={6} lg={6} className={'right'}>
              <strong>NIMF Authorised Person </strong><br />Name: {username}<br />
              <i>Designation: {"Senior"}</i>
            </Grid>
            <Grid item md={12} className={'divider'}>&nbsp;</Grid>
            <Grid item md={12}>&nbsp;</Grid>
            <Grid item xs={6} md={6} lg={6}>
              <button onClick={() => SendreviewOTP()} disabled={0 === submmited} className='btn-grad'>Send OTP</button>
            </Grid>
            <Grid item xs={6} md={6} lg={6} className={'right'}>
              <input type='number' value={supervisorOTP} onChange={(e) => { setSupervisorOTP(e.target.value) }} disabled={0 === submmited} className='admin_txtfield' placeholder='Enter OTP' /><br />

            </Grid>


          </Grid></Grid>

        {/* <Grid item md={12} className={'center'} >
        <Grid padding={1} rowSpacing={1} container width={'80%'}>
          <Grid item xs={6} md={6} lg={6}>
            <span> IMFD:{arnf.value === undefined ? "" : arnf.label}</span>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <span>NIMF Authorised Person<br /> Name: {username}</span><br />
            <span>Designation: {"senior"}</span>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <span> Name:{"Saurabh Rawool"}</span>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <span>Supervisor OTP to be entered</span><br />
            <input value={supervisorOTP} onChange={(e) => { setSupervisorOTP(e.target.value) }} disabled={0 === submmited} />
            <button onClick={() => SendreviewOTP()} disabled={0 === submmited}>Send OTP</button>
          </Grid>
        </Grid>
        </Grid> */}
        <Grid item xs={12} md={12} rowSpacing={1}>
          <Button onClick={() => SaveMonthlyReview()} variant="contained" disabled={0 === submmited} className={'btn-grad'} sx={{ marginRight: '1rem' }}>Save</Button>
          <Button onClick={() => { SubmitMonthlyReview() }} variant="contained" disabled={0 === submmited} className={'btn-grad'}>Submit</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default MonthlyReviewContent