import React, { useEffect, useState } from 'react';
import TopNavigation from '../../layouts/TopNavigation';
import SideNavigation from '../../layouts/SideNavigation';
import LoadingScreen from '../../layouts/LoadingScreen';
import { MarketScopePoints, sectorByCategory } from '../../Api/MarketScopeApi';
import { Grid, FormControl, Button } from '@mui/material';
import Select from 'react-select';
import No_data from '../../../assets/images/no_data1.jpg';
// import DatePicker from 'react-datepicker';
// import moment from 'moment';
import Auth from '../../../modules/Auth';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { Box } from '@mui/system';
// import { Preview } from '@mui/icons-material'
import { getallsector } from '../../Api/AdminApi';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
const ScopepointsContent = () => {
    const UserId = Auth.getUserId();

    const [categoryf, setCategoryf] = useState([]);
    const [categoryoption, setCategoryoption] = useState([
        { label: "Key Industry", value: 1 },
        { label: "Top Co-operative Banks Present", value: 2 },
        { label: "Top Co-operative Society Present", value: 3 },
        { label: "Top FMCG", value: 4 },
        { label: "Top Influencers in location", value: 5 },
        { label: "Top Industry Associations", value: 6 },
    ])
    const [sectorf, setSectorf] = useState([]);
    const [sectoroption, setSectoroption] = useState([]);
    const [companyname, setCompanyname] = useState("");

    const [errors, setErrors] = useState({});
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    // const [show, setShow] = useState(false);
    // const [previewData, setPreviewData] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [count, setCount] = useState(0)
    useEffect(() => {
        GetMSDetails();
        // GetSector()  
    }, [pageNo])



    // const GetSector = () => {
    //     getallsector().then((res) => {
    //         if (res.data.response.status === 1) {
    //             let result = res.data.response.data.sectorDetails;
    //             let sectorlist = [];
    //             let uniquesectorlist = [];
    //             result && result.map((x) => {
    //                 sectorlist.push(
    //                     x.sectorName
    //                 )
    //             })
    //             var unique = [...new Set(sectorlist)];

    //             unique.map((x) => {
    //                 uniquesectorlist.push({
    //                     label: x,
    //                     value: x
    //                 })
    //             })

    //             setSectoroption(uniquesectorlist);
    //         }
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }


    const GetMSDetails = (search) => {

        if(search !== undefined){
            setPageNo(search)
        }
        setLoading(true);
        let data = {
            userId: UserId,
            market_Scope_ID: categoryf.value === undefined ? 0 : categoryf.value,
            sector_Name: sectorf.label === undefined ? null : sectorf.label,
            companyName: companyname === "" ? null : companyname,
            // startDate: fromdatef === null ? null : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
            // endDate: todatef === null ? null : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
            pageNo:search  === undefined ? pageNo  : search,
            PageSize:5,
            type: "Pagination",
        }
        MarketScopePoints(data).then((res) => {
            if (res.data.response.status === 1) {
                let result = res.data.response.data;
                setCount(result.count);
                setData(result.history);
            } else {
                setCount(0);
                setData([]);
            }
            setLoading(false);
        }).catch((err) => {
            setCount(0);

            setData([]);
            setLoading(false);
            console.log(err, "err in MarketScopePoints")
        })

    }

    // const handlePreview = (data) => {
    //     setPreviewData(data);
    //     setShow(true);
    // }

    const ClearFilter = () => {
        setCategoryf([]);
        setSectorf([]);
        setCompanyname("");
        // GetSector()
        setPageNo(1);
        setCount(0);
        setErrors({});
        setLoading(true)
        let data = {
            userId: UserId,
            market_Scope_ID: 0,
            sector_Name: null,
            companyName: null,
            // startDate: null,
            // endDate: null,
            pageNo:1,
            PageSize:5,
            type: "Pagination",
        }
        MarketScopePoints(data).then((res) => {
            setLoading(false);
            if (res.data.response.status === 1) {
                let result = res.data.response.data;
                setCount(result.count);
                setData(result.history);
            } else {
                setCount(0);
                setData([]);
            }
        }).catch((err) => {
            setCount(0);
            setData([]);
            setLoading(false);
            console.log(err, "err in MarketScopePoints")
        })
    }

    const sectorFilterByCategory = (id) => {
        setLoading(true);
        sectorByCategory(id, UserId)
            .then((res) => {
                if (res.data.response.status === 1) {
                    let result = res.data.response.data.sectorDetails;

                    let sectorlist = result.map((f) => {
                        return { label: f.sectorName, value: f.sectorId };
                    });
                    setSectoroption(sectorlist)
                } else {
                    setSectoroption([])
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err, "err in sectorByCategory")
            })
    }
    const handlePageChange = (pageNumber) =>  {
        // console.log(`active page is ${pageNumber}`);
        setPageNo(pageNumber);
      }
    return (
        <div>
            <TopNavigation />
            <SideNavigation />
            {loading === true ? <LoadingScreen /> : null}


            <Grid container spacing={2} padding={3} rowSpacing={3} paddingLeft={'80px'} >
                <Grid item md={6} xs={6} alignContent="flex-start">
                    <div className='sub-heading'>Scope Points</div>
                </Grid>
                {/* <Grid item md={6} xs={6} className={'right'}>
                  <div>
                      <Button onClick={handleDownloadExcel} variant="contained" className='p-2'>download excel</Button>

                      <Button
                          className='p-2 m-2'
                          variant="contained"
                          onClick={() => downloadpdf()}
                      >
                          Download PDF
                      </Button>
                  </div>
              </Grid> */}
                <Grid item xs={12} md={12} className={'p-4'}>
                    <Grid container spacing={2} padding={0} rowSpacing={1.5} className={'admin__search-bar'} alignItems="center"
                        justifyContent="center">

                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth className="textfield">
                                <label>Category</label>
                                <Select
                                    value={categoryf}
                                    options={categoryoption}
                                    onChange={(selected) => {
                                        setCategoryf(selected);
                                        sectorFilterByCategory(selected.value)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth className="textfield">
                                <label>Sector</label>
                                <Select
                                    value={sectorf}
                                    options={sectoroption}
                                    isDisabled={categoryf.length === 0}
                                    onChange={(selected) => {
                                        setSectorf(selected);
                                    }}
                                    isClearable
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth className="textfield">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    className=""
                                    placeholder='Enter company name'
                                    value={companyname}
                                    onChange={(e) => {
                                        setCompanyname(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>


                        <Grid item md={12} xs={12} className={'center p-t-5'}>
                            <Button variant="contained" className='btn-grad' onClick={() => GetMSDetails(1)} >Search</Button>
                            <Button variant="contained" className='btn-grad' onClick={() => ClearFilter()} >Clear</Button>
                        </Grid>
                    </Grid>
                </Grid>
{data.length !== 0 ? 
                <Grid item xs={12} md={12}>
                    <div className="overflow-admin">
                        <table id="dsr" border={1} >
                            <thead>
                                <tr className="thead" >
                                    <td rowSpan={2}>Sr. No.</td>
                                    <td rowSpan={2} className="p-r-100">Category</td>
                                    <td rowSpan={2} className="p-r-100">Sector</td>
                                    <td rowSpan={2} className="p-r-100">Company Name</td>
                                    <td rowSpan={2} className="p-r-100">Contact Person</td>
                                    <td rowSpan={2} className="p-r-100">Designation</td>
                                    <td rowSpan={2} className="p-r-100">Address</td>
                                    <td rowSpan={2}>District</td>
                                    <td rowSpan={2}>Pin Code</td>
                                    <td rowSpan={2}>Contact no.</td>
                                    <td rowSpan={2}>Email</td>
                                    <td rowSpan={2}>FD Amount</td>
                                    <td rowSpan={2} className="p-r-100">Nature of Investment</td>
                                    <td rowSpan={2}>Total Employees</td>
                                    {/* <td rowSpan={2}>Action</td> */}
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((dailysreport, ind) => (
                                        <tr key={ind}>
                                              <td>{(ind + 1) +( (pageNo * 5) - (5) )}</td>
                                            <td>{dailysreport.marketScopeName}</td>
                                            <td>{dailysreport.sectorName}</td>
                                            <td>{dailysreport.companyName}</td>
                                            <td>{dailysreport.contactPerson}</td>
                                            <td>{dailysreport.designation}</td>
                                            <td>{dailysreport.address}</td>
                                            <td>{dailysreport.district}</td>
                                            <td>{dailysreport.pinCode}</td>
                                            <td >{dailysreport.contactNumber}</td>
                                            <td>{dailysreport.emailAddress}</td>
                                            <td>{dailysreport.turnOver}</td>
                                            <td>{dailysreport.natureInvName}</td>
                                            <td>{dailysreport.totalEmployees}</td>
                                            {/* <td className="icons-wrap">
                                              <IconButton
                                                  color="primary"
                                                  title='Preview'
                                                  onClick={() => handlePreview(dailysreport)}
                                              >
                                                  <Preview />
                                              </IconButton>
                                          </td> */}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <Grid item md={12} xs={12} className={'center p-t-5'}>
                        <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pageNo}
                        itemsCountPerPage={5}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        prevPageText="Previous"
                        nextPageText="Next"
                        onChange={handlePageChange}
                        />
                        </Grid>
                </Grid> :<img src={No_data} className="no_data" alt="no data"/>}

            </Grid>

        </div>
    )
}

export default ScopepointsContent