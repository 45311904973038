// export const loginsuccess = (data) => {
// 	return (dispatch) => {
// 		dispatch({
// 			type: 'Logged_Successfully',
// 			payload: data
// 		});
// 	};
// };

// export const loginfail = (data) => {
// 	debugger;
// 	return (dispatch) => {
// 		dispatch({
// 			type: 'Logged_Failed',
// 			payload: {}
// 		});
// 	};
// };
/* key industry action */
export const setkeyinddetails = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_KEYIND',
			payload: data
		});
	};
};
export const updatekeyinddetails = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_KEYIND',
			payload: data
		});
	};
};
export const handlechangekeyind = (data, idx) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_ARRAY',
			payload: data,
			index: idx
		});
	};
};
export const clearkeyinddetails = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_KEYIND',
			payload: []
		});
	};
};

/* top co operative banks present action */

export const settopercobanks = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_COBanks',
			payload: data
		});
	};
};
export const updatetopercobanks = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_COBanks',
			payload: data
		});
	};
};
export const cleartopercobanks = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_COBanks',
			payload: []
		});
	};
};
/* top co operative society present action */

export const settopercosociety = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_COSociety',
			payload: data
		});
	};
};
export const updatetopercosociety = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_COSociety',
			payload: data
		});
	};
};
export const cleartopercosociety = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_COSociety',
			payload: []
		});
	};
};
/* top FMCG */
export const settopfmcg = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_FMCG',
			payload: data
		});
	};
};
export const updatetopfmcg = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_FMCG',
			payload: data
		});
	};
};
export const cleartopfmcg = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_FMCG',
			payload: []
		});
	};
};

/* top Influencers in location */
export const settopinfluencers = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_INFLUENCERS',
			payload: data
		});
	};
};
export const updatetopinfluencers = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_INFLUENCERS',
			payload: data
		});
	};
};
export const cleartopinfluencers = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_INFLUENCERS',
			payload: []
		});
	};
};
/* Top industry Associtions */
export const setindassociations = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_ASSOCIATIONS',
			payload: data
		});
	};
};
export const updateindassociations = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_ASSOCIATIONS',
			payload: data
		});
	};
};
export const clearindassociations = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_ASSOCIATIONS',
			payload: []
		});
	};
};
/* Static Market Scope Data */
export const setstaticmarketscope = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_MarketScopeData',
			payload: data
		});
	};
};
export const clearstaticmarketscope = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_MarketScopeData',
			payload: []
		});
	};
};
