const initialState = {
	marketscopedata: {}
};
const staticmarketscopedataReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_MarketScopeData':
			return {
				...state,
				marketscopedata: action.payload
			};
		case 'CLEAR_MarketScopeData':
			return {
				...state,
				marketscopedata: action.payload
			};
		default:
			return state;
	}
};

export default staticmarketscopedataReducer;
