import React from 'react';
import LoginContent from '../sections/Login/LoginContent';

const Login = () => {
	return (
		<div style={{height:'100%'}}>
			<LoginContent />
		</div>
	);
};

export default Login;
