// import { faker } from "@faker-js/faker"
import jsPDF from "jspdf"
import autoTable from 'jspdf-autotable'
import base64Img from '../../assets/images/nipponindia-mutual-fund-logo.png';
import banner from '../../assets/images/banner-bg.jpg';
import moment from "moment";
const exportPDF = (headr, data, titlename, headingname) => {
    var doc = new jsPDF({
        orientation: 'landscape'
    })
    var totalPagesExp = '{total_pages_count_string}'
    doc.setProperties({
        author: 'nipponindiaim',
        title: titlename,
        creator: 'nipponindia'
    })
    doc.autoTable({
        head: headr,
        body: data,
        styles: { cellPadding: 0.5, fontSize: 5, },
        // startY: doc.lastAutoTable.finalY + 15,
        headStyles: { fillColor: [173, 26, 26] },
        rowPageBreak: 'auto',
        bodyStyles: { valign: 'top' },
        theme: 'grid',
        didDrawPage: function (data) {
            var pageSize = doc.internal.pageSize

            // Header
            if (base64Img) {
                doc.addImage(banner, 'JPEG', 0, 0, pageSize.width, 15)
                doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 3, 50, 8)

            }
            doc.setFontSize(20)
            doc.setTextColor(40)

            doc.setFont("times");
            doc.text(headingname, pageSize.width / 2, 9, { align: 'center' });
            doc.setFontSize(10);

            doc.text(`Date : ${moment(new Date()).format('DD-MM-yyyy')}`, pageSize.width - 50, 8);
            doc.setFontSize(20);

            // Footer
            var str = 'Page ' + doc.internal.getNumberOfPages()
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(10)

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
            doc.text(str, data.settings.margin.left, pageHeight - 10)
            // doc.setFont('Arial', 'Bold');
            doc.setLineWidth(0.3);
            doc.line(0, pageHeight - 7, pageSize.width, pageHeight - 7);
            doc.setFontSize(15);
            doc.text('Mutual Fund investments are subject to market risks, read all scheme related documents carefully.', pageSize.width / 2, pageHeight - 2, { align: 'center' })
        },
        margin: { top: 30 },
    })
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
    }
    doc.output('pdfobjectnewwindow', { filename: `${titlename}.pdf` })
}
export {
    exportPDF
}