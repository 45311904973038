// import update from 'react-addons-update';

const initialState = {
	cobanksDetails: [],
	oncobankchange: false
};
const topcooperbanksreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_COBanks':
			return {
				...state,
				cobanksDetails: action.payload
			};
		case 'UPDATE_COBanks':
			return {
				...state,
				cobanksDetails: action.payload
			};
		case 'CLEAR_COBanks':
			return {
				...state,
				cobanksDetails: action.payload
			};
		default:
			return state;
	}
};

export default topcooperbanksreducer;
