class Auth {
	constructor() {
		this.user = JSON.parse(localStorage.getItem('User')) || null;
		// if (this.data !== null) {
		// 	this.user = JSON.parse(this.data.login);
		// }
	}

	getToken() {
		if (this.user !== null) {
			return this.user.token;
		}
	}
	getEmailid() {
		if (this.user !== null) {
			return this.user.emailId;
		}
	}
	getUserRole() {
		if (this.user !== null) {
			return this.user.userRole;
		}
	}
	getUserType() {
		if (this.user !== null) {
			return this.user.userType;
		}
	}
	getUserId() {
		if (this.user !== null) {
			return this.user.userId;
		}
	}
	getUserName() {
		if (this.user !== null) {
			return this.user.userName;
		}
	}
	logout() {
		localStorage.removeItem('User');
		localStorage.removeItem('setupTime');
		localStorage.removeItem('DashboardDailyCallAlert');
		window.location.replace('/Login');
	}
}

export default new Auth();
