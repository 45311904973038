import BaseUrl from '../../modules/config/BaseUrl';
import axios from '../../modules/helpers/axiosInstance';

const getdashboarddetailsbyuserid = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardMarketScopeDetailsByUserID?UserId=${UserId}`
	});
};
const getdashboarddsrsummerybyuserid = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardDSRDetailsByUserID?UserId=${UserId}`
	});
};
const getdashboardyearlysummerybyuserid = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardDSRDetailsYearlyByUserID?UserId=${UserId}`
	});
};
const getdashboradgraphdetailsbyuserid = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardDSRGraphDetailsByUserID?UserId=${UserId}`
	});
};
const getdashboradgraphmonthdetailsbyuserid = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetDashboardDSRGraphMonthDetailsByUserID?UserId=${UserId}`
	});
};

const getuserprofiledetails = (UserId) => {
	return axios({
		method: 'GET',
		url: `${BaseUrl.MainGateWayUrl}/Authentication/GetRegisterationDetails?UserId=${UserId}`
	});
};
const updateuserprofiledetails = (data) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Authentication/UpdateRegisterationDetails`,
		data: data
	});
};
const changepassword = (data, UserId) => {
	return axios({
		method: 'POST',
		url: `${BaseUrl.MainGateWayUrl}/Authentication/change-password/${UserId}`,
		data: data
	});
};

const alertForMonths = (UserId) => {
	return axios({
		method: "POST",
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/ARNApproachingTypeAlert?UserId=${UserId}`

	})
}
const alertForSingleMonth = (UserId) => {
	return axios({
		method: "POST",
		url: `${BaseUrl.MainGateWayUrl}/DailySalesReport/ARNMonthlyApproachingTypeAlert?UserId=${UserId}`
	})
}
const getsip_lumpsumtarget = (data) => {
	return axios({
		method: "POST",
		url: `${BaseUrl.MainGateWayUrl}/Dashboard/GetTargetDetailsByUserID`,
		data: data
	})
}
export {
	getdashboarddetailsbyuserid,
	getdashboarddsrsummerybyuserid,
	getdashboardyearlysummerybyuserid,
	getdashboradgraphdetailsbyuserid,
	getdashboradgraphmonthdetailsbyuserid,
	getuserprofiledetails,
	updateuserprofiledetails,
	changepassword,
	alertForMonths,
	alertForSingleMonth,
	getsip_lumpsumtarget
};
