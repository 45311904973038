import React from 'react';
import DailySalesReportContent from '../sections/DailySalesReport/DailySalesReportContent';

const DailySalesReport = () => {
	return (
		<div>
			<DailySalesReportContent />
		</div>
	);
};

export default DailySalesReport;
