const initialState = {
	indassociationsDetails: [],
	onindassochange: false
};
const topindassoreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ASSOCIATIONS':
			return {
				...state,
				indassociationsDetails: action.payload
			};
		case 'UPDATE_ASSOCIATIONS':
			return {
				...state,
				indassociationsDetails: action.payload
			};
		case 'CLEAR_ASSOCIATIONS':
			return {
				...state,
				indassociationsDetails: action.payload
			};
		default:
			return state;
	}
};

export default topindassoreducer;
