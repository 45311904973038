import React from 'react';
import home_icon from '../../assets/images/home-icon.png';
import marketscope_icon from '../../assets/images/marketscope-icon.png';
import dailyreport_icon from '../../assets/images/dailyreport-icon.png';
import calendar_icon from '../../assets/images/calendar-icon.png';
import { Link } from 'react-router-dom';
const SideNavigation = () => {
	return <div>
		<div className='sideNav__bg '>
			<div className='sideNav__icons'>
				<Link to="/dashboard"><img src={home_icon} alt="Home" className="img-fluid" title='Home' /></Link>
				<Link to="/marketscope"><img src={marketscope_icon} alt="Market Scope" className="img-fluid" title='Market Scope' /></Link>
				<Link to="/dailysalesreport"><img src={dailyreport_icon} alt="Daily Sales Report" title="Daily Sales Report" className="img-fluid" /></Link>
				<Link to="/arndsrstatusreport"><img src={calendar_icon} alt="Calendar" title="Calendar" className="img-fluid" /></Link>
			</div>
		</div>
	</div>;
};

export default SideNavigation;
