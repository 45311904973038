import React, { useState, useEffect } from 'react';
import {
	Paper,
	Typography,
	Grid,
	FormControl,
	Button,
	Container,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useSearchParams, useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import reset_password from '../../../assets/images/reset-password.png';
import LoadingScreen from '../../layouts/LoadingScreen';
import BaseUrl from '../../../modules/config/BaseUrl';
const ForgotPassword = () => {
	const [password, setPassword] = useState('');
	const [comPass, setComPass] = useState('');
	const [token, setToken] = useState('');

	const [errPassword, setErrPasword] = useState('');
	const [errComPass, setErrComPass] = useState('');

	const [showpassword, setShowPassword] = useState(false);
	const [showcnfpassword, setShowCnfPassword] = useState(false);
	const [loading, setLoading] = useState(false)
	let [searchParams] = useSearchParams();
	let navigate = useNavigate();
	/* start show hide password and cnfpassword */
	const handleClickShowPassword = () => {
		setShowPassword(!showpassword);
	};
	const handleClickCnfShowPassword = () => {
		setShowCnfPassword(!showcnfpassword);
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	/* end show hide password and cnfpassword */
	useEffect(() => {
		const user = searchParams.get('user');
		setToken(user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const Validate = () => {
		let Valid = true;
		const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		if (!password) {
			setErrPasword('Please Enter Password');
			Valid = false;
		} else if (!passregex.test(password)) {
			setErrPasword('Password must be a minimum 8 characters and must contain combination of Numbers, both Uppercase and lowercase letters and special character.');
			Valid = false;
		} else {
			setErrPasword('');
		}

		if (!comPass) {
			setErrComPass('Please enter confirm password');
			Valid = false;
		} else if (password !== comPass) {
			setErrComPass(`Password does not match`);
			Valid = false;
		} else {
			setErrComPass('');
		}

		return Valid;
	};

	const onReset = (e) => {
		e.preventDefault();
		const isValid = Validate();
		if (isValid) {
			let body = {
				resetToken: token,
				userPassword: password,
				confirmPassword: comPass
			};
			let headers = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			setLoading(true);
			axios
				.post(`${BaseUrl.MainGateWayUrl}/Authentication/reset-password`, body, headers)
				.then((res) => {
					setLoading(false);
					if (res.status === 200) {
						if (res.data.authenticationResponse.status === 1) {
							resetState();
							navigate('/Login');
							Swal.fire('Password reset successfully', '', 'success');
						} else {
							Swal.fire('Something went wrong', '', 'info');
						}
					}
				})
				.catch((err) => {
					setLoading(false);
					Swal.fire('Something went wrong', '', 'info');
					console.log('err in reset password', err);
				});
		}
	};

	const resetState = () => {
		setPassword('');
		setComPass('');
		setToken('');
	};
	return (
		<div className="verify-bg">
			{loading === true ? <LoadingScreen /> : null}
			<Container sx={{ marginTop: 10, display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
				<Paper sx={{ maxWidth: 500 }} elevation={10} className="verify-box">
					<img alt="reset password" src={reset_password} />
					<Typography variant="h4">Reset Password</Typography>

					<form>
						<Grid container spacing={2} marginTop={2}>
							{/* <Grid item xs={12} md={12}>
								<Typography>
									Your new password must be different from previously used passwords
								</Typography>
							</Grid> */}

							<Grid item xs={12} md={12}>
								<FormControl
									sx={{ m: 1, maxWidth: '25rem', marginBottom: '15px' }}
									variant="outlined"
									fullWidth
								>
									<InputLabel htmlFor="outlined-adornment-password" color="secondary">
										Password
									</InputLabel>
									<OutlinedInput
										variant="outlined"
										color="secondary"
										name="Password"
										value={password}
										onChange={(e) => { setPassword(e.target.value); setErrPasword('') }}
										autoComplete="on"
										type={showpassword ? 'text' : 'password'}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showpassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
										label="Password"
									/>
									{errPassword !== '' ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{errPassword}
										</div>
									) : null}
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormControl
									sx={{ m: 1, maxWidth: '25rem', marginBottom: '15px' }}
									variant="outlined"
									fullWidth
								>
									<InputLabel htmlFor="outlined-adornment-password" color="secondary">
										Confirm Password
									</InputLabel>
									<OutlinedInput
										name="Cnfpassword"
										value={comPass}
										onChange={(e) => { setComPass(e.target.value); setErrComPass('') }}
										variant="outlined"
										color="secondary"
										autoComplete="on"
										type={showcnfpassword ? 'text' : 'password'}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickCnfShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showcnfpassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
										label="Confirm Password"
									/>
									{errComPass !== '' ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{errComPass}
										</div>
									) : null}
								</FormControl>
							</Grid>
						</Grid>

						<Button
							size="large"
							variant="contained"
							color="primary"
							startIcon={<LockResetIcon />}
							onClick={onReset}
							sx={{ marginBottom: 2, marginTop: 2 }}
						>
							Reset
						</Button>
						<Typography className="normaltxt " sx={{ marginBottom: 2, marginTop: 2 }} onClick={() => navigate("/Login")}>
							Back to Login
						</Typography>
					</form>
				</Paper>
			</Container>
		</div>
	);
};

export default ForgotPassword;
