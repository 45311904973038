import React, { useState } from 'react';
import { Button, TextField, Grid } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { changepassword } from '../../Api/DashboardApi';
import Auth from '../../../modules/Auth/index';
import Swal from 'sweetalert2';
import LoadingScreen from '../../layouts/LoadingScreen';

const ChangePassword = () => {
	const UserId = Auth.getUserId();
	const [passwordDetails, setPasswordDetails] = useState({
		oldpassword: '',
		newpassword: '',
		cnfpassword: ''
	});
	const [FormErr, setFormErr] = useState({});
	const [loading, setLoading] = useState(false);
	const Validate = () => {
		const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		let valid = true;
		let formerr = {};
		if (passwordDetails.oldpassword.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === '') {
			formerr['oldpassword'] = 'Please enter old password';
			valid = false
		}
		if (passwordDetails.newpassword.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === '') {
			formerr['newpassword'] = 'Please enter new password';
			valid = false
		} else if (passwordDetails.newpassword === passwordDetails.oldpassword) {
			formerr['newpassword'] = 'Your new password must be different from old passwords.';
			valid = false
		} else if (passregex.test(passwordDetails.newpassword) === false) {
			formerr['newpassword'] =
				'Password must be a minimum 8 characters and must contain combination of Numbers, both Uppercase and lowercase letters and special character.';
			valid = false;
		}
		if (passwordDetails.cnfpassword.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') === '') {
			formerr['cnfpassword'] = 'Please enter confirm password';
			valid = false
		}

		if (
			passwordDetails.newpassword.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') !== '' &&
			passwordDetails.cnfpassword.trimStart().replace(/[^a-zA-Z0-9_@" "]/ig, '') !== '' &&
			passwordDetails.newpassword !== passwordDetails.cnfpassword
		) {
			formerr['cnfpassword'] = 'New password and confirm password does not match';
			valid = false
		}
		setFormErr(formerr);
		return valid;
	};
	const UpdatePassword = () => {
		let isvalid = Validate();
		if (isvalid) {
			let data = {
				confirmPassword: passwordDetails.cnfpassword,
				oldPassword: passwordDetails.oldpassword,
				userPassword: passwordDetails.newpassword
			};
			setLoading(true);
			changepassword(data, UserId)
				.then((res) => {
					if (res.data.changePasswordResponse.status === 1) {
						setPasswordDetails({
							oldpassword: '',
							newpassword: '',
							cnfpassword: ''
						});
						setLoading(false);
						Swal.fire('Password Change Successfully', '', 'success');
					} else {
						setLoading(false);
						Swal.fire('Old password is incorrect', '', 'error');
					}
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}
	};
	const handleChange = (e) => {
		let { value, name } = e.target;
		setPasswordDetails({ ...passwordDetails, [name]: value });
		if (name === 'oldpassword' && value !== null) {
			setFormErr({ ...FormErr, oldpassword: '' });
		}
		if (name === 'newpassword' && value !== null) {
			setFormErr({ ...FormErr, newpassword: '' });
		}
		if (name === 'cnfpassword' && value !== null) {
			setFormErr({ ...FormErr, cnfpassword: '' });
		}
	};
	return (
		<div>
			{loading === true ? <LoadingScreen /> : null}
			<Container fluid>
				<Row>
					<Col className="left">
						<h4>Change Password</h4>
						<div className="sub-heading-bg" />
					</Col>
				</Row>
				&nbsp;
				<Row>
					<Col>
						<div className="change_password_box">
							<Grid container spacing={3.5} >
								<Grid item xs={12} md={12}>
									&nbsp;

								</Grid>
								<Grid item xs={12} md={4} className="left" marginTop={1}>Old Password</Grid>
								<Grid item xs={12} md={8} className="left">
									<TextField
										type="text"
										id="outlined-basic"
										size="small"
										label="Old Password"
										variant="outlined"
										name="oldpassword"
										value={passwordDetails.oldpassword}
										onChange={handleChange}
									/>
									{FormErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{FormErr.oldpassword}
										</div>
									) : null}
								</Grid>
								<Grid item xs={6} md={4} className="left" marginTop={1}>New Password</Grid>
								<Grid item xs={12} md={8} className="left">
									<TextField
										type="text"
										id="outlined-basic"
										size="small"
										label="New Password"
										variant="outlined"
										name="newpassword"
										value={passwordDetails.newpassword}
										onChange={handleChange}
									/>
									{FormErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{FormErr.newpassword}
										</div>
									) : null}
								</Grid>
								<Grid item xs={6} md={4} className="left" marginTop={1}>Confirm Password</Grid>
								<Grid item xs={12} md={8} className="left">
									<TextField
										type="text"
										id="outlined-basic"
										size="small"
										label="Confirm Password"
										variant="outlined"
										name="cnfpassword"
										value={passwordDetails.cnfpassword}
										onChange={handleChange}
									/>
									{FormErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{FormErr.cnfpassword}
										</div>
									) : null}
								</Grid>
								<Grid item xs={12} md={12} marginBottom={2}>
									<Button variant="contained" className='btn' onClick={UpdatePassword}>
										Update
									</Button>
								</Grid>
							</Grid>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ChangePassword;
