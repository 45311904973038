import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Grid, FormControl, TextField, Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import verify_icon from '../../../assets/images/2.png';
import LoadingScreen from '../../layouts/LoadingScreen'
import axios from 'axios';
import Swal from 'sweetalert2';
import BaseUrl from '../../../modules/config/BaseUrl';
const VerifyEmail = () => {
	const navigate = useNavigate();
	const [otp, setOtp] = useState('');
	const [searchparams, setSearchparams] = useState('');
	const [loading, setLoading] = useState(false);
	let [params] = useSearchParams();

	const handleotp = (e) => {
		setOtp(e.target.value);
	};
	useEffect(() => {
		const user = params.get('user');
		setSearchparams(user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleVerify = () => {
		let verifydata = {
			token: searchparams,
			otp: otp
		};
		let headers = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		setLoading(true)
		axios
			.post(`${BaseUrl.MainGateWayUrl}/Authentication/verify-email_mobile-no`, verifydata, headers)
			.then((res) => {
				setLoading(false);
				if (res.data.authenticationResponse.status === 1) {
					Swal.fire({
						icon: 'success',
						title: 'Your email and mobile number are verified successfully',
						confirmButtonText: 'Ok'
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							navigate('/Login');
						}
					});
				} else {
					setLoading(false);
					Swal.fire('Something went wrong', '', 'info');
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				Swal.fire('Something went wrong', '', 'info');
			});
	};
	const handleResendotp = () => {
		let verifydata = {
			token: searchparams
		};
		let headers = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		axios
			.post(`${BaseUrl.MainGateWayUrl}/Authentication/resend-otp`, verifydata, headers)
			.then((res) => {
				if (res.data.authenticationResponse.status === 1) {
					Swal.fire('Code sent to your registered mobile number', '', 'success');
				} else {
					Swal.fire('Something went wrong', '', 'info');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<div className="verify-bg">
			{loading === true ? <LoadingScreen /> : null}
			<Container sx={{ marginTop: 10, display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
				<Paper sx={{ maxWidth: 500 }} elevation={10} className="verify-box">
					<form>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12}>
								<img alt="verify-icon" src={verify_icon} />
							</Grid>
							<Grid item xs={12} md={12}>
								<Typography variant="h5">Verify Your Account</Typography>
							</Grid>
							<Grid item xs={12} md={12}>
								<Typography>
									The verification code has been sent to your mobile number.<br />
									Enter the code below to confirm.
								</Typography>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormControl sx={{ m: 1, maxWidth: '20rem' }} fullWidth>
									<TextField
										variant="outlined"
										color="primary"
										type="number"
										placeholder="Enter code"
										label="Code"
										name="OTP"
										value={otp}
										onChange={handleotp}
									/>
									{/* {newUserErr ? (
										<div className="mt-2" style={{ fontSize: 12, color: 'red' }}>
											{newUserErr.ARNcode}
										</div>
									) : null} */}
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12}>
								<Button
									size="large"
									variant="contained"
									color="primary"
									// startIcon={<HowToRegIcon />}
									onClick={handleVerify}
								>
									Submit
								</Button>
							</Grid>
							<Grid item xs={12} md={12}>
								<Typography className="normaltxt" sx={{ cursor: 'pointer' }} onClick={handleResendotp}>
									Didn't get the code? Resend Code.
								</Typography>
								<Typography
									className="normaltxt"
									sx={{ cursor: 'pointer' }}
									onClick={() => navigate('/Login')}
								>
									Go to Login Page.
								</Typography>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</Container>
		</div>
	);
};

export default VerifyEmail;
