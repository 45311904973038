import React, { useState, useEffect } from 'react'
import TopNavigation from '../../layouts/TopNavigation';
import SideNavigation from '../../layouts/SideNavigation';
import { Grid, FormControl, Button, IconButton, Typography } from '@mui/material'
import moment from 'moment';
import Select from 'react-select';
import { Lumpsum_Sip, Status } from '../../../modules/helpers/constant';
import DatePicker from 'react-datepicker';
import No_data from '../../../assets/images/no_data1.jpg'
import { dsrDailyReportDateRange } from '../../Api/DailySalesReportApi';
import LoadingScreen from '../../layouts/LoadingScreen';
import { Preview } from '@mui/icons-material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Box } from '@mui/system';
import { exportExcel } from '../../../modules/helpers/exportExcel';
import { exportPDF } from '../../../modules/helpers/exportPdf'
import Auth from '../../../modules/Auth';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';

const TotalLeadsContent = () => {
    const UserId = Auth.getUserId();


    const [siplumpsumf, setSiplumpsumf] = useState([]);
    const [statusf, setStatusf] = useState([])
    const [fromdatef, setFromdatef] = useState(null);
    const [todatef, setTodatef] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = useState({});
    const [errors, setErrors] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [count, setCount] = useState(0)
    useEffect(() => {
        GetDailyReportDetails();
        // eslint-disable-next-line
    }, [pageNo])
    const handleDownloadExcel = async () => {
        setLoading(true)
            let data = {
                userId: UserId,
                investmentType: siplumpsumf.value === undefined ? "" : siplumpsumf.value,
                status: statusf.value === undefined ? "" : statusf.value,
                startDate: fromdatef === null ? "" : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
                endDate: todatef === null ? "" : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
                pageNo:pageNo,
                PageSize:5,
                type: "Download",
            }
            dsrDailyReportDateRange(data).then((res) => {
                setLoading(true)
                if (res.data.response.status === 1) {
                    let result = res.data.response.data.history;
                    let table1 = result.map((S, ind) => {
                        return {
                            amount: `₹${new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2
                            }).format(Number(S.amount))}`,
                            businessSourcedOrExpected: S.businessSourcedOrExpected,
                            callType: S.callType,
                            clientName: S.clientName,
                            contactNumber: S.contactNumber,
                            date: moment(S.date).format('DD-MM-yyyy'),
                            investmentType: S.investmentType,
                            investorType: S.investorType,
                            nextMeetingDate: moment(S.nextMeetingDate).format('DD-MM-yyyy'),
                            numberOfSip: S.investmentType === 'SIP' ? S.numberOfSip : '-',
                            totalsipamt: S.investmentType === 'SIP' ?
                                `₹${new Intl.NumberFormat('en-IN', {
                                    minimumFractionDigits: 2
                                }).format(Number(S.amount * S.numberOfSip))}` :
                                '-',
                            productsPitched: S.productsPitched.label,
                            source: S.source.label,
                            remarks: S.remarks.value === 3 ? S.otherRemark : S.remarks.label,
                            scheme: S.scheme.label,
                            status: S.status,
                            submitDate: moment(S.submitDate).format('DD-MM-yyyy'),
                            time: S.time,
                            typesOfFunds: S.typesOfFunds,
                            // userArnCode: S.userArnCode,
                            // userMobileNo: S.userMobileNo,
                            // userName: S.userName,
                            supervisorName: S.supervisor.value
                        };
                    })
            
                    let columns = [
            
                        // { header: 'Agent mobile no.', key: 'userMobileNo', width: 12 },
                        { header: 'Client name', key: 'clientName', width: 30 },
                        { header: 'Contact number', key: 'contactNumber', width: 17 },
                        { header: 'Time', key: 'time', width: 15 },
                        { header: 'Investor Type', key: 'investorType', width: 30 },
                        { header: 'Date', key: 'date', width: 15 },
                        { header: 'Call type', key: 'callType', width: 30 },
                        { header: 'Next meeting date', key: 'nextMeetingDate', width: 15 },
                        { header: 'Product pitched', key: 'productsPitched', width: 15 },
                        { header: 'Source', key: 'source', width: 15 },
                        { header: 'Type of fund', key: 'typesOfFunds', width: 15 },
                        { header: 'Busi. Sourced / Expected', key: 'businessSourcedOrExpected', width: 10 },
                        { header: 'Amount', key: 'amount', width: 15 },
                        { header: 'Total SIP Amt', key: 'totalsipamt', width: 15 },
                        { header: 'No. of SIP', key: 'numberOfSip', width: 15 },
                        { header: 'Scheme', key: 'scheme', width: 30 },
                        { header: 'Lumpsum/SIP', key: 'investmentType', width: 15 },
                        { header: 'Status', key: 'status', width: 15 },
                        { header: 'Remark', key: 'remarks', width: 15 },
                        { header: 'Supervisor name', key: 'supervisorName', width: 30 },
                    ]
                    exportExcel('Daily Sales Report', columns, table1, 'dsrreport');
                } else {
                    Swal.fire("Please try again","Something went wrong","error")
                }
            }).catch((err) => {
                Swal.fire("Please try again","Something went wrong","error")
                setLoading(false);
                console.log(err)
            })
    }
    const downloadpdf = () => {
           setLoading(true)
            let data = {
                userId: UserId,
                investmentType: siplumpsumf.value === undefined ? "" : siplumpsumf.value,
                status: statusf.value === undefined ? "" : statusf.value,
                startDate: fromdatef === null ? "" : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
                endDate: todatef === null ? "" : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
                pageNo: pageNo, 
                PageSize:5,
                type: "Download",
            }
            dsrDailyReportDateRange(data).then((res) => {
                setLoading(false);
                if (res.data.response.status === 1) {
                    let result = res.data.response.data.history;
                    let table1 = result.map((S, ind) => {
                        return {
                            sr_no: ind + 1,
                            amount: `${new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2
                            }).format(Number(S.amount))}`,
                            businessSourcedOrExpected: S.businessSourcedOrExpected,
                            callType: S.callType,
                            clientName: S.clientName,
                            contactNumber: S.contactNumber,
                            date: moment(S.date).format('DD-MM-yyyy'),
                            investmentType: S.investmentType,
                            investorType: S.investorType,
                            nextMeetingDate: moment(S.nextMeetingDate).format('DD-MM-yyyy'),
                            numberOfSip: S.investmentType === 'SIP' ? S.numberOfSip : '-',
                            totalsipamt: S.investmentType === 'SIP' ?
                                `${new Intl.NumberFormat('en-IN', {
                                    minimumFractionDigits: 2
                                }).format(Number(S.amount * S.numberOfSip))}` :
                                '-',
                            productsPitched: S.productsPitched.label,
                            source: S.source.label,
                            remarks: S.remarks.value === 3 ? S.otherRemark : S.remarks.label,
                            scheme: S.scheme.label,
                            status: S.status,
                            submitDate: moment(S.submitDate).format('DD-MM-yyyy'),
                            time: S.time,
                            typesOfFunds: S.typesOfFunds,
                            // userMobileNo: S.userMobileNo,
                            supervisorName: S.supervisor.value
                        };
                    })
                    let head = [
                        {
                            sr_no: 'Sr. No.',
                            // userMobileNo: 'Agent mobile no.',
                            clientName: 'Client name',
                            contactNumber: 'Contact number',
                            time: 'Time',
                            investorType: 'Investor Type',
                            date: 'Date',
                            callType: 'Call type',
                            nextMeetingDate: 'Next meeting date',
                            productsPitched: 'Product pitched',
                            source: 'Source',
                            typesOfFunds: 'Type of fund',
                            businessSourcedOrExpected: 'Busi. Sourced / Expected',
                            amount: 'Amount',
                            totalsipamt: 'Total SIP Amt',
                            numberOfSip: 'No. of SIP',
                            scheme: 'Scheme',
                            investmentType: 'Lumpsum/SIP',
                            status: 'Status',
                            remarks: 'Remark',
                            supervisorName: 'Supervisor name',
                        },
                    ]
            
                    exportPDF(head, table1, 'daily sales report', 'Daily Sales Report');
                } 
                else {
                    Swal.fire("Please try again","Something went wrong","error");
                 
                }
            }).catch((err) => {
                Swal.fire("Please try again","Something went wrong","error");
                setLoading(false);
                console.log(err)
            })
    }

    const GetDailyReportDetails = (search) => {
      
        let valid = validate();
        if (valid) {
            if(search !== undefined){
                setPageNo(search)
            }
            setLoading(true)
            let data = {
                userId: UserId,
                investmentType: siplumpsumf.value === undefined ? "" : siplumpsumf.value,
                status: statusf.value === undefined ? "" : statusf.value,
                startDate: fromdatef === null ? "" : `${moment(fromdatef).format('yyyy-MM-DD')} 00:00:00`,
                endDate: todatef === null ? "" : `${moment(todatef).format('yyyy-MM-DD')} 23:59:59`,
                pageNo:search  === undefined ? pageNo  : search,
                PageSize:5,
                type: "Pagination",
            }
            dsrDailyReportDateRange(data).then((res) => {
                if (res.data.response.status === 1) {
                    let result = res.data.response.data;
                    setCount(result.count);
                    setData(result.history);
                } else {
                    setData([]);
                    setCount(0);
                }
                setLoading(false);
            }).catch((err) => {
                setData([]);
                setCount(0);
                setLoading(false);
                console.log(err)
            })
        }
    }
    const ClearFilter = () => {
        setSiplumpsumf([]);
        setStatusf([]);
        setFromdatef(null);
        setTodatef(null);
        setErrors({});
        setPageNo(1);
        setCount(0);
        setLoading(true)
        let data = {
            userId: UserId,
            investmentType: "",
            status: "",
            startDate: "",
            endDate: "",
            pageNo:1,
            PageSize:5,
            type: "Pagination",
        }
        dsrDailyReportDateRange(data).then((res) => {
            if (res.data.response.status === 1) {
                let result = res.data.response.data;
                setCount(result.count);
                setData(result.history);
            } else {
                setData([]);
                setCount(0);
            }
            setLoading(false);
        }).catch((err) => {
            setData([]);
            setCount(0);
            setLoading(false);
            console.log(err)
        })
    }
    const handlePreview = (data) => {
        setPreviewData(data);
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        setPreviewData({});
    }
    const validate = () => {
        let valid = true;
        let error = {};
        if (fromdatef == null && todatef != null) {
            error['fromdatef'] = 'Please select from date';
            valid = false;
        }
        if (todatef == null && fromdatef != null) {
            error['todatef'] = 'Please select to date';
            valid = false;
        }
        setErrors(error);
        return valid;

    }
    const handlePageChange = (pageNumber) =>  {
        // console.log(`active page is ${pageNumber}`);
        setPageNo(pageNumber);
      }
    return (
        <div><TopNavigation />
            <SideNavigation />
            <div>


                {loading === true ? <LoadingScreen /> : null}
                <Grid container spacing={2} padding={3} rowSpacing={3} paddingLeft={'80px'}>
                    <Grid item xs={12} md={12} textAlign={'left'}>
                        <Link to='/dailysalesreport'>
                            <Button startIcon={<ChevronLeftIcon />} color="warning" >
                                Back to Daily Sales Report
                            </Button></Link>
                    </Grid>
                    <Grid item xs={6} md={6} alignContent="flex-start">
                        <Typography variant="h4" className="sub-heading">View Daily Reports With Date Range</Typography>
                    </Grid>

                    <Grid item md={6} xs={6} className={'right'}>
                        <div>
                            <Button onClick={handleDownloadExcel} variant="contained" className='p-2' disabled={data.length === 0}>download excel</Button>
                            <Button
                                className='p-2 m-2'
                                variant="contained"
                                onClick={() => downloadpdf()}
                                disabled={data.length === 0}
                            >
                                Download PDF
                            </Button>

                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} className={'p-4'}>

                        <Grid container spacing={2} padding={0} rowSpacing={1.5} className={'admin__search-bar'}>

                            {/* <Grid item xs={12} md={2.4}>
                            <FormControl fullWidth className="textfield view-textfield">
                                <label>ARN Code</label>
                                <Select
                                    value={arnf}
                                    options={arnOption}
                                    onChange={(selected) => {
                                        setArnf(selected);
                                    }}
                                />
                            </FormControl>

                        </Grid> */}
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth className="textfield view-textfield">
                                    <label>Lumpsum/SIP</label>
                                    <Select
                                        value={siplumpsumf}
                                        options={Lumpsum_Sip}
                                        onChange={(selected) => {
                                            setSiplumpsumf(selected);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth className="textfield view-textfield">
                                    <label>Status</label>
                                    <Select
                                        value={statusf}
                                        options={Status}
                                        onChange={(selected) => {
                                            setStatusf(selected);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth className="textfield">
                                    <label>From Date</label>
                                    <DatePicker
                                        className="textfield"
                                        placeholderText="dd-mm-yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        maxDate={new Date()}
                                        // minDate={new Date(startDate)}
                                        onChange={(date) => {
                                            setFromdatef(date);
                                            setTodatef(new Date());
                                            setErrors({ ...errors, fromdatef: '' });
                                        }}
                                        dateFormat="dd-MM-yyyy"
                                        name="startdate"
                                        autoComplete="off"
                                        selected={fromdatef}
                                        withPortal
                                    />
                                </FormControl>
                                {errors ? (
                                    <div style={{ fontSize: 12, color: 'red' }}>
                                        {errors.fromdatef}
                                    </div>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth className="textfield">
                                    <label>To Date</label>
                                    <DatePicker

                                        className="textfield"
                                        placeholderText="dd-mm-yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(fromdatef)}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            setTodatef(date);
                                            setErrors({ ...errors, todatef: '' });
                                        }}
                                        withPortal
                                        dateFormat="dd-MM-yyyy"
                                        name="startdate"
                                        autoComplete="off"
                                        selected={todatef}
                                    />
                                </FormControl>
                                {errors ? (
                                    <div style={{ fontSize: 12, color: 'red' }}>
                                        {errors.todatef}
                                    </div>
                                ) : null}
                            </Grid>
                            <Grid item md={12} xs={12} className={'center p-t-5'}>
                                <Button variant="contained" className='btn-grad ' onClick={() => GetDailyReportDetails(1)} >Search</Button>
                                <Button variant="contained" className='btn-grad' onClick={() => ClearFilter()} >Clear</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {data.length !== 0 ? 
                    <Grid item xs={12} md={12}>
                        <div className="overflow-admin">
                            <table id="dsr" border={1} >
                                <thead>
                                    <tr className="thead">
                                        <td rowSpan={2}>Sr. No.</td>
                                        <td rowSpan={2} className="p-r-70">Date</td>
                                        {/* <td rowSpan={2} className="p-r-100">Time</td> */}
                                        {/* <td rowSpan={2}>Investor Type</td> */}
                                        <td rowSpan={2} className="p-r-100">Client Name</td>
                                        <td rowSpan={2}>Call Type</td>
                                        <td rowSpan={2}>Contact no.</td>
                                        {/* <td rowSpan={2}>
                    Products <br />Pitched
                  </td> */}         <td rowSpan={2}>Source</td>
                                        <td rowSpan={2}>Next Meeting</td>
                                        <td rowSpan={2}>
                                            Busi. Sourced<br />/Expected
                                        </td>
                                        <td colSpan={4} style={{ textAlign: 'center' }}>
                                            Details of Business
                                        </td>
                                        <td rowSpan={2}>Status</td>
                                        {/* <td rowSpan={2}>Remarks</td> */}
                                        <td rowSpan={2}>Action</td>
                                    </tr>
                                    <tr className="thead2">
                                        <td>Amount</td>
                                        <td>Total SIP Amt</td>
                                        <td>No. of SIP</td>
                                        {/* <td className="p-r-100">Scheme</td> */}
                                        <td>Lumpsum/SIP</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data &&
                                        data.map((dailysreport, ind) => (
                                            <tr key={ind} className={dailysreport.show === 0 ? "showRed" : null} >
                                                <td>{(ind + 1) +( (pageNo * 5) - (5) )}</td>
                                                <td>{moment(dailysreport.date).format('DD-MM-yyyy')}</td>
                                                {/* <td>{dailysreport.time}</td> */}
                                                {/* <td>{dailysreport.investorType}</td> */}
                                                <td>{dailysreport.clientName}</td>
                                                <td>{dailysreport.callType}</td>
                                                <td>{dailysreport.contactNumber}</td>
                                                {/* <td>{dailysreport.productsPitched.label}</td> */}
                                                <td>{dailysreport.source.label}</td>
                                                <td>{moment(dailysreport.nextMeetingDate).format('DD/MM/yyyy')}</td>
                                                <td>{dailysreport.businessSourcedOrExpected}</td>
                                                <td className='right'>{`₹${new Intl.NumberFormat('en-IN', {
                                                    minimumFractionDigits: 2
                                                }).format(Number(dailysreport.amount))}`}</td>
                                                <td className='right'>
                                                    {dailysreport.investmentType === 'SIP' ? (
                                                        `₹${new Intl.NumberFormat('en-IN', {
                                                            minimumFractionDigits: 2
                                                        }).format(Number(dailysreport.amount * dailysreport.numberOfSip))}`
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>
                                                <td>
                                                    {dailysreport.investmentType === 'SIP' ? dailysreport.numberOfSip : '-'}
                                                </td>
                                                {/* <td >{dailysreport.scheme.label}</td> */}
                                                <td>{dailysreport.investmentType}</td>
                                                <td>
                                                    <p className={dailysreport.status === 'Open' ? 'open' : 'closed'}>
                                                        {dailysreport.status}
                                                    </p>
                                                </td>
                                                {/* <td>
                        {dailysreport.remarks.value === 3 ? (
                          dailysreport.otherRemark
                        ) : (
                          dailysreport.remarks.label
                        )}
                      </td> */}
                                                <td className="icons-wrap">
                                                    <IconButton
                                                        color="primary"
                                                        title='Preview'
                                                        onClick={() => handlePreview(dailysreport)}
                                                    >
                                                        <Preview />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <Grid item md={12} xs={12} className={'center p-t-5'}>
                        <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pageNo}
                        itemsCountPerPage={5}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        prevPageText="Previous"
                        nextPageText="Next"
                        onChange={handlePageChange}
                        />
                        </Grid>
                    </Grid> : <img src={No_data} className="no_data" alt="no data"/>}

                </Grid>
                {show === true ?
                    <div>
                        <Modal size='lg' isOpen={show} toggle={handleClose}>
                            <ModalHeader toggle={handleClose} className="modal-hd">
                                DSR Preview
                            </ModalHeader>
                            <ModalBody>
                                <Box>
                                    <form>
                                        <Grid container padding={2} spacing={0} rowSpacing={1.5} columnSpacing={0} className="table-bordered" marginTop={2}>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                {' '}
                                                <b>Time : </b><br />
                                                {previewData.time}

                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                {' '}
                                                <b>Investor Type :</b> <br /> {previewData.investorType}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b>  Client Name : </b><br />
                                                {previewData.clientName}

                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Contact Number :</b><br />
                                                {previewData.contactNumber}
                                            </Grid>

                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Call Type : </b><br />
                                                {previewData.callType}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                {' '}
                                                <b>Next Meeting Date : </b><br />
                                                {moment(previewData.nextMeetingDate).format('DD/MM/yyyy')}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Products Pitched :</b> <br />
                                                {previewData.productsPitched.label}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Source :</b> <br />
                                                {previewData.source.label}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                {' '}
                                                <b> Business Sourced/Expected :</b> <br />
                                                {previewData.businessSourcedOrExpected}
                                            </Grid>

                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Amount :</b> <br />{' '}
                                                {`₹${new Intl.NumberFormat('en-IN', {
                                                    minimumFractionDigits: 2
                                                }).format(Number(previewData.amount))}`}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Type of Fund : </b><br />
                                                {previewData.typesOfFunds}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Scheme :</b><br />
                                                {previewData.scheme.label}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <div> <b>  Lumpsum/SIP :</b> <br />
                                                    {previewData.investmentType}
                                                </div>

                                            </Grid>
                                            {previewData.investmentType === 'SIP' ? (<Grid item xs={12} md={6} className={'divider'}>
                                                <div><b> No. of SIP :</b> <br />
                                                    {previewData.numberOfSip}</div>
                                            </Grid>) : null}


                                            <Grid item xs={12} md={6} className={'divider'}>
                                                <b> Status : </b><br />
                                                {previewData.status}
                                            </Grid>
                                            <Grid item xs={12} md={6} className={previewData.investmentType === 'SIP' ? "" : 'divider'}>
                                                <b>Remarks : </b><br />
                                                {previewData.remarks.value === 3 ? (
                                                    previewData.otherRemark
                                                ) : (
                                                    previewData.remarks.label
                                                )}
                                            </Grid>


                                        </Grid>
                                    </form>
                                </Box>
                            </ModalBody>
                            <ModalFooter>
                                <div>
                                    <Button variant="contained" className="btn-grad" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    </div> : null}
            </div>
        </div>
    )
}

export default TotalLeadsContent