import React from 'react';
import MarketScopeContent from '../sections/MarketScope/MarketScopeContent';

const MarketScope = () => {
	return (
		<div>
			<MarketScopeContent />
		</div>
	);
};

export default MarketScope;
