import React from 'react'
import AdminTopNavigation from '../layouts/AdminTopNavigation'
import AdminMandSixMReviewReportContent from '../sections/AdminMandSixMReviewReport/AdminMandSixMReviewReportContent'

const AdminMandSixMreviewReport = () => {
    return (
        <div>
            <AdminTopNavigation />
            <AdminMandSixMReviewReportContent />
        </div>
    )
}

export default AdminMandSixMreviewReport