import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
/*default*/
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import ForgotPassword from '../sections/Login/ForgotPassword';
import VerifyEmail from '../sections/Login/VerifyEmail';
import Comingsoon from '../pages/Comingsoon';
/*conditon for route */
import AfterloginUserRoute from './AfterloginUserRoute';
import AfterloginSupervisorRoute from './AfterloginSupervisorRoute';
import AfterloginAdminRoute from './AfterloginAdminRoute';
/*user */
import MarketScope from '../pages/MarketScope';
import UserProfile from '../pages/UserProfile';
import Dashboard from '../pages/Dashboard';
import DailySalesReport from '../pages/DailySalesReport';
import ChangePassword from '../sections/UserProfile/ChangePassword';
import DSRStatus from '../pages/DSRStatus';
import TotalLeads from "../pages/TotalLeads"
import ScopePoints from '../pages/ScopePoints';
/*supervisor */
import SupervisorDashboard from '../pages/SupervisorDashboard';
import SupervisorUserManagement from '../pages/SupervisorUserManagement';
import SupervisorReportsManagement from '../pages/SupervisorReportsManagement';
import SupervisorMarketScopeReport from '../pages/SupervisorMarketScopeReport';
import MonthlyReview from '../pages/MonthlyReview';
import SupervisorMandSixMreviewReport from '../pages/SupervisorMandSixMreviewReport';
import SupervisorArnDSRStatusReport from '../pages/SupervisorArnDSRStatusReport';

/*Admin */
import AdminDashboard from '../pages/AdminDashboard';
import AdminUserManagement from '../pages/AdminUserManagement';
import AdminSupervisorManagement from '../pages/AdminSupervisorManagement';
import AdminReportsManagement from '../pages/AdminReportsManagement';
import AdminMarketScopeReport from '../pages/AdminMarketScopeReport';
import AdminMandSixMreviewReport from '../pages/AdminMandSixMreviewReport';
import AdminArnDSRStatusReport from '../pages/AdminArnDSRStatusReport';
import AdminMasterManage from '../pages/AdminMasterManage'
import Auth from '../../modules/Auth';

const Content = () => {
	const userRole = Auth.getUserRole();
	return (
		<Routes>
			<Route>
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Registration />} />
				<Route path="/Subscriber/verify-email" element={<VerifyEmail />} />
				<Route path="/Subscriber/reset-password" element={<ForgotPassword />} />
				<Route path="/comingsoon" element={<Comingsoon />} />
			</Route>

			{userRole === 1 ? (
				<Route element={<AfterloginUserRoute />}>
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/marketscope" element={<MarketScope />} />
					<Route path="/dailysalesreport" element={<DailySalesReport />} />
					<Route path="/userprofile" element={<UserProfile />} />
					<Route path="/changepassword" element={<ChangePassword />} />
					<Route path="/arndsrstatusreport" element={<DSRStatus />} />
					<Route path="/dailyreportfilter" element={<TotalLeads />} />
					<Route path="/scopepoints" element={<ScopePoints />} />
					<Route path="*" element={<Navigate to="/dashboard" replace />} />
				</Route>
			) : userRole === 3 ? (
				<Route element={<AfterloginSupervisorRoute />}>
					<Route path="/supervisordashboard" element={<SupervisorDashboard />} />
					<Route path="/supervisorusermanagement" element={<SupervisorUserManagement />} />
					<Route path="/supervisorreportmanagement" element={<SupervisorReportsManagement />} />
					<Route path="/supervisormsreportsmanagement" element={<SupervisorMarketScopeReport />} />
					<Route path="/monthlyreview" element={<MonthlyReview />} />
					<Route path="/supervisormandsixmreviewreport" element={<SupervisorMandSixMreviewReport />} />
					<Route path="/supervisorarndsrstatusreport" element={<SupervisorArnDSRStatusReport />} />
					<Route path="*" element={<Navigate to="/supervisordashboard" replace />} />
				</Route>
			) : userRole === 2 ?
				(<Route element={<AfterloginAdminRoute />}>
					<Route path="/admindashboard" element={<AdminDashboard />} />
					<Route path="/adminusermanagement" element={<AdminUserManagement />} />
					<Route path="/adminsupervisormanagement" element={<AdminSupervisorManagement />} />
					<Route path="/adminreportsmanagement" element={<AdminReportsManagement />} />
					<Route path="/adminmsreportsmanagement" element={<AdminMarketScopeReport />} />
					<Route path="/adminmandsixmreviewreport" element={<AdminMandSixMreviewReport />} />
					<Route path="/adminarndsrstatusreport" element={<AdminArnDSRStatusReport />} />
					<Route path="/adminmastermanagement" element={<AdminMasterManage />} />
					<Route path="*" element={<Navigate to="/admindashboard" replace />} />
				</Route>) : null
			}
			<Route path="*" element={<Navigate to="/login" replace />} />
		</Routes>
	);
};

export default Content;
