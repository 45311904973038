const initialState = {
	influencersDetails: [],
	oninfluencerschange: false
};
const topinfluencersreducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_INFLUENCERS':
			return {
				...state,
				influencersDetails: action.payload
			};
		case 'UPDATE_INFLUENCERS':
			return {
				...state,
				influencersDetails: action.payload
			};
		case 'CLEAR_INFLUENCERS':
			return {
				...state,
				influencersDetails: action.payload
			};
		default:
			return state;
	}
};

export default topinfluencersreducer;
