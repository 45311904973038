import React, { useState, useEffect } from 'react'
import { FormControl, Button, Grid } from '@mui/material';
import DatePicker from 'react-datepicker';
import { getalluserlist, activeinactiveuser, updateuserlist, getallsupervisor, uploadmodule } from '../../Api/AdminApi';
import LoadingScreen from '../../layouts/LoadingScreen';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import { exportExcel } from '../../../modules/helpers/exportExcel';
import Select from 'react-select';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Box } from '@mui/system';

const AdminUserManagementContent = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supervisoroption, setSupervisoroption] = useState([]);
  const [supervisorList, setSupervisorList] = useState();
  const [selsupervisor, setSelsupervisor] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [show, setShow] = useState(false);

  const [mdate, setMdate] = useState(new Date());
  const [yfromdate, setYfromdate] = useState(new Date());
  const [ytodate, setYtodate] = useState(new Date());
  const [file, setFile] = useState(null);
  const [error, setError] = useState({})
  function handleDownloadExcel() {
    let table1 = data.map((S, ind) => {
      return {
        userName: S.userName,
        userArnCode: S.userArnCode,
        sipTarget: Number(S.sipTarget),
        lumpsumTarget: Number(S.lumpsumTarget),
        nimfSip: Number(S.nimfSip),
        nimfLumpsum: Number(S.nimfLumpsum),
        sipAmount: Number(S.sipTotalAmount),
        lumpsumAmount: Number(S.lumpsumAmount),
        supervisorName: S.supervisorName,
      };
    })

    let columns = [
      { header: 'Agent name', key: 'userName', width: 22 },
      { header: 'ARN Code', key: 'userArnCode', width: 12 },
      { header: 'Monthly SIP Target', key: 'sipTarget', width: 21 },
      { header: 'Monthly Lumpsum Target', key: 'lumpsumTarget', width: 28 },
      { header: 'NIMF SIP Achivement', key: 'nimfSip', width: 25 },
      { header: 'NIMF Lumpsum Achivement', key: 'nimfLumpsum', width: 30 },
      { header: 'SIP Amount', key: 'sipAmount', width: 15 },
      { header: 'Lumpsum Amount', key: 'lumpsumAmount', width: 18 },
      { header: 'SuperVisor name', key: 'supervisorName', width: 30 }]

    exportExcel('Agent List', columns, table1, 'Agent')
  }
  useEffect(() => {
    SupervisorList();
    GetAllUserList();
  }, [startDate]);

  const GetAllUserList = async () => {
    setLoading(true);
    let date = moment(startDate).format('yyyy-MM-DD');
    getalluserlist(date).then((res) => {
      setLoading(false);
      if (res.data.response.status === 1) {
        let list = res.data.response.data.objUMList;
        setData(list);

        setFilterData(list);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("err in userlist get");
    })
  }
  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    let newArr = [...data];
    newArr[idx][name] = value;
    setData(newArr);
  };
  const handleSectorchange = (e, idx) => {
    let find_user = supervisoroption.find((f) => f.supervisorId === Number(e.target.value))
    let selected = e.target.value;
    let newArr = [...data];
    let name = 'supervisorId';
    newArr[idx][name] = selected;
    newArr[idx]["supervisorName"] = find_user.supervisorName;
    newArr[idx]["supervisorMobileNo"] = find_user.supervisorMobileNo;
    setData(newArr);

  };
  const UserActiveDeActive = (userid, act, idx) => {
    setLoading(true);
    let newArr = [...data];
    let userData = {
      userId: userid,
      active: 1 === act ? 0 : 1
    }
    activeinactiveuser(userData).then((res) => {
      setLoading(false);
      if (res.data.response.status === 1) {
        newArr[idx]["active"] = 1 === act ? 0 : 1;
        setData(newArr);
        Swal.fire(res.data.response.message, "", "success");
      }
    }).catch((err) => {
      setLoading(false);
      console.log("err active and inactive")
    })
  }

  const UpdateUserDetails = () => {
    setLoading(true);
    let date = moment(startDate).format('yyyy-MM-DD');
    let userlistdata = data.map((S, ind) => {
      return {
        lumpsumTarget: Number(S.lumpsumTarget),
        sipTarget: Number(S.sipTarget),
        nimfLumpsum: Number(S.nimfLumpsum),
        nimfSip: Number(S.nimfSip),
        userId: S.userId,
        Date: date,
        supervisorId: Number(S.supervisorId)
      };
    })
    updateuserlist(userlistdata).then((res) => {
      setLoading(false);
      if (res.data.response.status === 1) {
        Swal.fire("User list updated successfully", "", "success")
      }
    }).catch((err) => {
      setLoading(false);
      console.log("err in update userlist")
    })
  }

  // function compareTwoDates(first, second) {
  //   const firstDate = `${moment(first).format("M")}-${moment(first).format("YYYY")}`;
  //   const secondDate = `${moment(second).format("M")}-${moment(second).format("YYYY")}`;
  //   return firstDate !== secondDate
  // }
  // function NIMFcompareTwoDates(first, second) {
  //   const firstDate = `${moment(first).format("M")}-${moment(first).format("YYYY")}`;
  //   const secondDate = `${moment(second).format("M")}-${moment(second).format("YYYY")}`;

  //   return firstDate === secondDate
  // }

  const SupervisorList = () => {
    getallsupervisor().then((res) => {
      if (res.data.authenticationResponse.status === 1) {

        let result = res.data.authenticationResponse.data.supervisor;
        setSupervisoroption(result);

        let resultSelect = result.map((f) => {
          return { label: f.supervisorName, value: f.supervisorId, phoneNo: f.supervisorMobileNo };
        });
        setSupervisorList(resultSelect)
      } else {
        setSupervisoroption([]);
      }
    }).catch((err) => {
      setSupervisoroption([]);
      console.log(err, "err in supervisor get")
    })
  }

  const handleSupervisor = (selected, e) => {
    setSelsupervisor(selected)

    let filterSupervisor = selected == null ? filterData : filterData.filter((f) => f.supervisorId === selected.value)
    setData(filterSupervisor)

  }
  const handleShow = () => {
    setShow(true);
  }
  const handleClose = () => {
    setFile(null);
    setMdate(new Date());
    setYfromdate(new Date());
    setYtodate(new Date());
    setError({})
    setShow(false);
  }
  const validate = () => {
    let valid = true;
    let formError = {};
    if (file === null) {
      valid = false;
      formError['file'] = 'Please select excel file.';
    }
    setError(formError);
    return valid;
  }
  const moduleSubmit = () => {
    const valid = validate();
    if (valid) {
      let y = ytodate.getFullYear();
      let  m = ytodate.getMonth();
      let lastDay = new Date(y, m + 1, 0);
      const formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileName', file.name);
      formData.append('yearFromDate', `${moment(yfromdate).format('yyyy-MM-DD')} 00:00:00`);
      formData.append('yearToDate', `${moment(lastDay).format('yyyy-MM-DD')} 23:59:59`);
      formData.append('monthDate', `${moment(mdate).format('yyyy-MM-')}01 00:00:00`);
      setLoading(true);
      uploadmodule(formData).then((res) => {
          setLoading(false);
          let result = res.data.response;
        if(res.data.response.status === 1){
        handleClose();
        Swal.fire("", result.message, "success");
        }else{
          Swal.fire("", result.message, "error");
        }
        
      }).catch((err) => {
        setLoading(false);
        console.log(err, `in upload module`)
      })
    }
  }
  return (
    <div className='p-4'>
      {loading === true ? <LoadingScreen /> : null}
      <Grid container spacing={0} padding={0} rowSpacing={1.5}>
        <Grid item md={6} xs={12} alignContent="flex-start">
          <div className='sub-heading'>User Management</div>
        </Grid>
        <Grid item md={6} xs={12} className={'right'} >
          

        </Grid>
        <Grid item md={6} xs={12} className={'left'}>
          <div className='d-flex justify-content-start align-content-center'><DriveFileRenameOutlineOutlinedIcon sx={{ marginTop: '8px' }} />

            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              sx={{ border: '0' }}
              className='admin__date  '
              popperPlacement="top-start"
              dateFormat="MMMM-yyyy"
              maxDate={new Date()}
              showYearDropdown
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </div>   </Grid>
        <Grid item md={6} xs={12} className={'a-rgt'} >

          {/* supervisor list */}
          <FormControl sx={{ maxWidth: '20rem' }} fullWidth className='p-1'>
            <Select
              placeholder="Select Supervisor Name"
              className='textfield'
              options={supervisorList}
              value={selsupervisor}
              onChange={handleSupervisor}
              isClearable />
          </FormControl>
          <Button onClick={handleDownloadExcel} variant="contained" className='btn-blue-lg m-1'>download excel</Button>
          <Button onClick={handleShow} variant="contained" className='btn-blue-lg m-1'>Upload module</Button>
        </Grid>
        <Grid>
        </Grid>
        <Grid item md={12} xs={12} >
          <div className='overflow'>
            <table
              id="mytable"
              className="normaltxt table-responsive"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th width="14%">Agent Name</th>
                  <th width="10%">ARN Code</th>
                  <th width="10%">Monthly SIP Target</th>
                  <th width="10%">Monthly Lumpsum Target</th>
                  <th width="10%">NIMF SIP Achivement</th>
                  <th width="10%">NIMF Lumpsum Achivement</th>
                  <th width="8%">SIP Amount</th>
                  <th width="8%">Lumpsum Amount</th>
                  <th width="14%">Supervisor</th>
                  <th width="6%">Active/Inactive</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map(
                  (
                    {
                      userId,
                      userName,
                      userArnCode,
                      sipTarget,
                      lumpsumTarget,
                      nimfSip,
                      nimfLumpsum,
                      supervisorId,
                      sipTotalAmount,
                      lumpsumAmount,
                      active
                    }, ind) => (

                    <tr key={ind} >
                      <td >{userName}</td>
                      <td>{userArnCode}</td>
                      <td>
                        <input
                          type="number"
                          name="sipTarget"
                          className="market_txtfield tData"
                          value={sipTarget}
                          onChange={(e) => handleChange(e, ind)}
                        // disabled={compareTwoDates(new Date(), new Date(startDate))}
                        /></td>
                      <td>
                        <input
                          type="number"
                          name="lumpsumTarget"
                          className="market_txtfield tData"
                          value={lumpsumTarget}
                          onChange={(e) => handleChange(e, ind)}
                        // disabled={compareTwoDates(new Date(), new Date(startDate))}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="nimfSip"
                          className="market_txtfield tData"
                          value={nimfSip}
                          onChange={(e) => handleChange(e, ind)}
                        // disabled={NIMFcompareTwoDates(new Date(), new Date(startDate))}
                        /></td>
                      <td>
                        <input
                          type="number"
                          name="nimfLumpsum"
                          className="market_txtfield tData"
                          value={nimfLumpsum}
                          onChange={(e) => handleChange(e, ind)}
                        // disabled={NIMFcompareTwoDates(new Date(), new Date(startDate))}
                        />
                      </td>
                      <td>{sipTotalAmount}</td>
                      <td>{lumpsumAmount}</td>
                      <td>  <select
                        className="market_txtfield_email drop_select"
                        value={supervisorId}
                        onChange={(selected) =>
                          handleSectorchange(selected, ind)
                        }
                      >
                        <option value={0} disabled>
                          Select
                        </option>
                        {supervisoroption &&
                          supervisoroption.map((x, indx) => {
                            return (
                              <option key={indx} value={x.supervisorId}>
                                {x.supervisorName}
                              </option>
                            );
                          })}
                      </select></td>
                      <td>
                        <Switch checked={1 === active ? true : false} onChange={() => UserActiveDeActive(userId, active, ind)} />
                      </td>
                    </tr>

                  )
                )}
              </tbody>
            </table>
          </div>
          <div className='p-t-5'>
            <Button variant="contained" className="btn-blue" onClick={() => UpdateUserDetails()}>Save</Button>
          </div>

        </Grid>

      </Grid>



      {show === true ?
        <div>
          <Modal size='md' isOpen={show} toggle={handleClose}>
            <ModalHeader toggle={handleClose} className="modal-hd">
              UPLOAD MODULE
            </ModalHeader>
            <ModalBody>
              <Box>
                <form>
                  <Grid container padding={2} spacing={0} rowSpacing={1.5} columnSpacing={1} marginTop={2} className="border-grid">
                    <Grid item xs={12} md={6} >
                      Year from date <br />
                      <DatePicker
                        selected={yfromdate}
                        className="txtfield"
                        onChange={(date) => {
                          setYfromdate(date);
                          setYtodate(date);
                          setMdate(date);
                        }}
                        // sx={{ border: '0' }}
                        // className='admin__date  '
                        popperPlacement="top-start"
                        dateFormat="MMMM-yyyy"
                        // maxDate={new Date()}
                        showYearDropdown
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      Year to date <br />
                      <DatePicker
                        selected={ytodate}
                        minDate={yfromdate}
                        className="txtfield"
                        onChange={(date) => {
                          setYtodate(date);
                          setMdate(date);
                        }}
                        // sx={{ border: '0' }}
                        // className='admin__date  '
                        popperPlacement="top-start"
                        dateFormat="MMMM-yyyy"
                        // maxDate={new Date()}
                        showYearDropdown
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      Select Month <br />
                      <DatePicker
                        minDate={yfromdate}
                        maxDate={ytodate}
                        selected={mdate}
                        className="txtfield"
                        onChange={(date) => {
                          setMdate(date);
                        }}
                        // sx={{ border: '0' }}
                        // className='admin__date  '
                        popperPlacement="top-start"
                        dateFormat="MMMM-yyyy"
                        // maxDate={new Date()}
                        showYearDropdown
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      Select File <br />
                      <input
                        type="file"
                        className="txtfield"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          setError({ ...error, file: '' });
                        }}
                      />
                      {error ? (
                        <div style={{ fontSize: 12, color: 'red' }}>
                          {error.file}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                </form>
              </Box>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button onClick={moduleSubmit} variant="contained" className="btn-grad" >
                  Submit
                </Button>
              </div>
              <div>
                <Button variant="contained" className="btn-grad" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div> : null}


    </div>
  )
}

export default AdminUserManagementContent